import { ApiError, Schedule } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { ScheduleNote } from '@services/schedules/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export type InternalScheduleProps = {
  taskId: string;
  forceOverlapping?: boolean;
  foremanId?: string;
  confirmed: boolean;
  startsAt: string;
  endsAt: string;
  note?: string;
};

export type CreateInternalScheduleReq = {
  schedule: InternalScheduleProps;
  note?: ScheduleNote;
  scheduleId?: string;
};

export interface CreateInternalScheduleRespSuccess {
  [key: string]: unknown;
  readonly status?: boolean;
  readonly data?: Schedule;
}

export type CreateInternalScheduleResp = CreateInternalScheduleRespSuccess | ApiError;

export const useCreateInternalSchedule = ({
  id,
  callback,
}: {
  id: string;
  callback: (data: CreateInternalScheduleResp) => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (data: CreateInternalScheduleReq): Promise<CreateInternalScheduleResp> =>
      queryFetch({ url: `jobs/${id}/internal_schedules`, data, opt: { method: 'POST' } }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: [...queryKeys.job, id] });
      await queryClient.refetchQueries({ queryKey: [...queryKeys.jobStages, id] });
      await queryClient.refetchQueries({ queryKey: queryKeys.onDeck });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendar });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendarSidebar });
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
