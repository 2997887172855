import { UserDropdownItem } from '@root/globalTypes';
import { queryFetch } from '@services/api';

export interface BuildersRespSuccess {
  success: true;
  records: UserDropdownItem[];
}

export const queryDropdownBuilders = async (): Promise<BuildersRespSuccess> => {
  return queryFetch({ url: 'dropdowns/builders' });
};
