import { SvgConsumer } from '@components/icons/svgConsumer';
import { JobStatus } from '@components/job/workProgress/helpers';
import { ReactNode } from 'react';

export const icons: Record<JobStatus, ReactNode> = {
  completed: <SvgConsumer id="jobCompleted" className="text-lg text-success-500" />,
  warning: <SvgConsumer id="jobWarning" className="text-xl text-primary-500" />,
  error: <SvgConsumer id="jobRejected" className="text-xl text-danger-500" />,
  readyToSchedule: null,
  scheduled: null,
  default: null,
};
