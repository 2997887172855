import { SvgConsumer } from '@components/icons/svgConsumer';
import { cn } from '@utils/cn';
import { JobStage as JobStageType, JobTaskData, JobTask as JobTaskType } from '@root/globalTypes';
import { sortByDate } from '@pages/hotSeat/utils';
import { parseISO } from 'date-fns';
import { Fragment } from 'react';
import { getJobStatus, isWithoutSchedule, sortByProp } from '@components/job/workProgress/helpers';
import { JobTask } from '../task';
import { JobAlert } from '../alert';
import { JobDate } from '../date';
import { useJobStages } from '@hooks/useJobStages';
import { JobStageMenu } from './menu';

interface JobStageProps {
  data: JobStageType;
}

export const JobStage = ({ data }: JobStageProps) => {
  const { toggle, isOpen } = useJobStages();
  const status = getJobStatus(data);

  return (
    <>
      <header
        id={`stage-${data.id}`}
        onClick={() => toggle(data.id)}
        className={cn([
          'col-start-1 col-end-5 mt-2 flex cursor-pointer select-none justify-between bg-white px-6 py-4 first-of-type:mt-0',
          isOpen(data.id) ? 'rounded-t-lg' : 'rounded-lg',
        ])}>
        <div className="flex items-center gap-2.5">
          <h2 className="text-lg font-bold text-neutral-800">{data.name}</h2>
          <JobAlert status={status} alerts={data.alerts} />
        </div>
        <div className="flex items-center gap-2">
          <JobStageMenu jobId={data.jobId} multi={data.multiplicableTasks} />
          <button
            className="flex h-6 w-6 items-center justify-center"
            type="button"
            onClick={() => toggle(data.id)}>
            <SvgConsumer
              id="expand"
              className={cn(['text-xs text-neutral-400', isOpen(data.id) && 'rotate-180'])}
            />
          </button>
        </div>
      </header>

      {isOpen(data.id) && (
        <>
          {sortByProp<JobTaskType>(
            data.tasks.find((el) => isWithoutSchedule(el))?.tasks || [],
            'position',
            'asc',
          ).map((task) => (
            <JobTask key={task.id} data={task} stage={data} />
          ))}

          {sortByDate<JobTaskData>(
            data.tasks.filter((el) => !isWithoutSchedule(el)),
            'date',
            'asc',
          ).map((el, index) => (
            <Fragment key={`job-stage-day-${index}`}>
              <JobDate date={parseISO(el.date)} alerts={el.dayAlerts} />
              {sortByProp<JobTaskType>(el.tasks, 'position', 'asc').map((task) => (
                <JobTask key={task.id} data={task} date={el.date} stage={data} />
              ))}
            </Fragment>
          ))}
        </>
      )}
    </>
  );
};
