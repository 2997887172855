import * as React from 'react';
import * as LabelPrimitive from '@radix-ui/react-label';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@utils/cn';

const labelVariants = cva(
  'text-xs font-normal text-neutral-700 leading-5 peer-disabled:cursor-not-allowed',
);

const Label = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root> & VariantProps<typeof labelVariants>
>(({ className, ...props }, ref) => (
  <LabelPrimitive.Root ref={ref} className={cn(labelVariants(), className)} {...props} />
));
// eslint-disable-next-line immutable/no-mutation
Label.displayName = LabelPrimitive.Root.displayName;

export { Label };
