import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@utils/cn';
import { badgeVariants } from './badgeVariants';

const bV = cva(
  'inline-flex items-center rounded-full border border-neutral-200 px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-neutral-950 focus:ring-offset-2',
  {
    variants: badgeVariants,
    defaultVariants: {
      variant: 'default',
    },
  },
);

export interface BadgeProps extends React.HTMLAttributes<HTMLDivElement>, VariantProps<typeof bV> {}

function Badge({ className, variant, ...props }: BadgeProps) {
  return <div className={cn(bV({ variant }), className)} {...props} />;
}

export { Badge };
