import { shortDate, shortDay } from '@utils/i18n';
import { alertStyleMap } from '@pages/hotSeat/utils';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { capitalizeFirstLetter } from '@utils/textHelpers';

interface JobDateProps {
  date: Date;
  alerts: string[];
}

export const JobDate = ({ date, alerts }: JobDateProps) => (
  <div className="col-start-1 col-end-5 flex h-7 items-center gap-2 bg-neutral-200 px-6 py-1">
    <span className="font-bold text-neutral-800">
      {[shortDay(date), shortDate(date)].join(', ')}
    </span>
    <AppTooltip
      variant="dark"
      context={
        <div className="flex flex-col gap-1">
          {alerts.map((alert) => {
            return (
              <div key={alert} className="flex items-center gap-2">
                <SvgConsumer id="jobRejected" className={`${alertStyleMap.error.icon} text-l`} />
                <span>{[capitalizeFirstLetter(alert.replaceAll('_', ' ')), '!'].join('')}</span>
              </div>
            );
          })}
        </div>
      }>
      {Boolean(alerts.length) && (
        <span className="shrink-0">
          <SvgConsumer id="jobRejected" className="text-l text-danger-500" />
        </span>
      )}
    </AppTooltip>
  </div>
);
