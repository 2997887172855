import React from 'react';

type TextHighlighterProps = {
  text: string;
  wordsIndexes: number[];
  highlightClass?: string;
};

export const TextHighlighter = ({
  text,
  wordsIndexes,
  highlightClass = 'text-primary-500 font-bold',
}: TextHighlighterProps) => {
  const wordsArray = text.split(' ');
  const uid = React.useId();
  const isLast = (index: number) => (index < wordsArray.length ? ' ' : '');
  return (
    <>
      {wordsArray.map((el, i) => {
        if (!wordsIndexes.includes(i)) {
          return (
            <React.Fragment key={`${uid}_${i}`}>
              {el}
              {isLast(i)}
            </React.Fragment>
          );
        }
        return (
          <span key={`${uid}_${i}`} className={highlightClass}>
            {el}
            {isLast(i)}
          </span>
        );
      })}
    </>
  );
};
