import { createContext, useContext, useEffect, useState } from 'react';
import { WebsocketContextData, WebsocketData, WebsocketProviderProps } from './types';
import { createConsumer } from 'actioncable';
import { initialState } from './constants';

const WebsocketContext = createContext<WebsocketContextData | Record<string, never>>({});

export const WebsocketProvider = ({ children }: WebsocketProviderProps) => {
  const [state, setState] = useState<WebsocketData>(initialState);

  const ws = !import.meta.env.VITE_DISABLE_WS
    ? createConsumer(import.meta.env.VITE_API_WS as string)
    : null;

  useEffect(() => {
    if (!ws) return;
    setState((prev) => ({ ...prev, ws }));
  }, [ws]);

  return (
    <WebsocketContext.Provider value={{ ...state, setState }}>{children}</WebsocketContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useWebsocketContext = () => useContext(WebsocketContext);
