import { CSSProperties, useEffect, useState } from 'react';
import { getElementStyle } from './helpers';

export const useElementStyle = (id?: string, active?: boolean) => {
  const [state, setState] = useState<CSSProperties>();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const newState = getElementStyle(id, active);
    if (JSON.stringify(state) === JSON.stringify(newState)) return;
    setState(newState);
  });

  return state;
};
