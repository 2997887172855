import { defaultCountries as countries, usePhoneInput } from 'react-international-phone';
import { PhoneProps } from './types';

export const Phone = ({ value }: PhoneProps) => {
  const { inputValue } = usePhoneInput({
    defaultCountry: 'us',
    value: value || '',
    countries,
  });

  if (!value) return null;
  return <span className="whitespace-nowrap text-left">{inputValue}</span>;
};
