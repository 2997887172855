import { SchedulerGridRow } from '../row';
import { useSchedulerContext } from '@contexts/scheduler';

export const SchedulerGridWithoutTeam = () => {
  const {
    data: {
      grouped: { withoutTeam },
    },
  } = useSchedulerContext();

  if (!withoutTeam) return null;

  if (!withoutTeam?.length)
    return <SchedulerGridRow data={[]} foreman={{ id: '', name: '' }} first last />;

  return withoutTeam.map((group, i) => (
    <SchedulerGridRow
      key={`scheduler-grid-without-team-row-${i}`}
      data={group}
      foreman={{ id: '', name: '' }}
      first={Boolean(!i)}
      last={i === withoutTeam.length - 1}
    />
  ));
};
