import { SEGMENT_LENGTH } from '@contexts/scheduler/constants';
import { Schedule } from '@services/jobs/schedules/teams';
import { compareHours } from '@utils/dateHelpers';
import { isAfter, isBefore, parseISO, subMinutes } from 'date-fns';

export const getSchedule = (segment: Date, data?: Schedule[]) =>
  data?.find((el) => {
    if (!el?.startsAt || !el?.endsAt) return;
    const startDate = parseISO(el.startsAt);
    const endDate = subMinutes(parseISO(el.endsAt), SEGMENT_LENGTH);
    const isBeforeOrEqualEnd = isBefore(segment, endDate) || compareHours(segment, endDate);
    const isAfterOrEqualStart = isAfter(segment, startDate) || compareHours(segment, startDate);
    return isBeforeOrEqualEnd && isAfterOrEqualStart;
  });

export const isStart = (segment: Date, data?: Schedule[]) =>
  data?.some((el) => {
    if (!el?.startsAt || !el?.endsAt) return;
    const startDate = parseISO(el.startsAt);
    if (compareHours(segment, startDate)) return true;
  });

export const isEnd = (segment: Date, data?: Schedule[]) =>
  data?.some((el) => {
    if (!el?.startsAt || !el?.endsAt) return;
    const endDate = subMinutes(parseISO(el.endsAt), SEGMENT_LENGTH);
    if (compareHours(segment, endDate)) return true;
  });
