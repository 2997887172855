import { useElementMeasures } from '@hooks/useElementMeasures';
import { useCallback, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useJobStages = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const openedStages = searchParams?.get('open')?.split(',').filter(Boolean) || [];
  const headerHeight = useElementMeasures('header').height;
  const jobHeaderHeight = useElementMeasures('sticky-job-header').height;

  const isOpen = (id: string) => openedStages.includes(id.toString());

  const scrollToStage = useCallback(
    (id: string) => {
      const stage = document.querySelector(`#stage-${id}`) as HTMLElement;
      const marginTop = 8;

      scrollTo({
        top:
          window.scrollY +
          stage?.getBoundingClientRect().top -
          headerHeight -
          jobHeaderHeight -
          marginTop,
        behavior: 'smooth',
      });

      setSearchParams(searchParams, { preventScrollReset: true });
    },
    [searchParams, setSearchParams, headerHeight, jobHeaderHeight],
  );

  const open = (id: string, withScroll?: boolean) => {
    if (!isOpen(id)) {
      const stagesWithNewIncluded = [...openedStages, id.toString()];
      searchParams.set('open', stagesWithNewIncluded.join(','));
      searchParams.set('focus', id);
      setSearchParams(searchParams, { preventScrollReset: true });
    }
    if (withScroll) scrollToStage(id.toString());
  };

  const close = (id: string) => {
    const stagesWithCurrentExcluded = openedStages.filter((el) => el !== id.toString());
    if (!stagesWithCurrentExcluded.length) searchParams.delete('open');
    else searchParams.set('open', stagesWithCurrentExcluded.join(','));
    searchParams.delete('focus');
    setSearchParams(searchParams, { preventScrollReset: true });
  };

  const toggle = (id: string) => {
    if (isOpen(id)) close(id);
    else open(id);
  };

  useEffect(() => {
    const id = searchParams.get('focus');
    if (!id) return;
    scrollToStage(id);
  }, [searchParams, scrollToStage]);

  return { open, toggle, isOpen };
};
