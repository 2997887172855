import { getInitials } from '@utils/textHelpers';

export const useInitials = (name?: string, lastName?: string) => {
  const canSplit = name ? name.split(' ').filter((s) => s) : [];
  let fName: string = name || '';
  let lName: string = lastName || '';
  if (canSplit?.length >= 2) {
    fName = canSplit[0];
    lName = canSplit[canSplit.length - 1];
  }
  let initials = '';
  if (fName && lName) initials = getInitials(fName) + getInitials(lName);
  if (fName && !lName && canSplit?.length < 2) initials = getInitials(fName, 2);
  return initials.trim();
};
