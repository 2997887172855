import { ProtectedRoute } from '@components/protectedRoute';
import { appRoutes, usersAccessList } from '@utils/consts';

export const onDeckRoutes = [
  {
    path: appRoutes.onDeck,
    lazy: async () => {
      const { HotSeat } = await import('@pages/hotSeat');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.onDeck)}>
            <HotSeat onDeck />
          </ProtectedRoute>
        ),
      };
    },
  },
];
