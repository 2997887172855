export const variants = {
  variant: {
    default:
      'text-white bg-primary hover:bg-primary-600 focus-visible:bg-primary-600 disabled:bg-primary-500 disabled:opacity-30',
    secondary:
      'bg-white text-neutral-700 border border-neutral-200 hover:bg-neutral-50 focus-visible:bg-neutral-50 disabled:bg-white disabled:opacity-30',
    tertiary:
      'bg-white text-primary-500 border border-primary-500 hover:border-primary-700 hover:text-primary-700 disabled:bg-white disabled:opacity-30',
    ghost:
      'text-neutral-700 hover:bg-neutral-50 disabled:opacity-30 focus-visible:shadow-none focus-visible:bg-neutral-50',
    success:
      'text-white bg-success-500 hover:bg-success-600 focus-visible:bg-success-600 disabled:bg-success-500 disabled:opacity-30',
    successSecondary:
      'text-success-700 bg-success-50 border border-success-700 hover:border-success-700 hover:bg-success-100 focus-visible:bg-success-100 disabled:opacity-30',
    danger:
      'bg-danger-500 text-white hover:bg-danger-700 focus-visible:bg-danger-700 disabled:bg-danger-300 focus-visible:shadow-danger-btn',
    dangerSecondary:
      'bg-white text-danger-500 border border-danger-500 hover:border-danger-700 hover:text-danger-700 focus-visible:text-white focus-visible:bg-danger-700 disabled:bg-white disabled:opacity-30',
  },
  size: {
    lg: 'h-11 text-m font-normal py-2.5',
    default: 'h-10 text-s font-semibold py-2.5',
    sm: 'h-8 text-s font-semibold py-1.5',
    icon: 'h-10 w-10',
  },
};
