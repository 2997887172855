import { DefaultApiResp } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export const useResolveIssue = ({ callback }: { callback: (data: DefaultApiResp) => void }) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string): Promise<DefaultApiResp> =>
      queryFetch({
        url: `issues/${id}/resolve`,
        opt: { method: 'PUT' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
    },
    onSettled: (data) => {
      if (callback && data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
