import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@components/ui/alert';
import { Button } from '@components/ui/button';
import { Dispatch, ReactNode, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';

type RemoveConfirmationAlertProps = {
  title: ReactNode;
  description: ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  children?: ReactNode;
  open?: boolean;
  setOpen?: Dispatch<SetStateAction<boolean>>;
  handleOpen?: (open: boolean) => void;
  locked?: boolean;
};

export const RemoveConfirmationAlert = ({
  title,
  description,
  onConfirm,
  onCancel,
  children,
  open,
  setOpen,
  handleOpen,
  locked,
}: RemoveConfirmationAlertProps) => {
  const state = useState(false);
  const [openDialog, setOpenDialog] =
    open && (handleOpen || setOpen) ? [open, handleOpen || setOpen] : state;

  const { t } = useTranslation('app');

  const handleCancel = () => {
    if (onCancel) onCancel();
    if (setOpenDialog) setOpenDialog(false);
  };

  const handleConfirm = () => {
    if (onConfirm) onConfirm();
    if (setOpenDialog) setOpenDialog(false);
  };

  return (
    <AlertDialog open={openDialog} onOpenChange={setOpenDialog}>
      {!handleOpen && !setOpen && (
        <AlertDialogTrigger asChild>
          {children ? (
            children
          ) : (
            <Button
              variant="ghost"
              size="sm"
              className="w-full justify-start whitespace-nowrap px-2">
              {t('actions.remove')}
            </Button>
          )}
        </AlertDialogTrigger>
      )}
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>{title}</AlertDialogTitle>
          <AlertDialogDescription>{description}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <Button onClick={handleCancel} variant="secondary" disabled={locked}>
            {t('actions.cancel')}
          </Button>
          <Button onClick={handleConfirm} variant="danger" disabled={locked}>
            {t('actions.confirm')}
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
