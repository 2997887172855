import { addHours } from 'date-fns';
import { SchedulerGridCell } from '../cell';
import { SchedulerGridHeaderHour } from './hour';
import { useElementMeasures } from '@hooks/useElementMeasures';
import { useSchedulerContext } from '@contexts/scheduler';
import { getHours } from '../helpers';
import { cn } from '@utils/cn';

export const SchedulerGridHeader = () => {
  const { date, modal } = useSchedulerContext();
  const hours = getHours(date);

  const navigation = useElementMeasures('scheduler-navigation').height;
  const tabHeader = useElementMeasures('page-header');
  const headerHeight = modal ? 0 : tabHeader.y + tabHeader.height + navigation;

  return (
    <>
      <SchedulerGridCell
        className={cn(['z-20 border-l-0 bg-white p-0', !modal && 'sticky border-b-2'])}
        style={{ top: headerHeight }}
      />
      {hours.map((hour, i) => (
        <SchedulerGridCell
          key={`scheduler-grid-header-${hour.getTime()}`}
          className={cn(['z-20 border-b-2 border-l-0 bg-white p-0', !modal && 'sticky'])}
          style={{ top: headerHeight }}>
          <SchedulerGridHeaderHour className="relative left-0 -translate-x-1/2" data={hour} />
          {i === hours.length - 1 && (
            <SchedulerGridHeaderHour
              className="absolute right-0 translate-x-1/2"
              data={addHours(hour, 1)}
            />
          )}
        </SchedulerGridCell>
      ))}
    </>
  );
};
