import { useAppContext } from '@contexts/app';
import { UserDropdownItem } from '@root/globalTypes';
import { BuildersRespSuccess, queryDropdownBuilders } from '@services/builder/dropdown';
import { UsersRespSuccess, queryDropdownUsers } from '@services/users/dropdown';
import { useIsFetching, useQueries, UseQueryResult } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useEffect } from 'react';

type DropdownsData = {
  coordinators: UserDropdownItem[];
  superintendents: UserDropdownItem[];
  builders: UserDropdownItem[];
};

export const useGetDropdownsData = ({
  getBuilders = true,
  getCoordinators = true,
  getSuperintendents = true,
}: {
  getBuilders?: boolean;
  getCoordinators?: boolean;
  getSuperintendents?: boolean;
}) => {
  const { isLogged, isForeman, setData } = useAppContext();
  const isFetchingDropdowns = useIsFetching({ queryKey: ['dropdowns'] });
  const queriesList = ['builders', 'project_coordinator', 'superintendent'].map((u) => {
    if (u === 'builders' && getBuilders)
      return {
        key: queryKeys.builders,
        fn: queryDropdownBuilders,
      };
    if (u === 'project_coordinator' && getCoordinators)
      return {
        key: queryKeys.projectCoordinator,
        fn: () => queryDropdownUsers(`?role[]=project_coordinator&role[]=administrator`),
      };
    if (u === 'superintendent' && getSuperintendents)
      return {
        key: queryKeys.superintendent,
        fn: () => queryDropdownUsers(`?role[]=${u}`),
      };
    return null;
  });

  const results = useQueries({
    queries:
      isLogged && !isForeman
        ? queriesList
            .filter((el) => el?.key)
            .map((el) => ({
              key: el?.key,
              queryKey: ['dropdowns', el?.key],
              queryFn: el?.fn,
              staleTime: 10 * 1000,
              cacheTime: 20 * 1000,
            }))
        : [],
  });

  function sortResults(
    r: UseQueryResult<BuildersRespSuccess | UsersRespSuccess, Error>[],
  ): DropdownsData {
    const coordinators: UserDropdownItem[] = [];
    const superintendents: UserDropdownItem[] = [];
    const builders: UserDropdownItem[] = [];

    r.forEach((res) => {
      if (!res.data?.records) return;
      res.data.records.forEach((rec) => {
        if (rec?.role) {
          if (rec.role === 'superintendent') {
            superintendents.push(rec);
          } else {
            coordinators.push(rec);
          }
        } else {
          builders.push(rec);
        }
      });
    });

    return {
      coordinators,
      superintendents,
      builders,
    };
  }

  useEffect(() => {
    if (!isLogged || isForeman) return;
    if (isFetchingDropdowns > 0) return;
    if (typeof setData === 'function') {
      setData((prev) => ({
        ...prev,
        ...sortResults(results),
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetchingDropdowns, setData, isLogged, isForeman]);
};
