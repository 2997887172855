import { useCallback, useEffect, useMemo } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { capitalizeFirstLetter } from '@utils/textHelpers';
import { unparseRoute } from '@utils/i18n';
import { foremanRoute } from '@utils/consts';

export const useTitle = (dynamic?: string) => {
  const { pathname } = useLocation();
  const { id } = useParams();

  const parsedPathname = useMemo(
    () =>
      unparseRoute(pathname)
        .split('/')
        [pathname.includes(foremanRoute.index) ? 2 : 1].split('-')
        .map((el) => capitalizeFirstLetter(el))
        .join(' '),
    [pathname],
  );

  const setTitle = useCallback(() => {
    if (!parsedPathname) return;
    // eslint-disable-next-line immutable/no-mutation
    document.title = `${dynamic && id ? `${dynamic} - ` : ''}${parsedPathname} - Streamline`;
  }, [dynamic, id, parsedPathname]);

  useEffect(setTitle, [setTitle]);
};
