type UserData = {
  accessToken: string;
  client: string;
  uid: string;
  id: string;
};

export const useUser = (): {
  isLogged: boolean;
  user: UserData;
} => {
  const user = localStorage.getItem('app-user');
  let parsedUser: Record<string, string> = {};
  if (typeof user === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const { data } = JSON.parse(user);
    if (typeof data === 'string') {
      parsedUser = JSON.parse(data) as Record<string, string>;
    }
  }
  const userData = (): UserData => ({
    accessToken: parsedUser['Access-Token'] || '',
    client: parsedUser.Client || '',
    uid: parsedUser.Uid || '',
    id: parsedUser.id || '',
  });

  return {
    isLogged: user ? user.includes('Access-Token') : false,
    user: userData(),
  };
};
