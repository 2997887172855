import { CSSProperties } from 'react';
import { Icons } from './svgSources';
import { transformSnakeToCamelCase } from '@utils/textHelpers';

export type SvgConsumerId = keyof typeof Icons;

interface SvgConsumerProps {
  id: SvgConsumerId;
  style?: CSSProperties;
  className?: string;
  clickHandler?: () => void;
}

const defaultStyle: CSSProperties = {
  width: '1em',
  height: '1em',
  fill: 'currentColor',
};

export const SvgConsumer = ({ id, style, clickHandler, className = '' }: SvgConsumerProps) => (
  <svg
    className={className}
    style={{
      ...defaultStyle,
      ...style,
      ...(typeof clickHandler !== 'undefined' ? { cursor: 'pointer' } : {}),
    }}
    fill={style?.color}
    onClick={clickHandler}
    viewBox="0 0 24 24">
    <use href={`#${transformSnakeToCamelCase(id.replace(' ', '_'))}Icon`} />
  </svg>
);

export const IconsSet = () => (
  <div className="text-neutral-70 flex flex-wrap gap-3">
    {Object.keys(Icons).map((key) => (
      <div key={key} className="hover:text-primary-400">
        <SvgConsumer id={`${key}` as SvgConsumerId} />
        <div>{key}</div>
      </div>
    ))}
  </div>
);
