import { ProtectedRoute } from '@components/protectedRoute';
import { appRoutes, usersAccessList } from '@utils/consts';

export const hotSeatRoutes = [
  {
    path: appRoutes.hotSeat,
    lazy: async () => {
      const { HotSeat } = await import('@pages/hotSeat');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.hotSeat)}>
            <HotSeat />
          </ProtectedRoute>
        ),
      };
    },
  },
];
