import { Checkbox } from '@components/ui/checkbox';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { Avatar } from '@components/avatar';
import { cn } from '@utils/cn';
import { capitalizeFirstLetter, transformSnakeToCamelCase } from '@utils/textHelpers';
import { JobTaskCalendarText } from './calendarText';
import {
  JobStage,
  JobSubtask as JobSubtaskType,
  JobTaskStatus,
  JobTask as JobTaskType,
} from '@root/globalTypes';
import { useTranslation } from 'react-i18next';
import { formatDate, formatTime, parseRoute } from '@utils/i18n';
import { JobTaskPill } from './pill';
import { lazy, useState } from 'react';
import { InspectionScheduleDialogType } from '@pages/jobs/:id/dialogs/scheduleInspection';
import { useAppContext } from '@contexts/app';
import { useCompleteTask } from '@services/tasks/complete';
import { useToast } from '@hooks/useToast';
import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { RemoveConfirmationAlert } from '@components/dialogs/removeConfirmationAlert';
import { Button } from '@components/ui/button';
import { useRemoveExternalSchedule } from '@services/schedules/remove';
import { useRevertTask } from '@services/tasks/revert';
import { parseISO } from 'date-fns';
import { useRemoveTask } from '@services/jobs/tasks/remove';
import { useJobContext } from '@contexts/job';
import { JobTaskAction, getAction, setBackground, sortByProp } from '../helpers';
import { JobFlag } from '../flag';
import { JobSubtask } from '../subtask';
import { NoteBox } from '../noteBox';
import { Issue } from '@components/issue';
import { useRemoveInternalSchedule } from '@services/schedules/internal/remove';
import { InternalScheduleDialog } from '@components/schedules/internal/dialog';
import { Schedule } from '@services/jobs/schedules/teams';
import { createSchedule } from '@contexts/scheduler/helpers';
import { SchedulerProvider } from '@contexts/scheduler';
import { WorkloadBasicSpeciality } from '@services/workload/calendar/types';
import { appRoutes } from '@utils/consts';
import { Link } from 'react-router-dom';
import { JobTaskFormCount } from './formCount';

const ConcreteOrderDialog = lazy(() => import('@pages/jobs/:id/dialogs/concreteOrder'));
const ScheduleInspectionDialog = lazy(() => import('@pages/jobs/:id/dialogs/scheduleInspection'));

export const JobTask = ({
  data,
  date,
  stage,
}: {
  data: JobTaskType;
  date?: string;
  stage?: JobStage;
}) => {
  const { toast } = useToast();
  const action = getAction(data);
  const importantTasks = stage?.tasks.flatMap((el) => el.tasks).filter((el) => el.important);
  const [open, setOpen] = useState(false);
  const isNotRevertable = data.status === 'completed' && !data.forceCompleted;
  const [isRevertConfirm, setIsRevertConfirm] = useState(false);
  const { t } = useTranslation('app');
  const { job, archived, allowed } = useJobContext();
  const [jobId, scheduleId] = [job.data?.data.id || '', data.schedule?.id || ''];

  const { isCadTechnician } = useAppContext();
  const isCadTask = data?.name?.includes('CAD');

  const isRemovable = data.removable || data.multitask || stage?.setupType === 'custom';

  const removeExternalSchedule = useRemoveExternalSchedule({
    callback: () => {
      toast({ title: t('messages.success.externalSchedule.remove') });
    },
  });

  const removeInternalSchedule = useRemoveInternalSchedule({
    id: scheduleId,
    jobId,
    callback: () => {
      toast({ title: t('messages.success.internalSchedule.remove') });
    },
  });

  const removeTask = useRemoveTask({
    callback: () => {
      toast({ title: t('messages.success.task.remove') });
    },
  });

  const complete = useCompleteTask({
    callback: () => {
      toast({ title: t('messages.success.task.complete') });
    },
  });

  const revert = useRevertTask({
    callback: () => {
      toast({ title: t('messages.success.task.revert') });
    },
  });

  const handleCheckbox = (newValue: boolean) => {
    if (!data.id) return;
    if (newValue) complete.mutate({ id: data.id });
    else setIsRevertConfirm(true);
  };

  const remove = {
    onConfirm:
      data.scheduleType === 'internal'
        ? () => removeInternalSchedule.mutate()
        : () => removeExternalSchedule.mutate({ jobId, scheduleId }),
    locked:
      data.scheduleType === 'internal'
        ? removeInternalSchedule.isLoading
        : removeExternalSchedule.isLoading,
  };

  const variant: Record<JobTaskAction, string> = {
    schedule:
      'text-primary-400 border-primary-200 bg-white hover:text-white hover:bg-primary-400 hover:border-primary-400 disabled:bg-white disabled:text-primary-400 disabled:border-primary-200',
    none: '',
    form: 'text-neutral-700 border-transparent hover:underline',
    reschedule: `text-neutral-700 disabled:text-neutral-700 ${
      data.status === 'rejected'
        ? 'border-neutral-200 bg-white disabled:bg-white disabled:border-neutral-200 hover:bg-neutral-700 hover:border-neutral-700 hover:text-white'
        : 'border-transparent hover:underline disabled:no-underline'
    }`,
  };

  const scheduleDate = data.schedule?.startsAt ? parseISO(data.schedule?.startsAt) : undefined;

  const stageImportantTaskStartsAt = stage?.tasks
    .find((el) => el.date === date)
    ?.tasks.find((task) => task.importantSchedule && task.schedule?.startsAt)?.schedule?.startsAt;

  const stageImportantTaskDate = stageImportantTaskStartsAt
    ? parseISO(stageImportantTaskStartsAt)
    : undefined;

  const getDialog = () => {
    const getInspectionDialog = (type: InspectionScheduleDialogType) => (
      <ScheduleInspectionDialog
        importantTasks={importantTasks}
        type={type}
        open={open}
        setOpen={setOpen}
        jobId={jobId}
        taskId={data.id}
        schedule={data.schedule}
        completed={isNotRevertable}
        note={data.note}
      />
    );
    const getConcreteOrderDialog = () => (
      <ConcreteOrderDialog
        concrete={data?.materials?.find((el) => el.product === 'Concrete')}
        importantTasks={importantTasks}
        open={open}
        setOpen={setOpen}
        completed={isNotRevertable}
        jobId={jobId}
        taskId={data.id}
        schedule={data.schedule}
        note={data.note}
      />
    );

    if (!data) return null;
    if (data.scheduleType !== 'external') return null;
    else if (!data.dateConfirmation) return getInspectionDialog('ohInspection');
    else if (data.dateConfirmation === 'external') return getInspectionDialog('inspection');
    return getConcreteOrderDialog();
  };

  const renderScheduleButton = () => {
    if (action === 'none') return null;
    if (action === 'form')
      return (
        <Link
          to={`/${parseRoute(appRoutes.jobQuestionary(jobId))}`}
          className={cn([
            'flex h-fit w-fit items-center gap-3 rounded-md px-4 py-1.5 text-sm font-semibold transition-colors disabled:opacity-50 ',
            variant[action],
          ])}
          type="button"
          onClick={() => setOpen(true)}>
          {t('actions.form')}
          <SvgConsumer id="back" className="rotate-180 text-[10px]" />
        </Link>
      );
    if (data.routePoint) {
      return (
        <AppTooltip side="top" context={t('schedules.routeLocked')}>
          <div className="flex cursor-not-allowed justify-end gap-1">
            <span
              className={cn([
                'flex h-fit w-fit items-center gap-2 rounded-md border px-4 py-1.5 text-sm font-semibold transition-colors disabled:opacity-50',
                variant[action],
              ])}>
              {capitalizeFirstLetter(action)}
            </span>
            <Button variant="ghost" size="sm" className="px-2" disabled>
              <SvgConsumer id="taskRemove" className="text-m" />
            </Button>
          </div>
        </AppTooltip>
      );
    }
    return (
      <div className="flex justify-end gap-1">
        {data.scheduleType && data.scheduleType !== 'dependent' && (
          <>
            <button
              data-testid={`schedule-${data.name}`}
              disabled={archived || isCadTechnician}
              className={cn([
                'flex h-fit w-fit items-center gap-2 rounded-md border px-4 py-1.5 text-sm font-semibold transition-colors disabled:opacity-50',
                variant[action],
              ])}
              type="button"
              onClick={() => setOpen(true)}>
              {(action === 'schedule' ||
                (action === 'reschedule' && data.status === 'rejected')) && (
                <SvgConsumer id="calendar" />
              )}
              {capitalizeFirstLetter(action)}
            </button>
            {action === 'schedule' && !isRemovable && <div className="w-8" />}
          </>
        )}
        {data?.scheduleType !== 'dependent' && action === 'schedule' && isRemovable && (
          <RemoveConfirmationAlert
            title={t('pages.job.removeTask.title')}
            description={t('pages.job.removeTask.description', { name: data.name })}
            onConfirm={() => removeTask.mutate({ jobId, id: data.id })}
            locked={removeTask.isLoading}>
            <Button
              data-testid={`remove-task-${data.name}`}
              title={t('actions.removeTask')}
              disabled={archived || isCadTechnician}
              variant="ghost"
              size="sm"
              className="px-2">
              <SvgConsumer id="taskRemove" className="text-m" />
              <span className="sr-only">{t('actions.removeTask')}</span>
            </Button>
          </RemoveConfirmationAlert>
        )}
        {data?.scheduleType !== 'dependent' && action === 'reschedule' && (
          <RemoveConfirmationAlert
            title={t('schedules.removeTitle')}
            description={t('schedules.removeInfo')}
            onConfirm={remove.onConfirm}
            locked={remove.locked}>
            <Button
              data-testid={`remove-schedule-${data.name}`}
              title={t('actions.removeSchedule')}
              disabled={archived || isCadTechnician}
              variant="ghost"
              size="sm"
              className="px-2">
              <SvgConsumer id="trash" className="text-m" />
              <span className="sr-only">{t('actions.removeSchedule')}</span>
            </Button>
          </RemoveConfirmationAlert>
        )}
      </div>
    );
  };

  return (
    <>
      <div
        className={cn([
          'col-start-1 col-end-2 flex flex-col justify-center gap-1 border-t-2 border-neutral-200 px-6 py-3',
          setBackground(data.status as JobTaskStatus),
        ])}>
        <div className="flex items-center gap-4">
          <h3 className="flex h-[22px] items-center justify-center text-sm font-semibold text-neutral-800">
            {data.name}
          </h3>
          <JobTaskPill status={data.status} />
          <JobTaskFormCount
            count={data.totalNoOfAnsweredQuestions}
            total={data.totalNoOfQuestions}
          />
          {data.status === 'completed' && data.forceCompleted && (
            <AppTooltip context={<p>{t('pages.job.info.doneTooltip')}</p>}>
              <span>
                <SvgConsumer className="text-xl text-success-800" id="markAsDone" />
              </span>
            </AppTooltip>
          )}
        </div>
        {data.schedule && scheduleDate && (
          <div className={cn(['flex gap-8', data.status === 'rejected' && 'opacity-50'])}>
            <div className="flex items-center gap-2">
              <SvgConsumer className="text-md text-neutral-400" id="calendar" />
              <JobTaskCalendarText>{formatDate(scheduleDate)}</JobTaskCalendarText>
              <JobTaskCalendarText>{formatTime(scheduleDate)}</JobTaskCalendarText>
            </div>
            {data.schedule.foreman && (
              <div className="flex items-center gap-2">
                <Avatar
                  hideTooltip
                  name={[data.schedule.foreman.firstName, data.schedule.foreman.lastName].join(' ')}
                />
                <span className="whitespace-nowrap text-sm">
                  {[data.schedule.foreman.firstName, data.schedule.foreman.lastName].join(' ')}
                </span>
              </div>
            )}
            {data.schedule.contact && (
              <span className="whitespace-nowrap text-sm">{data.schedule.contact.name}</span>
            )}
          </div>
        )}
      </div>
      <div
        className={cn([
          'col-start-2 col-end-3 flex items-center justify-end border-t-2 border-neutral-200 pr-6',
          setBackground(data.status as JobTaskStatus),
        ])}>
        {renderScheduleButton()}
      </div>
      <div
        className={cn([
          'col-start-3 col-end-4 flex justify-end border-t-2 border-neutral-200',
          setBackground(data.status as JobTaskStatus),
        ])}>
        <div className="w-fit border-x border-neutral-100 px-4.5 py-4">
          <Checkbox
            disabled={isNotRevertable || archived || !allowed}
            checked={data.status === 'completed'}
            onCheckedChange={handleCheckbox}
          />
        </div>
      </div>
      <div
        className={cn([
          'col-start-4 col-end-5 flex border-t-2 border-neutral-200',
          setBackground(data.status as JobTaskStatus),
        ])}>
        <NoteBox
          referableType="Task"
          data={data.note}
          jobId={job.data?.data.id}
          typeId={data.id}
          disabled={archived || (!isCadTask && isCadTechnician)}
        />
      </div>
      {Boolean(data.cancellationConfirmations?.length) &&
        data.cancellationConfirmations?.map((el) => <JobFlag key={el.id} data={el} />)}
      {Boolean(data.issues?.length) && data.issues?.map((el) => <Issue key={el.id} data={el} />)}
      {(data.schedule || !data.scheduleType) &&
        sortByProp<JobSubtaskType>(data.subtasks || [], 'position', 'asc').map((subTask) => (
          <JobSubtask key={subTask.id} data={subTask} status={data.status} />
        ))}
      {getDialog()}
      <RemoveConfirmationAlert
        open={isRevertConfirm}
        setOpen={setIsRevertConfirm}
        title={t('confirmDialog.title')}
        description={t('pages.job.revertTask', {
          name: data.name,
        })}
        onConfirm={() => revert.mutate({ id: data.id })}
      />
      {data.scheduleType === 'internal' && (
        <SchedulerProvider>
          <InternalScheduleDialog
            open={open}
            setOpen={setOpen}
            type={
              transformSnakeToCamelCase(data.foremanSpeciality || '') as WorkloadBasicSpeciality
            }
            jobId={job.data?.data.id || ''}
            schedule={
              action === 'schedule'
                ? createSchedule(data, job.data?.data)
                : ({
                    ...data.schedule,
                    task: { ...data.schedule?.task, job: job.data?.data, note: data.note },
                    foreman: data.schedule?.foreman ? data.schedule.foreman : { id: '' },
                  } as Schedule)
            }
            initialDate={(action === 'schedule' && stageImportantTaskDate) || scheduleDate}
            activeId={data.schedule?.id}
          />
        </SchedulerProvider>
      )}
    </>
  );
};
