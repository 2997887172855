import { appRoutes, foremanRoute } from '@utils/consts';
import { Outlet } from 'react-router-dom';

export const foremanTasksRoutes = [
  {
    path: foremanRoute.task(foremanRoute.taskId),
    lazy: async () => {
      const { ForemanTaskProvider } = await import('@contexts/foreman/task');
      return {
        Component: () => (
          <ForemanTaskProvider>
            <Outlet />
          </ForemanTaskProvider>
        ),
      };
    },
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { ForemanTask } = await import('@pages/foreman/tasks/:id');
          return {
            Component: () => <ForemanTask />,
          };
        },
      },
      {
        path: foremanRoute.questionary,
        lazy: async () => {
          const { QuestionaryProvider } = await import('@contexts/questionary');
          return {
            Component: () => (
              <QuestionaryProvider>
                <Outlet />
              </QuestionaryProvider>
            ),
          };
        },
        children: [
          {
            path: appRoutes.base,
            lazy: async () => {
              const { ForemanTaskQuestionary } = await import(
                '@pages/foreman/tasks/:id/questionary'
              );
              return {
                Component: () => <ForemanTaskQuestionary />,
              };
            },
          },
          {
            path: foremanRoute.questionId,
            lazy: async () => {
              const { ForemanTaskQuestionaryDetails } = await import(
                '@pages/foreman/tasks/:id/questionary/:id'
              );
              return {
                Component: () => <ForemanTaskQuestionaryDetails />,
              };
            },
          },
        ],
      },
    ],
  },
];
