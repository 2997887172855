import { SvgConsumer } from '@components/icons/svgConsumer';
import { IssueListItemProps } from './types';
import { useDownloadIssueImage } from '@services/issues/download';
import { useAppContext } from '@contexts/app';
import { cn } from '@utils/cn';

export const IssueListItem = ({
  issueId,
  data: { id: imageId, filename, url },
}: IssueListItemProps) => {
  const { isForeman } = useAppContext();
  const download = useDownloadIssueImage();

  return (
    <li className="group flex items-center gap-3 p-1 font-semibold text-primary-500">
      <span className="flex items-center gap-2">
        <SvgConsumer className="shrink-0" id="file" />
        <a
          className="cursor-pointer text-sm hover:underline"
          href={url}
          target="_blank"
          rel="noopener noreferrer">
          {filename}
        </a>
      </span>
      <button
        type="button"
        onClick={() => download.mutate({ issueId, imageId })}
        className={cn([
          'p-1 text-m text-neutral-300 hover:text-blue-400',
          !isForeman && 'opacity-0 group-hover:opacity-100',
        ])}>
        <SvgConsumer id="downloadDocument" />
      </button>
    </li>
  );
};
