import { ProtectedRoute } from '@components/protectedRoute';
import { appRoutes, jobIdRoute, usersAccessList } from '@utils/consts';
import { WorkProgress } from './:id/workProgress';
import { JobInventoryProvider } from '@contexts/job/inventory';

export const jobsRoutes = [
  {
    path: appRoutes.jobs,
    lazy: async () => {
      const { Jobs } = await import('@pages/jobs');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.jobs)}>
            <Jobs />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: appRoutes.jobPreview(':id'),
    lazy: async () => {
      const { Preview } = await import('@pages/jobs/preview');
      return {
        Component: () => <Preview />,
      };
    },
  },
  {
    path: appRoutes.job(':id'),
    lazy: async () => {
      const { Job } = await import('@pages/jobs/:id');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.jobs)}>
            <Job />
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        path: jobIdRoute.index,
        lazy: async () => {
          const { MainInformation } = await import('@pages/jobs/:id/mainInformation');
          return {
            Component: () => <MainInformation />,
          };
        },
      },
      {
        path: jobIdRoute.materials,
        lazy: async () => {
          const { Materials } = await import('@pages/jobs/:id/materials');
          return {
            Component: () => <Materials />,
          };
        },
      },
      {
        path: jobIdRoute.inventory,
        lazy: async () => {
          const { Inventory } = await import('@pages/jobs/:id/inventory');
          return {
            Component: () => (
              <JobInventoryProvider>
                <Inventory />
              </JobInventoryProvider>
            ),
          };
        },
      },
      {
        path: jobIdRoute.documents,
        lazy: async () => {
          const { Documents } = await import('@pages/jobs/:id/documents');
          return {
            Component: () => <Documents />,
          };
        },
      },
      {
        path: jobIdRoute.questionary,
        lazy: async () => {
          const { Questionary } = await import('@pages/jobs/:id/questionary');
          return {
            Component: () => <Questionary />,
          };
        },
      },
      {
        path: jobIdRoute.workProgress,
        element: <WorkProgress />,
      },
    ],
  },
];
