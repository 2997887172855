import { MenuProps } from './types';
import { Popover, PopoverContent, PopoverTrigger } from '@components/ui/popover';
import { MenuItem } from './item';
import { MenuHeader } from './header';
import { Fragment, SyntheticEvent } from 'react';

export const Menu = ({ options, children, variant = 'big', open, setOpen }: MenuProps) => {
  const handleItemClick = (e: SyntheticEvent<HTMLButtonElement>, callback: () => void) => {
    e.stopPropagation();
    setOpen(false);
    callback();
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>{children}</PopoverTrigger>
      <PopoverContent
        align="end"
        className="relative left-0 z-[60] flex w-fit max-w-[205px] flex-col overflow-hidden rounded border-none p-0 text-xs shadow-stage-menu">
        {options.map(({ group, items }) => (
          <Fragment key={group.id}>
            {group?.name && <MenuHeader>{group.name}</MenuHeader>}
            {items.map(({ id, name, callback }) => (
              <MenuItem key={id} onClick={(e) => handleItemClick(e, callback)} variant={variant}>
                {name}
              </MenuItem>
            ))}
          </Fragment>
        ))}
      </PopoverContent>
    </Popover>
  );
};
