import { SvgConsumer } from '@components/icons/svgConsumer';
import { alertStyleMap } from '@pages/hotSeat/utils';
import { filterDuplicates, sortAlerts } from '../../helpers';
import { JobAlertTooltipProps } from './types';

export const JobAlertTooltip = ({ data }: JobAlertTooltipProps) => (
  <div className="flex flex-col gap-1">
    {filterDuplicates(sortAlerts(data), 'message').map((el) => (
      <div key={el.id} id={el.id} className="flex items-center gap-2">
        <SvgConsumer
          id={['error', 'issue'].includes(el.alertType) ? 'error' : 'warning'}
          className={`${
            alertStyleMap[['error', 'issue'].includes(el.alertType) ? 'error' : 'warning'].icon
          } shrink-0 text-l`}
        />
        <span className="overflow-hidden text-ellipsis whitespace-nowrap">{el.message}</span>
      </div>
    ))}
  </div>
);
