import { cn } from '@utils/cn';
import { IssueListItem } from './item';
import { IssueListProps } from './types';

export const IssueList = ({ issueId, data, foreman }: IssueListProps) => (
  <ul className={cn(['flex flex-col', foreman && 'ml-4'])}>
    {data.map((el) => (
      <IssueListItem key={`job-issue-list-item-${el.id}`} issueId={issueId} data={el} />
    ))}
  </ul>
);
