/* eslint-disable immutable/no-mutation */

import { localStore } from '@utils/localStore';
import { formatBody, formatResponse } from '@utils/textHelpers';

export type ResponseError = Error & {
  info: Record<string, unknown>;
  status: number;
};

const saveUserAuthParams = (res: Response) => {
  if (!res.headers.has('Access-Token')) return;
  const auth: Record<string, string | null> = {};
  const oldData = localStore.getItem('app-user');
  let cache: Record<string, string> = {};
  if (oldData && typeof oldData === 'string') cache = JSON.parse(oldData) as Record<string, string>;
  if (res.headers.has('Access-Token')) {
    auth['Access-Token'] = res.headers.get('Access-Token') || cache['Access-Token'];
  }
  if (res.headers.has('Client')) auth.Client = res.headers.get('Client') || cache.Client;
  if (res.headers.has('Uid')) auth.Uid = res.headers.get('Uid') || cache.Uid;
  if (res.headers.has('Expiry')) {
    auth.Expiry = res.headers.get('Expiry')?.toString() || cache.Expiry;
  }
  localStore.updateItem('app-user', auth, parseInt(auth.Expiry || cache.Expiry, 10));
};

const getUserAuthParams = () => {
  const params = new URLSearchParams(window.location.search);
  const authHeaders: HeadersInit = {};
  if (params.has('access-token')) {
    authHeaders['Access-Token'] = params.get('access-token') || '';
    authHeaders.Client = params.get('client') || '';
    authHeaders.Uid = params.get('uid') || '';
  } else {
    const savedData = localStore.getItem('app-user');
    let cache: Record<string, string> = {};
    if (savedData) {
      if (typeof savedData === 'string') cache = JSON.parse(savedData) as Record<string, string>;
      authHeaders['Access-Token'] = cache['Access-Token'];
    }
    authHeaders.Client = cache.Client;
    authHeaders.Uid = cache.Uid;
    authHeaders.Expiry = cache.Expiry;
  }
  return authHeaders;
};

export const queryFetch = async <T>({
  url,
  data,
  opt,
  useMock = false,
  useAuth = true,
  formData,
  blob,
}: {
  url: string;
  data?: Record<string, unknown>;
  opt?: RequestInit;
  useMock?: boolean;
  useAuth?: boolean;
  formData?: boolean;
  blob?: boolean;
}): Promise<T> => {
  const createOptions = () => {
    const options: RequestInit = useMock
      ? {}
      : {
          headers: {
            'Access-Control-Allow-Origin': '*',
            'Cache-Control': 'no-store',
          },
          cache: 'reload',
        };
    options.method = opt?.method || 'GET';

    if (!formData) options.headers = { ...options.headers, 'Content-Type': 'application/json' };
    if (data) options.body = JSON.stringify(formatBody(data));
    if (opt?.body) options.body = opt.body;
    if (useAuth && !useMock) {
      options.headers = {
        ...options.headers,
        ...getUserAuthParams(),
      };
    }
    return options;
  };

  const apiUrl = `${useMock ? import.meta.env.VITE_MOCK_URL : import.meta.env.VITE_API_URL}`;
  const resp = await fetch(`${apiUrl}/${url}`, createOptions());

  if (resp.status === 401) localStorage.removeItem('app-user');

  saveUserAuthParams(resp.clone());

  if (blob) return resp as T;
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return formatResponse(await resp.json());
};
