import { foremanRoute } from '@utils/consts';

export const foremanArchiveRoutes = [
  {
    path: foremanRoute.archive,
    lazy: async () => {
      const { Archive } = await import('@pages/foreman/archive');
      return {
        Component: () => <Archive />,
      };
    },
  },
];
