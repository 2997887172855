import { foremanWidth as width } from '@utils/consts';
import { SchedulerGridCell } from '../../cell';
import { Foreman } from '@components/ui/foreman';
import { SchedulerGridForemanProps } from './types';
import { cn } from '@utils/cn';

export const SchedulerGridForeman = ({ name, first, last, lastRow }: SchedulerGridForemanProps) => (
  <SchedulerGridCell
    className={cn([
      'h-12 border-l-0 p-2',
      !lastRow ? 'border-b' : 'after:hidden',
      !last && 'border-b-transparent',
    ])}
    style={{ width }}>
    {first ? <Foreman name={name} /> : null}
  </SchedulerGridCell>
);
