import { useUpdateFilters } from '@services/users/filters/update';
import { useFilterKey } from './useFilterKey';
import { FilterValue, Filters } from '@services/users/filters/types';
import { useAppContext } from '@contexts/app';
import isEqual from 'deep-equal';

const EXCLUDED_KEYS = ['date', 'tab', 'page'] as const;

export const useSaveFilters = () => {
  const key = useFilterKey();
  const { mutate } = useUpdateFilters();
  const { filters } = useAppContext();

  return (newFilters: FilterValue) => {
    const params = newFilters;

    EXCLUDED_KEYS.forEach((el) => {
      if (!params[el]) return;
      delete params[el];
    });

    Object.keys(params).forEach((el) => {
      const paramKey = el as keyof FilterValue;
      if (!params[paramKey]?.length) delete params[paramKey];
    });

    if (!filters || !key || isEqual(filters?.[key], params)) return;
    mutate({ data: { ...filters, [key]: params } as Filters });
  };
};
