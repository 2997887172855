import { isPast, isSameDay, isToday, lastDayOfWeek, parseISO, startOfWeek } from 'date-fns';
import { isFirstVisible } from './header/helpers';
import { WorkloadData, WorkloadDraggableItem } from '@contexts/workload/types';
import { Speciality } from '@root/globalTypes';
import { WorkloadCalendarSchedule } from '@services/workload/calendar/types';

export const getFirstDayOfBusinessWeek = (date: Date): Date =>
  startOfWeek(date, { weekStartsOn: 1 });

export const getLastDayOfBusinessWeek = (date: Date): Date =>
  lastDayOfWeek(date, { weekStartsOn: 0 });

export const isFirstDayOfBusinessWeek = (date: Date): boolean =>
  date.getTime() === getFirstDayOfBusinessWeek(date).getTime();

export const isLastDayOfBusinessWeek = (date: Date): boolean =>
  date.getTime() === getLastDayOfBusinessWeek(date).getTime();

export const isBeforeToday = (date: Date): boolean => isPast(date) && !isToday(date);

export const isLastVisible = (dates: Date[], index: number): boolean => index === dates.length - 1;

export const appendSchedulesToDates = (
  dates: Date[],
  schedules: WorkloadCalendarSchedule[],
): WorkloadCalendarSchedule[][] =>
  dates.map((date) =>
    schedules.filter((schedule) => isSameDay(date, parseISO(schedule.startDate))),
  );

export const getBoundaryItemStyles = (date: Date, index: number, upperHeader?: boolean) => [
  isFirstDayOfBusinessWeek(date) && !isFirstVisible(index) && 'border-l-[5px] border-l-neutral-100',
  !upperHeader && isBeforeToday(date) && 'bg-neutral-50',
];

export const changeVisibleDays = (
  setData: (prop: keyof WorkloadData, value: unknown) => void,
  calendarWidth: number,
  visibleDays: number,
  initialDays: number,
) => {
  if (calendarWidth >= 1100 && visibleDays !== initialDays) setData('visibleDays', initialDays);
  if (calendarWidth < 1100 && calendarWidth > 900 && visibleDays !== initialDays - 1)
    setData('visibleDays', initialDays - 1);
  if (calendarWidth < 900 && calendarWidth > 700 && visibleDays !== initialDays - 2)
    setData('visibleDays', initialDays - 2);
  else if (calendarWidth <= 700 && visibleDays !== initialDays - 3) {
    setData('visibleDays', initialDays - 3);
  }
};

export const getForemanSpeciality = (type?: string): Speciality => {
  if (!type) return null;
  return (['wallsLayout', 'footingLayout'].includes(type) ? 'layout' : type) as Speciality;
};

export const getDraggableStyles = (date: Date, draggableItem?: WorkloadDraggableItem) => {
  if (!draggableItem) return;
  if (!draggableItem?.isDragging) return;
  return isBeforeToday(date) ? 'bg-neutral-100' : 'bg-neutral-50';
};
