import { IssueVariants } from './types';

export const variants: IssueVariants = {
  critical: {
    text: 'text-danger-400',
    border: 'border-danger-600',
    background: 'bg-danger-50',
    count: 'bg-danger-400',
  },
  regular: {
    text: 'text-warning-600',
    border: 'border-warning-600',
    background: 'bg-warning-50',
    count: 'bg-warning-600',
  },
  resolved: {
    text: 'text-neutral-700',
    border: 'border-neutral-200',
    background: 'bg-task-done',
    count: 'bg-neutral-300',
  },
};
