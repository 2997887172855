import { RemoveConfirmationAlert } from '@components/dialogs/removeConfirmationAlert';
import { useToast } from '@hooks/useToast';
import { useRemoveNote } from '@services/notes/remove';
import { useTranslation } from 'react-i18next';
import { RemoveNoteDialogProps } from './types';

export const RemoveNoteDialog = ({ children, id }: RemoveNoteDialogProps) => {
  const { toast } = useToast();
  const { t } = useTranslation('app');

  const remove = useRemoveNote({
    callback: () => {
      toast({
        title: t(`messages.success.note.remove`),
      });
    },
  });

  return (
    <RemoveConfirmationAlert
      title={t('deleteDialog.title', {
        item: t('deleteDialog.item.note'),
        name: '',
      })}
      description={t('deleteDialog.description', { item: t('deleteDialog.item.note') })}
      onConfirm={() => remove.mutate({ id })}
      locked={remove.isLoading}>
      {children}
    </RemoveConfirmationAlert>
  );
};
