import { SvgConsumer } from '@components/icons/svgConsumer';
import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { NoteDialog } from '@pages/jobs/:id/dialogs/note';
import { Note } from '@root/globalTypes';
import { useState } from 'react';
import { NoteBoxProps } from './types';
import { Button } from '@components/ui/button';
import { RemoveNoteDialog } from './removeNoteDialog';
import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';
import { useAppContext } from '@contexts/app';

export const NoteBox = ({ referableType, data, jobId, typeId, disabled }: NoteBoxProps) => {
  const { t } = useTranslation('app');
  const { isForeman } = useAppContext();
  const [open, setOpen] = useState(false);
  const [initialValues, setInitialValues] = useState<Note>();
  const isJob = referableType === 'Job';

  return (
    <div className={cn(['group flex overflow-hidden px-2.5 py-3', isJob ? 'w-full' : 'w-[282px]'])}>
      <AppTooltip side="top" context={t(`pages.job.info.note.${data ? 'update' : 'create'}`)}>
        <Button
          disabled={disabled}
          onClick={() => {
            setInitialValues({ ...data, jobId, typeId });
            setOpen(true);
          }}
          variant="ghost"
          className={cn(
            'mr-3 h-fit p-2 text-neutral-400 hover:text-primary-500',
            ['Subtask', 'Issue'].includes(referableType) && 'opacity-0 group-hover:opacity-100',
          )}>
          <SvgConsumer className="shrink-0 text-m" id={data?.content ? 'editNote' : 'addNote'} />
        </Button>
      </AppTooltip>
      {data && (
        <>
          <span className="mr-1 overflow-hidden text-ellipsis text-xs leading-4 text-neutral-500">
            <strong className="font-bold">{t('pages.job.info.note.title')}</strong>
            <p className="overflow-hidden whitespace-pre-line">{data.content}</p>
          </span>
          {!isForeman && (
            <RemoveNoteDialog id={data.id}>
              <button
                className={cn(
                  'mt-[-1px] flex h-4 w-4 items-center justify-center text-sm text-neutral-300 opacity-0 hover:text-danger-500 group-hover:opacity-100',
                  !isJob && 'ms-auto',
                )}
                type="button">
                <SvgConsumer className="shrink-0" id="trash" />
              </button>
            </RemoveNoteDialog>
          )}
        </>
      )}
      <NoteDialog
        open={open}
        setOpen={setOpen}
        initialValues={{ ...initialValues, referableType }}
      />
    </div>
  );
};
