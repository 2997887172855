import { ApiError } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

interface RemoveCancellationConfirmationRespSuccess {
  readonly success?: boolean;
}

type RemoveCancellationConfirmationResp = RemoveCancellationConfirmationRespSuccess | ApiError;

export const useRemoveCancellationConfirmation = ({
  callback,
}: {
  callback?: (data: RemoveCancellationConfirmationResp) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (id: string): Promise<RemoveCancellationConfirmationResp> =>
      queryFetch({
        url: `cancellation_confirmations/${id}`,
        opt: { method: 'DELETE' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (success) await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
    },
    onSettled: (data) => {
      if (callback && data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
