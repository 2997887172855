import { Checkbox } from '@components/ui/checkbox';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hooks/useToast';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { useRemoveCancellationConfirmation } from '@services/schedules/removeCancellationConfirmation';
import { useJobContext } from '@contexts/job';
import { Phone } from '@components/ui/phone';
import { JobFlagProps } from './types';

export const JobFlag = ({ data }: JobFlagProps) => {
  const { toast } = useToast();
  const { archived, allowed } = useJobContext();
  const { t } = useTranslation('app');

  const { mutate, isLoading } = useRemoveCancellationConfirmation({
    callback: () => {
      toast({
        title: t('messages.success.cancellation.remove'),
      });
    },
  });

  return (
    <>
      <div className="col-start-1 col-end-3 flex items-center gap-6 border-t border-warning-600 bg-warning-50 px-6 py-3">
        <h4 className="flex items-center gap-3.5 text-xs font-medium">
          <SvgConsumer className="text-m text-warning-600" id="outgoingCall" />
          <span className="font-medium text-warning-600">
            {t('schedules.cancellationConfirmation')}
          </span>
          <span className="flex gap-2 font-bold text-warning-700">
            <span>{data.contact?.name}</span>
            <a className="hover:underline" href={`tel:${data.contact?.phone}`}>
              <Phone value={data.contact?.phone} />
            </a>
          </span>
        </h4>
      </div>

      <div className="col-start-3 col-end-5 border-t border-warning-600 bg-warning-50">
        <div className="h-full w-fit border-x px-4.5 py-2.5">
          <Checkbox
            disabled={isLoading || !allowed || archived}
            onCheckedChange={() => mutate(data.id as string)}
          />
        </div>
      </div>
    </>
  );
};
