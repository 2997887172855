import { createContext, useContext } from 'react';
import { JobContextData, JobProviderProps } from './types';

export const JobContext = createContext<JobContextData | Record<string, never>>({});

export const JobProvider = ({ children, value }: JobProviderProps) => (
  <JobContext.Provider value={value}>{children}</JobContext.Provider>
);

// eslint-disable-next-line react-refresh/only-export-components
export const useJobContext = () => useContext(JobContext);
