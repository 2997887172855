import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';
import { JobTaskStatus } from '@root/globalTypes';
import { variants } from '../../helpers';

interface JobTaskPillProps {
  status?: JobTaskStatus;
  size?: 'small' | 'big';
  children?: string;
}

export const JobTaskPill = ({ status, size = 'big', children }: JobTaskPillProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.job.info.status' });

  if (!status) return null;
  if (['to_schedule'].includes(status)) return null;
  return (
    <span
      className={cn(
        'flex w-fit items-center justify-center whitespace-nowrap rounded-lg font-semibold',
        size === 'big' && 'min-w-[90px] px-2.5 py-0.5 text-sm',
        size === 'small' && 'h-[18px] px-3 text-xs',
        variants[status],
      )}>
      {children || t(status)}
    </span>
  );
};
