import { ProtectedRoute } from '@components/protectedRoute';
import { InventoryProvider } from '@contexts/inventory_new';
import { InventoryQuantitiesProvider } from '@contexts/inventory_new/quantities';
import { appRoutes, usersAccessList } from '@utils/consts';

export const inventoryRoutes = [
  {
    path: appRoutes.inventory,
    lazy: async () => {
      const { Inventory } = await import('@pages/inventory_new');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.inventory)}>
            <InventoryProvider>
              <InventoryQuantitiesProvider>
                <Inventory />
              </InventoryQuantitiesProvider>
            </InventoryProvider>
          </ProtectedRoute>
        ),
      };
    },
  },
];
