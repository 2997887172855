import * as React from 'react';
import * as CheckboxPrimitive from '@radix-ui/react-checkbox';
import { Check } from 'lucide-react';
import { cn } from '@utils/cn';
import { FormLabel } from '../form';

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, children, ...props }, ref) => (
  <div className="flex items-center gap-2.5">
    <CheckboxPrimitive.Root
      ref={ref}
      className={cn(
        'peer h-5 w-5 shrink-0 rounded border border-neutral-400 bg-white text-primary-500 ring-offset-white focus-visible:shadow-primary-btn focus-visible:outline-none disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-primary-600 disabled:opacity-60',
        className,
      )}
      {...props}>
      <CheckboxPrimitive.Indicator className="flex items-center justify-center text-current">
        <Check className="h-5 w-auto" />
      </CheckboxPrimitive.Indicator>
    </CheckboxPrimitive.Root>
    {children && <FormLabel htmlFor={props.id}>{children}</FormLabel>}
  </div>
));
// eslint-disable-next-line immutable/no-mutation
Checkbox.displayName = CheckboxPrimitive.Root.displayName;

export { Checkbox };
