import { createContext, useContext, useEffect, useState } from 'react';
import { MapContextData, MapData, MapProviderProps } from './types';
import { DEFAULT_FOCUS_ZOOM, initialState } from './constants';
import { useGetMap } from '@services/map';
import { useMap } from '@vis.gl/react-google-maps';
import { useQueryParams } from '@hooks/useQueryParams';
import { FilterValue } from '@services/users/filters/types';
import { focusOnElement, hasCoords } from './helpers';

const MapContext = createContext<MapContextData | Record<string, never>>({});

export const MapProvider = ({ children }: MapProviderProps) => {
  const [state, setState] = useState<MapData>(initialState);

  const map = useMap();

  const {
    params: { markerId },
  } = useQueryParams<FilterValue>();

  const dataResp = useGetMap();
  const data = dataResp.data?.records;

  useEffect(() => {
    if (!data) return;
    setState((prev) => ({ ...prev, data }));
  }, [data]);

  useEffect(() => {
    if (!map || !data) return;
    const marker = data.find((el) => el.id === markerId);
    setState((prev) => ({ ...prev, marker: marker || initialState.marker }));
    if (!marker) return;
    if (!hasCoords(marker)) return;
    map.setCenter({ lat: Number(marker.lat), lng: Number(marker.lon) });
    map.setZoom(DEFAULT_FOCUS_ZOOM);
    focusOnElement(marker.id);
  }, [map, markerId, data]);

  return <MapContext.Provider value={{ ...state, setState }}>{children}</MapContext.Provider>;
};

// eslint-disable-next-line react-refresh/only-export-components
export const useMapContext = () => useContext(MapContext);
