/* eslint-disable camelcase */

import {
  Alert,
  BasicJobStage,
  JobSubtask,
  JobTask,
  JobTaskData,
  JobTaskStatus,
} from '@root/globalTypes';

export type JobTaskAction = 'schedule' | 'reschedule' | 'form' | 'none';

export type JobStatus =
  | 'completed'
  | 'error'
  | 'warning'
  | 'scheduled'
  | 'default'
  | 'readyToSchedule';

export const setBackground = (status: JobTaskStatus, isSubTask?: boolean) => {
  switch (status) {
    case 'completed':
      return 'bg-task-done';
    case 'rejected':
      return 'bg-task-rejected';
    default:
      return isSubTask ? 'bg-white' : 'bg-task-default';
  }
};

export const variants: Record<JobTaskStatus, string> = {
  scheduled: 'bg-blue-100 text-blue-500',
  schedule_request_sent: 'bg-neutral-100 text-neutral-800',
  rejected: 'bg-danger-100 text-danger-500',
  completed: 'bg-success-100 text-success-800',
  to_schedule: '',
  not_started: '',
  in_progress: 'bg-blue-400 text-white',
  ready_to_schedule: 'bg-white text-blue-400 border-blue-400 border border-dashed',
};

export const sortByProp = <T>(array: T[], prop: string, direction: 'asc' | 'desc') => {
  return array.sort((a, b) =>
    direction === 'desc'
      ? (b as Record<string, number>)[prop] - (a as Record<string, number>)[prop]
      : (a as Record<string, number>)[prop] - (b as Record<string, number>)[prop],
  );
};

export const filterDuplicates = <T>(array: T[], prop: keyof T) => {
  return array.filter((value, i, self) => i === self.findIndex((t) => t[prop] === value[prop]));
};

export const sortAlerts = (alerts: Alert[]) => {
  return alerts.sort((a, b) =>
    a.alertType < b.alertType ? -1 : a.alertType > b.alertType ? 1 : 0,
  );
};

export const getTaskAlerts = (task: JobTask): Alert[] => {
  let alerts: Alert[] = [];
  if (task?.alerts?.length) alerts = [...alerts, ...task.alerts];

  task?.subtasks?.forEach((subtask) => {
    if (subtask.alerts?.length) alerts = [...alerts, ...subtask.alerts];
  });

  return sortAlerts(alerts);
};

export const getAlertsStatus = (alerts?: Alert[]): JobStatus => {
  if (alerts?.find((alert) => ['error', 'issue'].includes(alert.alertType))) return 'error';
  if (alerts?.find((alert) => ['warning', 'global_warning'].includes(alert.alertType)))
    return 'warning';
  return 'default';
};

export const getTaskStatus = (task: unknown) => getAlertsStatus(getTaskAlerts(task as JobTask));

export const getJobStatus = (stage?: BasicJobStage, skipStageStatus?: boolean): JobStatus => {
  const alertsStatus = getAlertsStatus(stage?.alerts);

  if (!skipStageStatus) {
    if (stage?.status === 'completed') return 'completed';
    if (stage?.status === 'ready_to_schedule') return 'readyToSchedule';
    if (alertsStatus === 'default' && ['scheduled', 'in_progress'].includes(stage?.status || ''))
      return 'scheduled';
  }

  return alertsStatus;
};

export const getConcreteFromSubtask = (subtask?: JobSubtask) => {
  return subtask?.materials?.find((m) => m.options?.find((o) => o.name === 'Slump'));
};

export const isWithoutSchedule = (data: JobTaskData) => data.date === 'without_schedule';

export const getAction = ({ status, schedule, taskConfirmation }: JobTask): JobTaskAction => {
  if (taskConfirmation === 'form') return 'form';
  if (['to_schedule', 'ready_to_schedule'].includes(status || '') && !schedule) return 'schedule';
  if (schedule) return 'reschedule';
  return 'none';
};
