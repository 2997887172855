import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import {
  type LogInUserReq,
  type LogInUserResp,
  useLogIn,
  LogInUserRespSuccess,
} from '@services/auth/loginUser';
import { SetError, handleOnSettled, useHandleFormErrors } from '@utils/formHelpers';
import { useAppContext } from '@contexts/app';
import { useTranslation } from 'react-i18next';
import { localStore } from '@utils/localStore';
import { modelUserData } from '@utils/userData';

export const useLogInForm = ({
  successHandler: successHandler,
}: {
  successHandler: (resp?: LogInUserResp) => void;
}) => {
  const handleFormErrors = useHandleFormErrors();
  const { t } = useTranslation('app');
  const { setData } = useAppContext();
  const formSchema = z.object({
    email: z
      .string()
      .trim()
      .email({ message: t('forms.email.required') }),
    password: z
      .string()
      .trim()
      .min(8, {
        message: t('forms.password.required'),
      }),
  });

  const defaultValues: LogInUserReq = {
    email: '',
    password: '',
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSettled = (resp: LogInUserResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };
    let error: unknown = undefined;
    if (typeof resp?.errors !== 'undefined') {
      error = resp.errors;
    } else {
      const userData = resp as LogInUserRespSuccess;
      if (typeof setData === 'function') {
        setData((prev) => ({
          ...prev,
          ...modelUserData(userData.data || {}),
        }));
        const store = localStore.getItem('app-user');
        if (typeof store === 'string') {
          const tmp = JSON.parse(store) as Record<string, string>;
          // eslint-disable-next-line immutable/no-mutation
          tmp.id = userData.data?.id || '';
          localStore.setItem('app-user', JSON.stringify(tmp));
        }
      }
    }
    handleOnSettled<LogInUserResp>(resp, successHandler, () =>
      handleFormErrors(handleError, Object.keys(defaultValues), { root: error }),
    );
  };
  const { mutate, isLoading } = useLogIn({ callback: onSettled });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    mutate(values);
  };

  return {
    form,
    onSubmit,
    locked: isLoading,
  };
};
