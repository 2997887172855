import { Input, InputProps } from '@components/ui/form/input';
import { forwardRef } from 'react';

interface JobSubTaskInputProps extends InputProps {
  label: string;
  suffix?: string;
}

export const JobSubTaskInput = forwardRef<HTMLInputElement, JobSubTaskInputProps>(
  ({ label, suffix, ...props }, ref) => (
    <>
      <span className="flex h-[40px] items-center whitespace-nowrap text-sm font-medium text-neutral-800">
        {label}:
      </span>
      <Input
        {...props}
        ref={ref}
        type="number"
        autoComplete="off"
        step="any"
        className="[&>input]:w-14"
        suffixElement={<span className="font-semibold">{suffix}</span>}
      />
    </>
  ),
);
