import { Avatar } from '@components/avatar';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { IssueAuthorProps } from './types';
import { formatDate, formatTime } from '@utils/i18n';
import { parseISO } from 'date-fns';
import { cn } from '@utils/cn';

export const IssueAuthor = ({ date, name, foreman }: IssueAuthorProps) => {
  const parsedDate = parseISO(date);

  return (
    <div className={cn(['flex items-center gap-6 font-medium', foreman && 'text-neutral-600'])}>
      <span className={cn(['flex items-center gap-2', foreman && 'text-xs'])}>
        <span className="flex items-center gap-2">
          <SvgConsumer className="shrink-0 text-sm" id="calendar" />
          {formatDate(parsedDate)}
        </span>
        <span className="whitespace-nowrap">{formatTime(parsedDate)}</span>
      </span>
      <span className={cn(['flex items-center gap-2', foreman && 'text-xs'])}>
        <Avatar className="h-6 w-6 font-normal" name={name} />
        {name}
      </span>
    </div>
  );
};
