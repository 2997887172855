import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from '../translations/en.json';
import es from '../translations/es.json';
import { appRoutes, getLangFromPath } from '@utils/consts';

export const resources = {
  en: { app: en },
  es: { app: es },
} as const;

// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n.use(initReactI18next).init({
  resources,
  lng: getLangFromPath(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

/* eslint-disable immutable/no-mutation */
document.documentElement.lang = i18n.language;

export const handleLanguageChange = async (langParam = '') => {
  const lang = langParam || getLangFromPath();
  await i18n.changeLanguage(lang);
  document.documentElement.lang = lang;
};

export const isEn = () => {
  return i18n.language === 'en';
};

export const parseRoute = (url: string) => {
  const languagePrefix = isEn() ? '' : i18n.language;
  if (url === appRoutes.index) return `/${languagePrefix}`;
  return `${languagePrefix ? `${languagePrefix}/` : ''}${url}`;
};

export const unparseRoute = (url: string) => (isEn() ? url : url.split(`/${i18n.language}`)[1]);

const locale = `${i18n.language}-US`;

export const formatTime = (date: Date) =>
  new Intl.DateTimeFormat(locale, { timeStyle: 'short' }).format(date);

export const formatDate = (date: Date, defaultLocale: string = locale) =>
  new Intl.DateTimeFormat(defaultLocale, {
    weekday: 'short',
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  }).format(date);

export const shortDay = (date: Date) =>
  new Intl.DateTimeFormat(locale, { weekday: 'short' }).format(date);

export const numericDay = (date: Date) =>
  new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(date);

export const shortMonth = (date: Date) =>
  new Intl.DateTimeFormat(locale, { month: 'short' }).format(date);

export const longMonth = (date: Date) =>
  new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);

export const shortDate = (date: Date) =>
  new Intl.DateTimeFormat(locale, { day: 'numeric', month: 'short' }).format(date);

export default i18n;
