import { cn } from '@utils/cn';
import { MenuItemProps } from './types';

export const MenuItem = ({ children, onClick, variant }: MenuItemProps) => (
  <button
    title={children}
    type="button"
    onClick={onClick}
    className={cn([
      'overflow-hidden text-ellipsis whitespace-nowrap text-left leading-6 text-neutral-900 hover:bg-primary-500 hover:text-white',
      variant === 'big' && 'px-4 py-1.5',
      variant === 'small' && 'px-3 py-0.5',
    ])}>
    {children}
  </button>
);
