import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { NotificationResp } from '../types';

export const useReadNotification = ({
  callback,
}: {
  callback: (data: NotificationResp) => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (id: string): Promise<NotificationResp> =>
      queryFetch({ url: `notifications/${id}/mark_as_read`, opt: { method: 'PUT' } }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (success) await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
