import { useCallback, useEffect, useState } from 'react';

export const useElementMeasures = (className: string, dependency?: string): DOMRect => {
  const [measures, setMeasures] = useState<DOMRect>(new DOMRect());
  const element = document.querySelector(`.${className}`);

  const updateMeasures = useCallback(() => {
    const newMeasures = element?.getBoundingClientRect();
    if (!newMeasures) return;
    setMeasures(newMeasures);
  }, [element]);

  const handleResize = useCallback(() => {
    updateMeasures();
  }, [updateMeasures]);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [handleResize]);

  useEffect(() => {
    updateMeasures();
  }, [updateMeasures, dependency]);

  return measures;
};
