import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@components/ui/dialog';
import { InternalScheduleDialogProps } from './types';
import { useTranslation } from 'react-i18next';
import { Button } from '@components/ui/button';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { numericDay, shortDay } from '@utils/i18n';
import { DatePicker } from '@components/ui/form/date';
import { useDate } from '@hooks/date';
import { RemoveConfirmationAlert } from '@components/dialogs/removeConfirmationAlert';
import { useEffect, useState } from 'react';
import { Scheduler } from '@components/scheduler';
import { useTeamsSchedules } from '@services/jobs/schedules/teams';
import { Form, FormField } from '@components/ui/form';
import { useInternalScheduleForm } from '@hooks/forms/useSchedule.form';
import { useToast } from '@hooks/useToast';
import { FormItemWrapper } from '@components/ui/form/itemWrapper';
import { Input } from '@components/ui/form/input';
import { Checkbox } from '@components/ui/checkbox';
import { useRemoveInternalSchedule } from '@services/schedules/internal/remove';
import { formatDate } from '@utils/dateHelpers';
import { useSchedulerContext } from '@contexts/scheduler';
import { initialState } from '@contexts/scheduler/constants';

export const InternalScheduleDialog = ({
  open,
  setOpen,
  type,
  schedule,
  jobId,
  initialDate,
  activeId,
}: InternalScheduleDialogProps) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();
  const [isConfirmDialog, setIsConfirmDialog] = useState(false);
  const { active, setState } = useSchedulerContext();

  const { date, rawDate, setRawDate, handlePrevDay, handleNextDay } = useDate({
    initialDate,
    disableLoadFromParams: true,
    disableParamsUpdate: true,
  });

  const { data } = useTeamsSchedules({
    date: formatDate(date),
    foremanSpeciality: type,
    active: open,
  });

  const removeSchedule = useRemoveInternalSchedule({
    id: active.schedule.id,
    jobId,
    callback: () => {
      setOpen(false);
      toast({ title: t('messages.success.internalSchedule.remove') });
    },
  });

  const { form, onSubmit, locked } = useInternalScheduleForm({
    successHandler: () => {
      form.reset();
      setOpen(false);
      toast({
        title: t(`messages.success.internalSchedule.${schedule?.id ? 'reschedule' : 'schedule'}`),
      });
    },
    onOverlap: () => {
      setIsConfirmDialog(true);
    },
  });

  const { setValue } = form;

  useEffect(() => {
    if (!open) {
      setState(initialState);
      if (initialDate) setRawDate(initialDate);
      return;
    }
    if (schedule?.foreman?.id) setValue('foremanId', schedule?.foreman?.id);
    if (!schedule?.id && (type === 'footing' || type === 'walls')) setValue('confirmed', true);
    if (schedule?.status === 'approved') setValue('confirmed', true);
    if (schedule?.task?.note?.content) setValue('note', schedule.task.note.content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialDate, open, schedule, type, setValue]);

  return (
    <>
      <Dialog open={open} onOpenChange={setOpen}>
        <DialogContent className="!container max-w-full">
          <DialogHeader className="relative flex flex-row items-center justify-between">
            <DialogTitle>{t('schedule.team', { team: t(`specialty.${type}`) })}</DialogTitle>
            <div className="absolute bottom-0 left-1/2 flex -translate-x-1/2 items-center gap-2 text-heading-xs">
              <Button variant="ghost" className="p-1" onClick={handlePrevDay}>
                <SvgConsumer id="arrow" className="-scale-x-100" />
              </Button>
              <strong className="select-none whitespace-nowrap">
                {shortDay(rawDate)}, {numericDay(rawDate)}
              </strong>
              <DatePicker
                noText
                noRemove
                className="border-0 p-1 text-inherit"
                value={rawDate}
                onChange={(newDate) => {
                  if (!newDate) return;
                  setRawDate(newDate);
                }}
                placeholder="Select"
              />
              <Button variant="ghost" className="p-1" onClick={handleNextDay}>
                <SvgConsumer id="arrow" />
              </Button>
            </div>
          </DialogHeader>
          <Scheduler
            modal
            date={date}
            foremen={data?.records}
            withoutTeam={data?.withoutTeam}
            schedule={schedule}
            activeId={activeId}
          />
          <Form {...form}>
            {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
            <form onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="forceOverlapping"
                render={() => (
                  <FormItemWrapper>
                    <input type="hidden" />
                  </FormItemWrapper>
                )}
              />
              <FormField
                control={form.control}
                name="foremanId"
                render={({ field }) => (
                  <FormItemWrapper>
                    <input type="hidden" {...field} />
                  </FormItemWrapper>
                )}
              />
              <FormField
                control={form.control}
                name="startsAt"
                render={({ field }) => (
                  <FormItemWrapper>
                    <input type="hidden" {...field} />
                  </FormItemWrapper>
                )}
              />
              <FormField
                control={form.control}
                name="endsAt"
                render={({ field }) => (
                  <FormItemWrapper>
                    <input type="hidden" {...field} />
                  </FormItemWrapper>
                )}
              />
              <FormField
                control={form.control}
                name="note"
                render={({ field }) => (
                  <FormItemWrapper label={t('forms.note.label')}>
                    <Input {...field} />
                  </FormItemWrapper>
                )}
              />
              <div className="mt-4 flex items-center justify-end gap-2">
                <div>
                  <FormField
                    control={form.control}
                    name="confirmed"
                    render={({ field }) => (
                      <FormItemWrapper>
                        <div className="flex gap-2">
                          <Checkbox
                            id="confirmed"
                            checked={Boolean(field.value)}
                            onCheckedChange={field.onChange}>
                            {t('schedules.markAsConfirmed')}
                          </Checkbox>
                        </div>
                      </FormItemWrapper>
                    )}
                  />
                </div>
                <Button
                  variant="ghost"
                  onClick={() => {
                    form.reset();
                    setOpen(false);
                  }}>
                  {t('actions.cancel')}
                </Button>
                {schedule?.id && schedule?.id !== 'new' && (
                  <RemoveConfirmationAlert
                    title={t('schedules.removeTitle')}
                    description={t('schedules.removeInfo')}
                    onConfirm={removeSchedule.mutate}
                    locked={removeSchedule.isLoading}>
                    <Button variant="dangerSecondary">{t('actions.removeSchedule')}</Button>
                  </RemoveConfirmationAlert>
                )}
                <Button disabled={locked || !active.schedule.id} type="submit">
                  {schedule?.id && schedule?.id !== 'new'
                    ? t('actions.reschedule')
                    : t('actions.send')}
                </Button>
              </div>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <RemoveConfirmationAlert
        open={isConfirmDialog}
        setOpen={setIsConfirmDialog}
        locked={locked || !active.schedule.id}
        title={t('overlappingSchedules.title')}
        description={t('overlappingSchedules.description')}
        onCancel={() => {
          setValue('forceOverlapping', false);
        }}
        onConfirm={() => {
          setValue('forceOverlapping', true);
          // eslint-disable-next-line @typescript-eslint/no-floating-promises
          form.handleSubmit(onSubmit)();
        }}
      />
    </>
  );
};
