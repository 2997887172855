type LocalData = {
  ttl: number;
  data: string | object;
};

export const isLocalObj = (object: unknown): object is LocalData => {
  if (object !== null && typeof object === 'object') {
    return 'ttl' in object;
  }
  return false;
};

export const localStore = {
  setItem(key: string, value: string | object, tOffset?: number) {
    const timeOffset = tOffset && tOffset > 0 ? tOffset : 1000 * 3600 * 24 * 7;
    const data = JSON.stringify({
      ttl: new Date().getTime() + timeOffset,
      data: value,
    });
    localStorage.setItem(key, data);
  },
  getItem(key: string) {
    const rawData = localStorage.getItem(key);
    if (!rawData) return null;
    try {
      const keyData: unknown = JSON.parse(rawData);
      if (isLocalObj(keyData)) {
        if (keyData.ttl < new Date().getTime()) {
          localStorage.removeItem(key);
          return null;
        }
        if (!keyData.data) return null;
        return keyData.data;
      }
    } catch (error) {
      console.error(error);
      localStorage.clear();
    }
  },
  updateItem(key: string, value: object, tOffset?: number) {
    const timeOffset = tOffset && tOffset > 0 ? tOffset : 1000 * 3600 * 24 * 7;
    const oldValue = localStore.getItem(key);
    let newValue = {};
    if (oldValue && typeof oldValue === 'string') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const json = JSON.parse(oldValue);
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      newValue = {
        ...json,
        ...value,
      };
    } else {
      newValue = value;
    }
    const data = JSON.stringify({
      ttl: new Date().getTime() + timeOffset,
      data: JSON.stringify(newValue),
    });
    localStorage.setItem(key, data);
  },
  removeItem(key: string) {
    localStorage.removeItem(key);
  },
};
