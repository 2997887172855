import { ProtectedRoute } from '@components/protectedRoute';
import { appRoutes, usersAccessList } from '@utils/consts';

export const workloadRoutes = [
  {
    path: appRoutes.workload,
    lazy: async () => {
      const { Workload } = await import('@pages/workload');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.workload)}>
            <Workload />
          </ProtectedRoute>
        ),
      };
    },
  },
];
