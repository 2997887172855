import {
  addHours,
  set,
  addMinutes,
  parseISO,
  getDate,
  getMinutes,
  setMinutes,
  getMonth,
  getYear,
  isBefore,
  isAfter,
} from 'date-fns';
import { HOURS_COUNT, SEGMENT_LENGTH, START_HOUR, initialState } from './constants';
import { Job, JobTask } from '@root/globalTypes';
import { Schedule } from '@services/jobs/schedules/teams';

export const getFirstHour = (date: Date) =>
  set(date, { hours: START_HOUR, minutes: 0, seconds: 0 });

export const getLastHour = (hours: Date[][]) => {
  const lastHour = hours[hours.length - 1];
  return addMinutes(lastHour[lastHour.length - 1], SEGMENT_LENGTH);
};

export const appendHours = (array: unknown[], date: Date) => array.map((_, i) => addHours(date, i));

export const getHours = (date: Date) => appendHours([...new Array<undefined>(HOURS_COUNT)], date);

export const createSchedule = (task: JobTask, data?: Job) => ({
  ...initialState.active.schedule,
  id: 'new',
  task: {
    ...initialState.active.schedule.task,
    ...task,
    job: data || initialState.active.schedule.task?.job,
  },
});

export const setDatesForCurrentDay = (date?: Date, startsAt?: string, endsAt?: string) => {
  if (!startsAt || !endsAt) return { startsAt: '', endsAt: '' };
  if (!date) return { startsAt, endsAt };

  return {
    startsAt: set(parseISO(startsAt), {
      date: getDate(date),
      month: getMonth(date),
      year: getYear(date),
    }).toISOString(),
    endsAt: set(parseISO(endsAt), {
      date: getDate(date),
      month: getMonth(date),
      year: getYear(date),
    }).toISOString(),
  };
};

export const roundDate = (date: string | undefined, hours?: Date[][]) => {
  if (!date || !hours) return undefined;

  const parsedDate = parseISO(date);
  const firstHour = getFirstHour(parsedDate);
  const lastHour = getLastHour(hours);
  const minutes = getMinutes(parsedDate);
  const minutesToSegment = minutes % SEGMENT_LENGTH;

  const newDate = setMinutes(
    parsedDate,
    minutesToSegment > 7
      ? minutes + (SEGMENT_LENGTH - minutesToSegment)
      : minutes - minutesToSegment,
  );

  if (isBefore(newDate, firstHour)) return firstHour;
  if (isAfter(newDate, lastHour)) return lastHour;
  return newDate;
};

export const roundSchedulesDates = (data: Schedule[], hours: Date[][]): Schedule[] =>
  data.map((el) => ({
    ...el,
    startsAt: roundDate(el.startsAt, hours)?.toISOString() || '',
    endsAt: roundDate(el.endsAt, hours)?.toISOString() || '',
  }));
