import { useEffect, useRef, useState } from 'react';

export const useFileInput = () => {
  const ref = useRef<HTMLInputElement>(null);
  const [files, setFiles] = useState<FileList | null>(null);

  const removeFile = (index: number) => {
    if (!ref?.current) return;
    const dt = new DataTransfer();
    const input = ref.current;
    const inputFiles = input.files as FileList;
    for (let i = 0; i < inputFiles.length; i++) {
      const file = inputFiles[i];
      if (index !== i) dt.items.add(file);
    }
    setFiles(dt.files);
  };

  useEffect(() => {
    if (!ref?.current) return;
    const input = ref.current;
    // eslint-disable-next-line immutable/no-mutation
    input.files = files;
  }, [files]);

  return { ref, files, setFiles, removeFile };
};
