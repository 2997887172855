import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CreateInternalScheduleReq, CreateInternalScheduleResp } from './createSchedule';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';

export const useUpdateInternalSchedule = ({
  id,
  callback,
}: {
  id: string;
  callback: (data: CreateInternalScheduleResp) => void;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (data: CreateInternalScheduleReq): Promise<CreateInternalScheduleResp> => {
      const { scheduleId } = data;
      return queryFetch({
        url: `jobs/${id}/internal_schedules/${scheduleId}/reschedule`,
        data,
        opt: { method: 'POST' },
      });
    },
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: [...queryKeys.job, id] });
      await queryClient.refetchQueries({ queryKey: [...queryKeys.jobStages, id] });
      await queryClient.refetchQueries({ queryKey: queryKeys.onDeck });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendar });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendarSidebar });
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    reschedule: mutate,
    isUpdating: isPending,
  };
};
