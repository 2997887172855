import { useQuery } from '@tanstack/react-query';
import { ApiError, SubtaskForFile } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { useAppContext } from '@contexts/app';

export interface SubtasksDropdownRespSuccess {
  success: boolean;
  records: SubtaskForFile[];
}

export const fetchSubtasksDropdown = async (
  jobId?: string,
): Promise<SubtasksDropdownRespSuccess> => {
  return queryFetch({
    url: `dropdowns/subtasks_for_files${jobId ? `?job_id=${jobId}` : ''}`,
  });
};

export const useGetSubtasksDropdown = ({ jobId }: { jobId?: string }) => {
  const { isForeman } = useAppContext();
  const { data, error, isFetching } = useQuery<SubtasksDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.subtasksDropdown,
    queryFn: () => fetchSubtasksDropdown(jobId),
    enabled: !isForeman && Boolean(jobId && uuidRegEx.test(jobId)),
    placeholderData: (previousData) => previousData as SubtasksDropdownRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
