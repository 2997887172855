import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { initialState } from './constants';
import {
  InventoryQuantitiesContextData,
  InventoryQuantitiesData,
  InventoryQuantitiesProviderProps,
} from './types';
import { useLocationsDropdown } from '@services/inventory/locations/dropdown';
import { useGetLocationItems } from '@services/inventory/locations/items';

const InventoryQuantitiesContext = createContext<
  InventoryQuantitiesContextData | Record<string, never>
>({});

export const InventoryQuantitiesProvider = ({ children }: InventoryQuantitiesProviderProps) => {
  const [state, setState] = useState<InventoryQuantitiesData>(initialState);

  const dataResp = useGetLocationItems(state.location);
  const data = dataResp.data?.records;

  const dropdownResp = useLocationsDropdown();
  const dropdown = dropdownResp.data?.records;

  const toggle = (open: boolean) => {
    setState((prev) => ({ ...prev, open }));
  };

  const setLocation = useCallback(
    (id?: string) => {
      const location = state.dropdown.find((el) => el.locationId === id) || initialState.location;
      setState((prev) => ({ ...prev, location }));
    },
    [state.dropdown],
  );

  useEffect(() => {
    if (!data) return;
    setState((prev) => ({ ...prev, data }));
  }, [data]);

  useEffect(() => {
    if (!dropdown) return;
    setState((prev) => ({ ...prev, dropdown }));
  }, [dropdown]);

  return (
    <InventoryQuantitiesContext.Provider
      value={{
        toggle,
        setLocation,
        isLoading: dataResp.isLoading || dropdownResp.isLoading,
        ...state,
        setState,
      }}>
      {children}
    </InventoryQuantitiesContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useInventoryQuantitiesContext = () => useContext(InventoryQuantitiesContext);
