import { useTranslation } from 'react-i18next';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@components/ui/dialog';
import { Dispatch, SetStateAction } from 'react';
import { Button } from '@components/ui/button';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { formatDate, formatTime } from '@utils/i18n';
import { transformSnakeToCamelCase } from '@utils/textHelpers';
import { Link } from 'react-router-dom';
import { useReadNotifications } from '@services/notifications/read';
import { toast } from '@hooks/useToast';
import { useClearNotifications } from '@services/notifications/clear';
import { useReadNotification } from '@services/notifications/id/read';
import { parseISO } from 'date-fns';
import { cn } from '@utils/cn';
import { NotificationsRespSuccess } from '@services/notifications';

interface NotificationsDialogProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  data?: NotificationsRespSuccess;
}

export const NotificationsDialog = ({ open, setOpen, data }: NotificationsDialogProps) => {
  const { t } = useTranslation('app');

  const read = useReadNotification({
    callback: () => {
      toast({
        title: t('messages.success.notifications.read'),
      });
    },
  });

  const readAll = useReadNotifications({
    callback: () => {
      toast({
        title: t('messages.success.notifications.readAll'),
      });
    },
  });

  const clearAll = useClearNotifications({
    callback: () => {
      setOpen(false);
      toast({
        title: t('messages.success.notifications.clearAll'),
      });
    },
  });

  return (
    <Dialog open={open} onOpenChange={() => setOpen(false)}>
      <DialogContent className="w-[calc(100%-3rem)] gap-0 p-0 md:max-w-lg">
        <DialogHeader className="p-6">
          <DialogTitle>{t('notificationsDialog.title')}</DialogTitle>
        </DialogHeader>
        <div className=" flex max-h-[345px] flex-col overflow-auto px-6 text-sm font-medium">
          {data?.records?.map((el) => {
            const parsedDate = el.createdAt ? parseISO(el.createdAt) : undefined;
            return (
              <Link
                key={el.id}
                to={el.link || ''}
                onClick={(e) => {
                  if (!el.read) read.mutate(el.id);
                  if (el.link) setOpen(false);
                  else e.preventDefault();
                }}
                className={cn([
                  'flex items-center gap-2.5 border-b border-neutral-200 py-4 focus-visible:underline focus-visible:outline-none',
                  !el.link && el.read && 'pointer-events-none cursor-default',
                  el.link && 'hover:underline',
                  !el.read && 'font-bold',
                ])}>
                <span className="shrink-0">
                  <SvgConsumer id="questionMark" className="text-xl text-blue-400" />
                </span>
                {[
                  el.message ||
                    t(`notifications.${transformSnakeToCamelCase(el.activityType || '')}`, {
                      jobAddress: el.jobAddress,
                      name: el.creator.fullName,
                      taskName: el.taskName,
                    }),
                  parsedDate ? [formatDate(parsedDate), formatTime(parsedDate)].join(' ') : null,
                ].join(': ')}
              </Link>
            );
          })}
        </div>
        <div className="flex items-center justify-end gap-2 bg-white p-6">
          <Button
            className="text-m 2xl:text-sm"
            variant="dangerSecondary"
            onClick={() => clearAll.mutate()}>
            {t('actions.clearAll')}
          </Button>
          <Button
            disabled={!data?.unreadNotifications}
            className="text-m 2xl:text-sm"
            variant="secondary"
            onClick={() => readAll.mutate()}>
            {t('actions.markAllAsRead')}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};
