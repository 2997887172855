import { Edge } from '@components/edge';
import { cn } from '@utils/cn';
import { SchedulerGridScheduleEdgeProps } from './types';

export const SchedulerGridScheduleEdge = ({ position, styles }: SchedulerGridScheduleEdgeProps) => (
  <Edge
    count={4}
    size={8}
    elementClassName={styles}
    position={position}
    className={cn(['top-[1.5px]', position === 'left' ? 'left-[-3px]' : 'right-[-3px]'])}
  />
);
