import { Avatar } from '@components/avatar';
import { ForemanProps } from './types';
import { cn } from '@utils/cn';
import { useTranslation } from 'react-i18next';

export const Foreman = ({ type, name, specialties }: ForemanProps) => {
  const { t } = useTranslation('app');

  return (
    <div className="flex h-full w-full items-center gap-2">
      <div className="h-6 w-6">
        <Avatar
          name={name || '?'}
          hideTooltip
          className={cn([
            'h-6 w-6 select-none text-xs',
            name ? 'bg-primary-300' : 'bg-neutral-200',
          ])}
        />
      </div>
      <div className="flex flex-col">
        <span
          className={cn(['text-sm font-semibold', name ? 'text-neutral-600' : 'text-neutral-400'])}>
          {name || t('pages.foreman.scheduledWithoutTeam')}
        </span>
        {(type || specialties) && (
          <span className="text-[10px] leading-3 text-neutral-400">
            {specialties
              ? specialties.map((el) => t(`specialty.${el}`)).join(', ')
              : t(`specialty.${type}`)}
          </span>
        )}
      </div>
    </div>
  );
};
