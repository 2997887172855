import { Button } from '@components/ui/button';
import { Card, CardContent, CardHeader } from '@components/ui/card';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

export const NotFound = () => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.notFound' });

  return (
    <Card className="mx-auto max-w-[600px] rounded-lg border-0 font-inter shadow-card">
      <CardHeader className="flex-row">
        <h1 className="text-heading-xs font-bold">{t('title')}</h1>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        <div className="leading-6">
          <p>{t('first')}</p>
          <p>{t('second')}</p>
        </div>
        <Link to={parseRoute(appRoutes.index)}>
          <Button className="w-fit">{t('action')}</Button>
        </Link>
      </CardContent>
    </Card>
  );
};
