import { createContext, useContext, useEffect, useState } from 'react';
import { SchedulerContextData, SchedulerData, SchedulerProviderProps } from './types';
import { initialState } from './constants';
import { groupSchedules } from '@components/scheduler/grid/helpers';
import { roundSchedulesDates } from './helpers';

const SchedulerContext = createContext<SchedulerContextData | Record<string, never>>({});

export const SchedulerProvider = ({ children }: SchedulerProviderProps) => {
  const [state, setState] = useState<SchedulerData>(initialState);

  const {
    data: { raw },
    hours,
  } = state;

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      data: {
        ...prev.data,
        grouped: {
          foremen: prev.data.raw.foremen.map((el) => ({
            ...el,
            schedules: groupSchedules(roundSchedulesDates(el.schedules, hours)),
          })),
          withoutTeam: groupSchedules(roundSchedulesDates(prev.data.raw.withoutTeam, hours)),
        },
      },
    }));
  }, [raw, hours]);

  return (
    <SchedulerContext.Provider value={{ ...state, setState }}>{children}</SchedulerContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useSchedulerContext = () => useContext(SchedulerContext);
