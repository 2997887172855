import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { SetError, handleOnSettled, useHandleFormErrors } from '@utils/formHelpers';
import {
  CreateInternalScheduleResp,
  InternalScheduleProps,
  useCreateInternalSchedule,
} from '@services/jobs/schedules/createSchedule';
import { useUpdateInternalSchedule } from '@services/jobs/schedules/updateSchedule';
import { ScheduleNote } from '@services/schedules/types';
import { useSchedulerContext } from '@contexts/scheduler';
import { initialState } from '@contexts/scheduler/constants';

export const useInternalScheduleForm = ({
  successHandler,
  onOverlap,
}: {
  successHandler: () => void;
  onOverlap: () => void;
}) => {
  const {
    setState,
    active: { schedule },
  } = useSchedulerContext();

  const handleFormErrors = useHandleFormErrors();
  const formSchema = z.object({
    forceOverlapping: z.boolean().optional(),
    taskId: z.string().trim(),
    foremanId: z.string().trim().optional(),
    confirmed: z.boolean(),
    startsAt: z.string(),
    endsAt: z.string(),
    scheduleId: z.string().trim().optional(),
    note: z.string().trim().optional(),
  });

  const defaultValues: InternalScheduleProps & { scheduleId?: string } = {
    taskId: schedule?.task?.id || '',
    foremanId: schedule.foreman?.id || '',
    confirmed: false,
    startsAt: schedule.startsAt,
    endsAt: schedule.endsAt,
    scheduleId: schedule.id,
    note: undefined,
    forceOverlapping: false,
  };
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSettled = (resp: CreateInternalScheduleResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };
    let error: Record<string, string[]> | undefined = undefined;
    if (typeof resp?.errors !== 'undefined') {
      error = resp.errors as Record<string, string[]>;
    }
    const err = resp.errors as { base: string[] };
    if (err?.base?.includes('overlapping_schedules')) {
      onOverlap();
    } else {
      setState(initialState);
      handleOnSettled(resp, successHandler, () =>
        handleFormErrors(handleError, Object.keys(defaultValues), error, true),
      );
    }
  };

  const { mutate, isLoading } = useCreateInternalSchedule({
    id: schedule.task?.job?.id || '',
    callback: onSettled,
  });
  const { reschedule, isUpdating } = useUpdateInternalSchedule({
    id: schedule.task?.job?.id || '',
    callback: onSettled,
  });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    const { scheduleId: sId, note: content, ...rest } = values;

    const noteObject = content
      ? ({
          id: schedule?.task?.note?.id,
          content,
          referableId: schedule?.task?.id,
          referableType: 'Task',
        } as ScheduleNote)
      : undefined;

    if (schedule.id && schedule.id !== 'new') {
      reschedule({
        scheduleId: schedule.id,
        schedule: {
          ...rest,
          taskId: schedule.task?.id || '',
          startsAt: schedule.startsAt,
          endsAt: schedule.endsAt,
          foremanId: schedule.foreman?.id || '',
        },
        note: noteObject,
      });
    } else {
      mutate({
        schedule: {
          ...rest,
          taskId: schedule.task?.id || '',
          startsAt: schedule.startsAt,
          endsAt: schedule.endsAt,
          foremanId: schedule.foreman?.id || '',
        },
        note: noteObject,
      });
    }
  };

  return {
    form,
    onSubmit,
    locked: isLoading || isUpdating,
  };
};
