import { parse, format, isBefore, isEqual, isSameHour, isSameMinute } from 'date-fns';

const DEFAULT_FORMAT = 'dd-MM-yyyy';

export const formatDate = (date?: Date, pattern?: string) =>
  format(date || new Date(), pattern || DEFAULT_FORMAT);

export const parseDate = (date: string, pattern?: string) =>
  parse(date, pattern || DEFAULT_FORMAT, new Date());

export const compareDate = (d1: Date, d2: Date) => {
  const isSameDay = d1.getDate() === d2.getDate();
  const isSameMonth = d1.getMonth() === d2.getMonth();
  const isSameYear = d1.getFullYear() === d2.getFullYear();

  return isSameDay && isSameMonth && isSameYear;
};

export const compareHours = (d1: Date, d2: Date) => isSameHour(d1, d2) && isSameMinute(d1, d2);

export const isBeforeOrEqual = (first: Date, second: Date) =>
  isBefore(first, second) || isEqual(first, second);
