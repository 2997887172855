/* eslint-disable immutable/no-mutation */

const getFileName = (headers: Headers) =>
  headers
    .get('content-disposition')
    ?.split('; ')
    ?.find((header) => header.includes('filename='))
    ?.split('"')
    ?.find((string) => string.includes('.'));

export const downloadFile = (blob: Blob, headers: Headers) => {
  const fileName = getFileName(headers);
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  a.target = '_blank';
  if (fileName?.length) a.download = fileName;
  document.body.appendChild(a);
  a.click();
  window.URL.revokeObjectURL(url);
};
