import { useGetFilters } from '@services/users/filters';
import { useEffect } from 'react';
import { useQueryParams } from './useQueryParams';
import { FilterValue } from '@services/users/filters/types';
import { useFilterKey } from './useFilterKey';
import { useAppContext } from '@contexts/app';

export const useFilters = () => {
  const { setData, filters } = useAppContext();
  const filterKey = useFilterKey();
  const { params, setParams } = useQueryParams<FilterValue>();
  const { data } = useGetFilters();
  const newFilters = data?.data;

  useEffect(() => {
    setParams({ ...params, ...filters?.[filterKey] }, { disableSaveFilters: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, filterKey]);

  useEffect(() => {
    if (!newFilters || !setData) return;
    setData((prev) => ({ ...prev, filters: newFilters }));
  }, [newFilters, setData]);
};
