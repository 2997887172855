import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { appRoutes, foremanRoute, queryKeys } from '@utils/consts';
import { NoteReq, NoteResp } from './types';
import { useLocation } from 'react-router-dom';

export const useCreateNote = ({ callback }: { callback: (data: NoteResp) => void }) => {
  const queryClient = useQueryClient();
  const { pathname } = useLocation();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, important, ...data }: NoteReq): Promise<NoteResp> =>
      queryFetch({
        url: 'notes',
        data,
        opt: { method: 'POST' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.job });
      await queryClient.refetchQueries({ queryKey: queryKeys.questionary });
      if (pathname === `/${appRoutes.jobs}`) {
        await queryClient.refetchQueries({ queryKey: queryKeys.jobsList });
      }
      if (!pathname.includes(foremanRoute.questionary)) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.questionary });
      await queryClient.refetchQueries({ queryKey: queryKeys.questionaryAttachment });
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
