/* eslint-disable camelcase */

import * as React from 'react';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { DayPicker } from 'react-day-picker';

import { cn } from '@utils/cn';
import { variants } from '@components/ui/button/buttonVariants';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function Calendar({ className = '', classNames, showOutsideDays = true, ...props }: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3', className)}
      classNames={{
        months: 'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0',
        month: 'space-y-4',
        caption: 'flex justify-center pt-1 relative items-center',
        caption_label: 'text-sm font-medium',
        nav: 'space-x-1 flex items-center',
        nav_button: cn(
          variants.variant.ghost,
          'h-7 w-7 flex items-center justify-center bg-transparent p-0 opacity-50 hover:opacity-100',
        ),
        nav_button_previous: 'absolute left-1',
        nav_button_next: 'absolute right-1',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex',
        head_cell: 'text-neutral-500 rounded-md w-9 font-normal text-[0.8rem]',
        row: 'flex w-full',
        cell: 'text-center text-neutral-700 text-sm p-0 relative [&:has([aria-selected])]:bg-primary-400 first:[&:has([aria-selected])]:rounded-l-md last:[&:has([aria-selected])]:rounded-r-md focus-within:relative focus-within:z-20',
        day: 'h-9 w-9 p-0 font-normal aria-selected:opacity-100 hover:bg-primary-200',
        day_selected: 'bg-primary-400 text-white hover:bg-primary-600 focus:bg-primary-600',
        day_today: 'bg-neutral-100 text-neutral-900',
        day_outside: 'text-neutral-500 opacity-50',
        day_disabled: 'text-neutral-500 opacity-50',
        day_range_middle: 'aria-selected:bg-neutral-100 aria-selected:text-neutral-900',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: () => <ChevronLeft className="pointer-events-none h-4 w-4" />,
        IconRight: () => <ChevronRight className="pointer-events-none h-4 w-4" />,
      }}
      {...props}
    />
  );
}

/* eslint-disable-next-line immutable/no-mutation */
Calendar.displayName = 'Calendar';

export { Calendar };
