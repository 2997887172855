export const badgeVariants = {
  variant: {
    default: 'border-transparent bg-neutral-900 text-neutral-50 hover:bg-neutral-900/80',
    outline: 'text-neutral-950',
    gray: 'bg-neutral-100 text-neutral-600',
    active: 'bg-success-100 text-success-700 border-success-100',
    pending: 'bg-info-100 text-info-700 border-info-100',
    layout: 'bg-layout-100 text-layout-700 border-layout-100',
    footing: 'bg-footing-100 text-footing-700 border-footing-100',
    walls: 'bg-walls-100 text-walls-700 border-walls-100',
    pump: 'bg-pump-100 text-pump-700 border-pump-100',
    stripout: 'bg-stripout-100 text-stripout-700 border-stripout-100',
    crane: 'bg-crane-100 text-crane-700 border-crane-100',
    utilityDriver: 'bg-utility-driver-100 text-utility-driver-700 border-utility-driver-100',
    customerService:
      'bg-customer-service-100 text-customer-service-700 border-customer-service-100',
  },
};
