import { UserDropdownItem } from '@root/globalTypes';
import { queryFetch } from '@services/api';

export interface UsersRespSuccess {
  success: true;
  records: UserDropdownItem[];
}

export const queryDropdownUsers = async (params: string): Promise<UsersRespSuccess> => {
  return queryFetch({ url: `dropdowns/users${params}` });
};
