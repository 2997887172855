import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { DocumentResp } from './create';
import { queryFetch } from '@services/api';
import { useAppContext } from '@contexts/app';
import { DocumentsQueryType, getDocumentsUrl } from '.';

export const useRemoveDocument = ({
  queryType,
  callback,
}: {
  queryType: DocumentsQueryType;
  callback: (data: DocumentResp) => void;
}) => {
  const { isForeman } = useAppContext();
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: (id?: string): Promise<DocumentResp> =>
      queryFetch({ url: `${getDocumentsUrl(queryType)}/${id}`, opt: { method: 'DELETE' } }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      if (isForeman) {
        await queryClient.refetchQueries({ queryKey: queryKeys.files });
      } else {
        await queryClient.refetchQueries({ queryKey: queryKeys.documents });
        await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
      }
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
