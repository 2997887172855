import { SvgConsumer } from '@components/icons/svgConsumer';
import { useTranslation } from 'react-i18next';
import { IssueHeadingProps } from './types';

export const IssueHeading = ({ children }: IssueHeadingProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.job' });

  return (
    <h4 className="flex gap-3.5 pb-3 font-medium">
      <SvgConsumer className="shrink-0 text-m" id="issue" />
      <span className="whitespace-nowrap font-medium">{t('issue')}:</span>
      <span className="flex gap-2 font-bold">{children}</span>
    </h4>
  );
};
