import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SetError, handleOnSettled, useHandleFormErrors } from '@utils/formHelpers';
import { useTranslation } from 'react-i18next';
import { NoteReq, NoteResp } from '@services/notes/types';
import { useCreateNote } from '@services/notes/create';
import { useUpdateNote } from '@services/notes/update';
import { Note } from '@root/globalTypes';
import { useRemoveNote } from '@services/notes/remove';
import { useAppContext } from '@contexts/app';

interface UseNoteFormProps {
  initialValues?: Note;
  successHandler: (action: 'create' | 'update' | 'remove') => void;
  usage?: string;
}

export const useNoteForm = ({ initialValues, successHandler, usage }: UseNoteFormProps) => {
  const handleFormErrors = useHandleFormErrors();
  const { t } = useTranslation('app');
  const { isForeman } = useAppContext();

  const formSchema = z.object({
    content: !initialValues?.id
      ? z
          .string()
          .trim()
          .min(1, {
            message: t('forms.note.required'),
          })
      : z.string().trim(),
    important: z.boolean().optional(),
  });

  const defaultValues: NoteReq = {
    content: '',
    important: false,
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const noteContent = form.watch('content');
  const isEmptyLocked = isForeman && !noteContent.length;

  const onSettled = (resp: NoteResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };
    let error: object = {};
    if (resp?.errors) {
      error = { root: resp.errors };
      if (Object.keys(resp.errors).length) {
        error = { ...resp.errors } as object;
      }
    }

    handleOnSettled<NoteResp>(
      resp,
      () => successHandler(initialValues?.id ? 'update' : 'create'),
      () => handleFormErrors(handleError, Object.keys(defaultValues), error, true),
    );
  };

  const create = useCreateNote({ callback: onSettled });
  const update = useUpdateNote({ callback: onSettled });
  const remove = useRemoveNote({ callback: () => successHandler('remove') });

  const onSubmit = async ({ important, content }: z.infer<typeof formSchema>) => {
    if (!initialValues || isEmptyLocked) return;
    const { id, jobId, typeId } = initialValues;
    if (!id) {
      if (usage) {
        create.mutate({
          referableType: 'Job',
          referableId: jobId,
          content,
          usage,
        });
      } else {
        create.mutate({
          referableType: important ? 'Job' : initialValues.referableType,
          referableId: important ? jobId : typeId,
          content,
        });
      }
    } else if (!content) {
      remove.mutate({ id });
    } else update.mutate({ id, content });
  };

  return {
    form,
    onSubmit,
    locked: create.isLoading || update.isLoading || isEmptyLocked,
  };
};
