import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useShowErrorToast } from '@utils/formHelpers';
import { DefaultApiResp } from '@root/globalTypes';
import { useParams } from 'react-router-dom';
import { queryFetch } from '@services/api';
import { JobInventoryItemReq } from '../types';

export const useUpdateJobInventoryItems = ({
  callback,
}: {
  callback: (data: DefaultApiResp) => void;
}) => {
  const { id: jobId } = useParams();
  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { mutate, isPending } = useMutation({
    mutationFn: (items: JobInventoryItemReq[]): Promise<DefaultApiResp> =>
      queryFetch({
        url: `jobs/${jobId}/inventories/items/bulk_update`,
        data: { items },
        opt: { method: 'POST' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: [...queryKeys.jobInventory, jobId] });
      await queryClient.refetchQueries({ queryKey: [...queryKeys.jobInventoryDropdown, jobId] });
    },
    onSettled: (data) => {
      const { success, errors } = data as DefaultApiResp;
      if (errors) showErrorToast(errors);
      if (success) callback(data as DefaultApiResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
