import { MapData } from './types';

export const API_KEY: string = import.meta.env.VITE_MAP_API_KEY as string;
export const MAP_ID: string = import.meta.env.VITE_MAP_API_ID as string;
export const DEFAULT_ZOOM: number = 13;
export const DEFAULT_FOCUS_ZOOM: number = 18;
export const DEFAULT_CENTER: google.maps.LatLngLiteral = {
  lat: Number(import.meta.env.VITE_MAP_DEFAULT_CENTER_LAT),
  lng: Number(import.meta.env.VITE_MAP_DEFAULT_CENTER_LON),
};

export const initialState: MapData = {
  hoveredMarker: '',
  data: [],
  marker: {
    id: '',
    name: '',
    status: '',
    constructionType: 'residential',
    builder: { name: '' },
    lat: DEFAULT_CENTER.lat.toString(),
    lon: DEFAULT_CENTER.lng.toString(),
    address: '',
    priority: 'low',
    startDate: '',
    followUpDate: '',
    lastScheduledTaskDate: '',
    importantTasks: [
      {
        schedule: {
          startsAt: '',
          endsAt: '',
        },
        stage: {
          id: '',
          name: '',
        },
      },
    ],
  },
};
