import { cn } from '@utils/cn';
import { SchedulerGridCellProps } from './types';

export const SchedulerGridCell = ({ children, className, style }: SchedulerGridCellProps) => (
  <div
    style={style}
    className={cn([
      'relative flex border-l after:absolute after:-bottom-[2px] after:-left-[1px] after:h-[2px] after:w-[1px] after:bg-neutral-100',
      className,
    ])}>
    {children}
  </div>
);
