import { ReactNode } from 'react';
import { getFirstDayOfBusinessWeek, isFirstDayOfBusinessWeek, isLastVisible } from '../helpers';
import { WorkloadWeek } from './week';

export const isFirstVisible = (index: number): boolean => index === 0;

export const renderWeek = (dates: Date[], item: Date, index: number): ReactNode => {
  const firstDisplayedDate = dates[0].getTime();
  const firstDayOfBusinessWeekOfItem = getFirstDayOfBusinessWeek(item).getTime();

  const isFirstDayOfBusinessWeekVisible: boolean = dates
    .map((el) => el.getTime())
    .includes(firstDayOfBusinessWeekOfItem);

  const isWeekVisible: boolean =
    isFirstDayOfBusinessWeek(item) ||
    isFirstVisible(index) ||
    (!isFirstDayOfBusinessWeekVisible &&
      firstDisplayedDate === firstDayOfBusinessWeekOfItem &&
      isLastVisible(dates, index));

  if (isWeekVisible) return <WorkloadWeek date={item} />;
  return null;
};
