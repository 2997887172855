import { differenceInCalendarWeeks, isThisWeek, startOfWeek } from 'date-fns';

export const getPrefix = (date: Date): string => {
  const isNextWeek = differenceInCalendarWeeks(date, startOfWeek(new Date())) === 1;
  const isPreviousWeek = differenceInCalendarWeeks(date, startOfWeek(new Date())) === -1;
  if (isThisWeek(date)) return 'pages.workload.thisWeek';
  if (isNextWeek) return 'pages.workload.nextWeek';
  if (isPreviousWeek) return 'pages.workload.previousWeek';
  return '';
};
