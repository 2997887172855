import { useSchedulerContext } from '@contexts/scheduler';
import { SchedulerGridRow } from '../row';

export const SchedulerGridForemen = () => {
  const {
    data: {
      grouped: { foremen },
    },
  } = useSchedulerContext();

  return foremen.map((el, index) => {
    if (!el.schedules.length) {
      return (
        <SchedulerGridRow
          key={`scheduler-grid-foreman-${el.id}`}
          data={[]}
          foreman={{ id: el.id || '', name: el.fullName || '' }}
          first
          last
          lastRow={index === foremen.length - 1}
        />
      );
    }
    return el.schedules.map((group, i) => (
      <SchedulerGridRow
        key={`scheduler-grid-foreman-${el.id}-group-${i}`}
        data={group}
        foreman={{ id: el.id || '', name: el.fullName || '' }}
        first={Boolean(!i)}
        last={i === el.schedules.length - 1}
        lastRow={index === foremen.length - 1}
      />
    ));
  });
};
