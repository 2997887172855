import {
  customJsonParser,
  transformCamelCaseToSnakeCase,
  transformSnakeToCamelCase,
} from '@utils/textHelpers';
import queryString, { StringifyOptions } from 'query-string';
import { useSearchParams } from 'react-router-dom';
import { useSaveFilters } from './useSaveFilters';

interface SetParamsOptions {
  disableSaveFilters?: boolean;
}

export const useQueryParams = <T>() => {
  const options: StringifyOptions = {
    arrayFormat: 'bracket',
    skipEmptyString: true,
    skipNull: true,
  };
  const [state, setState] = useSearchParams();
  const saveFilters = useSaveFilters();

  const params = customJsonParser(
    queryString.parse(state.toString(), options),
    transformSnakeToCamelCase,
  ) as T;

  const parseParams = (paramsToParse: Record<string, unknown>) => {
    const parsed = queryString.stringify(
      customJsonParser(paramsToParse, transformCamelCaseToSnakeCase),
      options,
    );
    if (parsed.length) return `?${parsed}`;
    return parsed;
  };

  const setParams = (newParams: Record<string, unknown>, opt?: SetParamsOptions) => {
    const mergedParams = { ...params, ...newParams };
    setState(new URLSearchParams(parseParams(mergedParams)));
    if (opt?.disableSaveFilters) return;
    saveFilters(mergedParams);
  };

  const setParam = (key: string, value: unknown) => {
    setParams({ ...params, [key]: value });
  };

  return {
    params,
    setParam,
    setParams,
    parseParams,
    searchParams: state,
  };
};
