import { cn } from '@utils/cn';
import * as React from 'react';

export interface TextareaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  error?: string;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  ({ className, error, ...props }, ref) => {
    return (
      <textarea
        className={cn(
          'group-[.error]:shadow-danger-b flex min-h-[106px] w-full items-center gap-2 rounded border border-neutral-300 bg-white px-3 py-1 text-m leading-6 text-neutral-800 focus-within:border-primary-600 focus-within:outline focus-within:outline-2 focus-within:outline-primary-200',
          error ? 'border-danger-500 focus-within:border-danger-500' : '',
          props.disabled ? 'border-neutral-200 bg-neutral-50 text-neutral-400' : '',
          props.readOnly ? 'border-neutral-300 text-neutral-800' : '',
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
// eslint-disable-next-line immutable/no-mutation
Textarea.displayName = 'Textarea';

export { Textarea };
