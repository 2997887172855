import { useToast } from '@hooks/useToast';
import { queryFetch } from '@services/api';
import { useMutation } from '@tanstack/react-query';
import { downloadFile } from '@utils/downloadFile';
import { useTranslation } from 'react-i18next';

export const useDownloadDocument = (foreman?: boolean) => {
  const { t } = useTranslation('app', { keyPrefix: 'errors' });
  const { toast } = useToast();

  const { mutate, isPending } = useMutation({
    mutationFn: (id?: string) =>
      queryFetch<Response>({
        url: `${foreman ? 'foreman/' : ''}documents/${id}/download_file`,
        blob: true,
        opt: { method: 'GET' },
      }),
    onSettled: async (res?: Response) => {
      if (res?.ok && res?.status === 200) downloadFile(await res.blob(), res.headers);
      else {
        toast({
          title: t('download'),
          variant: 'destructive',
        });
      }
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
