import { Checkbox } from '@components/ui/checkbox';
import { cn } from '@utils/cn';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { setBackground } from '../helpers';
import { JobSubtask as JobSubtaskType, JobTaskStatus } from '@root/globalTypes';
import { useTranslation } from 'react-i18next';
import { useCompleteSubtask } from '@services/subtasks/complete';
import { useToast } from '@hooks/useToast';
import { UploadFileDialog } from '@pages/jobs/:id/documents/components/dialog';
import { useState } from 'react';
import { useRevertSubtask } from '@services/subtasks/revert';
import { useAppContext } from '@contexts/app';
import { RemoveConfirmationAlert } from '@components/dialogs/removeConfirmationAlert';
import { useDownloadDocument } from '@services/documents/download';
import { useRemoveDocument } from '@services/documents/remove';
import { useJobContext } from '@contexts/job';
import { JobSubtaskConcreteUpdate } from './form';
import { NoteBox } from '../noteBox';

interface JobSubtaskProps {
  data: JobSubtaskType;
  status?: JobTaskStatus;
}

export const JobSubtask = ({ data, status }: JobSubtaskProps) => {
  const { toast } = useToast();
  const { t } = useTranslation('app');
  const { archived, allowed } = useJobContext();

  const [isFileDialog, setIsFileDialog] = useState(false);
  const [isRevertConfirm, setIsRevertConfirm] = useState(false);
  const isFileNeeded = data.taskConfirmation === 'file';
  const isExtraAttributes = data.taskConfirmation === 'extra_attributes_update';

  const { isForeman, isCadTechnician } = useAppContext();
  const isCadTask = data?.name?.includes('CAD');

  const downloadDocument = useDownloadDocument();

  const removeDocument = useRemoveDocument({
    queryType: 'subtask',
    callback: () => {
      toast({
        title: t('messages.success.file.remove'),
      });
    },
  });

  const complete = useCompleteSubtask({
    callback: () => {
      toast({
        title: t('messages.success.subtask.complete'),
      });
    },
  });

  const revert = useRevertSubtask({
    callback: () => {
      toast({
        title: t('messages.success.subtask.revert'),
      });
    },
  });

  const handleCheckbox = (newValue: boolean) => {
    if (!data.id) return;
    if (newValue) complete.mutate({ id: data.id });
    else setIsRevertConfirm(true);
  };

  return (
    <>
      <div
        className={cn([
          'col-start-1 col-end-3 flex items-center gap-6 border-t px-9 py-3',
          setBackground(status as JobTaskStatus, true),
        ])}>
        <div>
          <h4 className="text-sm font-medium text-neutral-800">{data.name}</h4>
          {isExtraAttributes && <JobSubtaskConcreteUpdate data={data} status={status} />}
        </div>
        <div className="flex flex-col gap-1">
          {data.documents?.map((file) => (
            <div key={file.id} className="group flex items-center gap-3">
              <a
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
                className="flex cursor-pointer items-center gap-2 text-sm font-semibold text-primary-500 hover:underline">
                <SvgConsumer className="text-m" id="uploadDocument" />
                <span>{file.filename}</span>
              </a>
              <div className="flex gap-1 text-m">
                <button
                  type="button"
                  className="h-fit p-1 text-neutral-300 opacity-0 hover:text-blue-400 group-hover:opacity-100"
                  onClick={(e) => {
                    e.stopPropagation();
                    downloadDocument.mutate(file.id);
                  }}>
                  <SvgConsumer id="downloadDocument" />
                </button>
                {(!isForeman || allowed) && (
                  <RemoveConfirmationAlert
                    title={t('confirmDialog.title')}
                    description={t('deleteDialog.description', {
                      item: file.filename,
                    })}
                    onConfirm={() => removeDocument.mutate(file.id)}
                    locked={removeDocument.isLoading}>
                    <button
                      disabled={archived}
                      type="button"
                      className="h-fit p-1 text-neutral-300 opacity-0 hover:text-danger-500 group-hover:opacity-100">
                      <SvgConsumer id="trash" />
                    </button>
                  </RemoveConfirmationAlert>
                )}
              </div>
            </div>
          ))}
          {isFileNeeded && (
            <button
              disabled={archived || isForeman}
              onClick={() => setIsFileDialog(true)}
              className="flex h-fit w-fit items-center gap-2 rounded-md border border-neutral-200 bg-white px-4 py-1.5 text-sm font-semibold text-neutral-700 disabled:opacity-50">
              <SvgConsumer id="uploadDocument" />
              {t('fileUpload.selectFile')}
            </button>
          )}
        </div>
      </div>
      <div
        className={cn([
          'col-start-3 col-end-4 flex justify-end border-t',
          setBackground(status as JobTaskStatus, true),
        ])}>
        <div className="w-fit border-x border-neutral-100 px-4.5 py-4">
          <Checkbox
            disabled={!allowed || archived}
            checked={data.completed}
            onCheckedChange={handleCheckbox}
          />
        </div>
      </div>
      <div
        className={cn([
          'col-start-4 col-end-5 border-t',
          setBackground(status as JobTaskStatus, true),
        ])}>
        <NoteBox
          referableType="Subtask"
          data={data.note}
          jobId={data.jobId}
          typeId={data.id}
          disabled={archived || (!isCadTask && isCadTechnician)}
        />
      </div>
      <UploadFileDialog
        title={data.name}
        jobId={data.jobId as string}
        open={isFileDialog}
        setOpen={setIsFileDialog}
        initialSubtaskId={data.id}
        dragAndDrop
        queryType="subtask"
      />
      <RemoveConfirmationAlert
        open={isRevertConfirm}
        setOpen={setIsRevertConfirm}
        title={t('confirmDialog.title')}
        description={t('pages.job.revertSubtask', {
          name: data.name,
        })}
        onConfirm={() => revert.mutate(data?.id || '')}
      />
    </>
  );
};
