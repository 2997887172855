import { SchedulerGridCellSegmentProps } from './types';
import { SchedulerGridSchedule } from '../../schedule';
import { useSchedulerDragAndDrop } from '@hooks/scheduler/dnd';
import { useScheduler } from '@hooks/scheduler';
import { cn } from '@utils/cn';

export const SchedulerGridCellSegment = ({
  date,
  data,
  foremanId,
  start,
  end,
  last,
  style,
}: SchedulerGridCellSegmentProps) => {
  const { handleDrop, handleDragOver } = useSchedulerDragAndDrop();
  const { handleMouseDown, isSegmentDisabled } = useScheduler();
  const disabled = isSegmentDisabled(date);

  const after = 'after:absolute after:w-[2px] after:h-full after:-left-[1px]';
  const before = cn(
    'before:absolute before:w-full before:h-[2px] before:-bottom-[1px]',
    disabled && !last && 'before:bg-neutral-50',
  );

  return (
    <div
      onMouseDown={(e) => handleMouseDown(e, foremanId)}
      onDrop={() => handleDrop(foremanId)}
      onDragOver={(e) => handleDragOver(e, date, foremanId)}
      data-start={start ? data?.id : undefined}
      data-date={date.toISOString()}
      data-end={end ? data?.id : undefined}
      className={cn([
        'scheduler-segment relative h-full select-none',
        after,
        before,
        disabled && 'bg-neutral-50',
      ])}
      style={style}>
      {start && data && <SchedulerGridSchedule data={data} />}
    </div>
  );
};
