import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { useShowErrorToast } from '@utils/formHelpers';
import { queryFetch } from '@services/api';
import { CopyTaskReq, TaskResp } from './types';

export const useCopyTask = ({ callback }: { callback: (data: TaskResp) => void }) => {
  const queryClient = useQueryClient();
  const { showErrorToast } = useShowErrorToast();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ jobId, ...data }: CopyTaskReq): Promise<TaskResp> =>
      queryFetch({
        url: `jobs/${jobId}/tasks/copy`,
        data: { task: data },
        opt: { method: 'POST' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
    },
    onSettled: (data) => {
      const { success, errors } = data as TaskResp;
      if (errors) showErrorToast(errors as string);
      if (success) callback(data as TaskResp);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
