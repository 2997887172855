import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { useInitials } from '@hooks/useInitials';
import React from 'react';

type AvatarProps = React.HTMLAttributes<HTMLSpanElement> & {
  name?: string;
  lastName?: string;
  hideTooltip?: boolean;
};

export const Avatar = ({
  name = '',
  lastName,
  className = '',
  hideTooltip = false,
  ...props
}: AvatarProps) => {
  const initials = useInitials(name, lastName);

  if (!hideTooltip && initials) {
    return (
      <AppTooltip
        context={
          <p>
            {name} {lastName}
          </p>
        }>
        <span
          className={`flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-cyan-600 font-inter text-white ${
            className || 'h-7 w-7'
          }`}
          {...props}>
          {initials}
        </span>
      </AppTooltip>
    );
  }

  return (
    <span
      className={`flex shrink-0 items-center justify-center overflow-hidden rounded-full bg-cyan-600 font-inter text-white ${
        className || 'h-7 w-7'
      }`}
      {...props}>
      {initials}
    </span>
  );
};
