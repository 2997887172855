import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';

import { variants } from './buttonVariants';
import { cn } from '@utils/cn';
import { useAppContext } from '@contexts/app';

const buttonVariants = cva(
  'inline-flex gap-2 focus-visible:shadow-primary-btn items-center justify-center rounded-md px-4 transition-colors focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50 leading-none',
  {
    variants: variants,
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  },
);

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, asChild = false, type = 'button', ...props }, ref) => {
    const Comp = asChild ? Slot : 'button';
    const { children, disabled, ...rest } = props;
    const ctx = useAppContext();

    return (
      <Comp
        type={type}
        className={cn(buttonVariants({ size, variant, className }))}
        ref={ref}
        disabled={typeof disabled === 'boolean' ? disabled : ctx?.apiActivity}
        {...rest}>
        {children}
      </Comp>
    );
  },
);
// eslint-disable-next-line immutable/no-mutation
Button.displayName = 'Button';

export { Button };
