export const typography = {
  'heading-l': ['36px', '40px'],
  'heading-m': ['32px', '40px'],
  'heading-s': ['28px', '38px'],
  'heading-xs': ['24px', '32px'],
  xl: ['20px', '28px'],
  l: ['18px', '28px'],
  m: ['16px', '24px'],
  s: ['14px', '20px'],
  xs: ['12px', '16px'],
};
