import { Avatar } from '@components/avatar';
import { PushNotifications } from '@components/permisions/pushNotifications';
import { PushTest } from '@components/permisions/test';
import { UpdateSW } from '@components/serviceWorker/update';
import { Button } from '@components/ui/button';
import { NavMenu } from '@components/ui/navigationMenu/navigationMenu';
import { useAppContext } from '@contexts/app';
import { cn } from '@utils/cn';
import { appRoutes, userAccessMap } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { localStore } from '@utils/localStore';
import { ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { NotificationsDialog } from './notificationsDialog';
import { useGetNotifications } from '@services/notifications';
import { transformSnakeToCamelCase } from '@utils/textHelpers';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { getEnvType } from '@utils/getEnvType';
import { Badge } from '@components/ui/badge';
import { version } from '../../../package.json';
import {
  HotSeat,
  Workload,
  OnDeck,
  Map,
  Jobs,
  Users,
  Builders,
  Companies,
  Inventory,
} from './icons';
import { initialState } from '@contexts/app/constants';
import { useWebsocketContext } from '@contexts/websocket';
import { UserRole } from '@root/globalTypes';

export const Navbar = () => {
  const {
    isLogged,
    isManager,
    isForeman,
    setData,
    role,
    firstName,
    lastName,
    onDeckError,
    hotSeatError,
  } = useAppContext();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { t } = useTranslation('app');
  const { ws } = useWebsocketContext();
  const envType = getEnvType();
  const [notificationsDialog, setNotificationsDialog] = useState(false);
  const { data: notifications } = useGetNotifications(Boolean(isLogged));
  const haveNotifications = Boolean(notifications?.records?.length);

  const handleLogout = () => {
    if (typeof setData === 'function') setData(initialState);
    ws?.disconnect();
    localStore.removeItem('app-user');
    navigate(parseRoute(appRoutes.index));
  };

  const navLinkClasses = (isActive: boolean, error: boolean = false) =>
    cn([
      'hover:text-primary-500 relative block',
      isActive
        ? 'text-primary-500 after:h-[3px] after:bg-primary-500 after:absolute after:left-0 after:right-0 after:bottom-0'
        : 'text-neutral-400',
      error &&
        'before:block before:absolute before:bottom-1 before:right-1 before:h-3 before:w-3 before:rounded-full before:bg-danger-400',
    ]);

  const getHomeLink = () => {
    if (role === 'foreman') return `/${parseRoute(appRoutes.foremanSchedules)}`;
    return parseRoute(appRoutes.index);
  };

  const iconsMap: Record<string, ReactNode> = {
    'hot-seat': <HotSeat />,
    workload: <Workload />,
    'on-deck': <OnDeck />,
    map: <Map />,
    jobs: <Jobs />,
    users: <Users />,
    builders: <Builders />,
    companies: <Companies />,
    inventory: <Inventory />,
  };

  const routeError = (path: keyof typeof iconsMap) => {
    if (path === 'hot-seat' && hotSeatError) return true;
    if (path === 'on-deck' && onDeckError) return true;
    return false;
  };

  const generateLinks = () => {
    if (!role) return null;
    const routes = userAccessMap[transformSnakeToCamelCase(role as UserRole)];
    if (!routes) return null;
    return (
      <ul className="absolute left-1/2 flex grow -translate-x-1/2 justify-center gap-4">
        {routes.map((route) => (
          <li key={route}>
            <NavLink
              onClick={(e) => {
                if (pathname !== `/${parseRoute(route)}`) return;
                e.preventDefault();
              }}
              to={`/${parseRoute(route)}`}
              className={({ isActive }) => navLinkClasses(isActive, routeError(route))}>
              {iconsMap[route]}
            </NavLink>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="header sticky left-0 right-0 top-0 z-[60] bg-white shadow-sm">
      <nav className="container relative flex h-12 items-center gap-5 px-4 py-1">
        <Link
          to={getHomeLink()}
          className="absolute left-4 top-1/2 flex -translate-y-1/2 items-center gap-4">
          STREAMLINE
          {envType !== 'prod' && (
            <Badge variant={envType === 'stage' ? 'footing' : 'active'}>
              {envType} v{version}
            </Badge>
          )}
        </Link>
        {isLogged ? (
          <>
            {generateLinks()}
            <div
              className={`absolute right-4 top-1/2 flex shrink-0 -translate-y-1/2 items-center justify-end gap-4 ${
                !isManager ? 'ms-auto' : ''
              }`}>
              {isForeman && (
                <Link to={parseRoute(appRoutes.foremanArchive)}>
                  <Button
                    variant="ghost"
                    className={cn([
                      'relative flex h-9 w-9 items-center justify-center p-0 text-neutral-400 hover:text-primary-500',
                      pathname.includes(appRoutes.foremanArchive) && 'text-primary-500',
                    ])}>
                    {Boolean(notifications?.missingOptionalSubtasks) && (
                      <span className="absolute bottom-0.5 right-1 flex h-5 w-5 items-center justify-center rounded-full bg-danger-400 text-white">
                        {notifications?.missingOptionalSubtasks}
                      </span>
                    )}
                    <SvgConsumer className="text-heading-s" id="archive" />
                  </Button>
                </Link>
              )}
              <Button
                disabled={!haveNotifications}
                variant="ghost"
                className={cn([
                  'relative flex h-9 w-9 items-center justify-center p-0 text-neutral-400 hover:text-primary-500',
                  Boolean(notifications?.unreadNotifications) &&
                    'after:absolute after:bottom-1 after:right-6 after:block after:h-2.5 after:w-2.5 after:rounded-full after:bg-danger-400',
                ])}
                onClick={() => setNotificationsDialog(!notificationsDialog)}>
                <SvgConsumer className="text-heading-l" id="notifications" />
              </Button>

              <NavMenu trigger={<Avatar name={[firstName, lastName].join(' ')} hideTooltip />}>
                <Button variant="ghost" onClick={handleLogout} size="sm">
                  {t('navigation.logout')}
                </Button>
                <Link to={parseRoute(appRoutes.passwordReset)}>
                  <Button className="whitespace-nowrap" variant="ghost" size="sm">
                    {t('navigation.changePassword')}
                  </Button>
                </Link>
                <div>{import.meta.env.VITE_PUSH_TEST ? <PushTest /> : null}</div>
                <div>
                  {import.meta.env.VITE_SENTRY_TEST ? (
                    <button
                      type="button"
                      onClick={() => {
                        throw new Error('Sentry Test Error');
                      }}>
                      Break the world
                    </button>
                  ) : null}
                </div>
              </NavMenu>
            </div>
          </>
        ) : (
          <Link to={parseRoute(appRoutes.index)} className="ms-auto">
            <strong className="font-inter text-s text-neutral-400 hover:text-primary-500">
              {t('navigation.login')}
            </strong>
          </Link>
        )}
      </nav>
      <UpdateSW />
      {isLogged && <PushNotifications />}
      <NotificationsDialog
        open={notificationsDialog}
        setOpen={setNotificationsDialog}
        data={notifications}
      />
    </div>
  );
};
