import { FilterKey } from '@services/users/filters/types';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

const KEYS: FilterKey[] = ['hot-seat', 'workload', 'on-deck', 'map', 'jobs', 'inventory'];

export const useFilterKey = () => {
  const { pathname } = useLocation();
  const [key, setKey] = useState<FilterKey>();

  useEffect(() => {
    const newKey = KEYS.find((el) => pathname.endsWith(el));
    setKey(newKey);
  }, [pathname]);

  return key as FilterKey;
};
