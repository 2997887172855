import { JobSubtask, JobTaskStatus } from '@root/globalTypes';
import { JobSubTaskInput } from '../input';
import { getSlumpOption } from '@pages/jobs/:id/materials/helpers';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Button } from '@components/ui/button';
import { useOutsideClick } from '@hooks/useOutsideClick';
import { useConcreteUpdateForm } from '@hooks/forms/useConcreteUpdateForm';
import { useToast } from '@hooks/useToast';
import { FormField } from '@components/ui/form';
import { getConcreteFromSubtask } from '../../helpers';
import { useJobContext } from '@contexts/job';
import { useAppContext } from '@contexts/app';

interface JobSubtaskConcreteUpdateProps {
  data: JobSubtask;
  status?: JobTaskStatus;
}

export const JobSubtaskConcreteUpdate = ({ data, status }: JobSubtaskConcreteUpdateProps) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();
  const formRef = useRef<HTMLFormElement>(null);
  const [isVisible, setIsVisible] = useState<boolean>(false);
  const { archived } = useJobContext();
  const { isCadTechnician } = useAppContext();

  const material = getConcreteFromSubtask(data);

  useOutsideClick(formRef, () => setIsVisible(false));

  const { form, onSubmit } = useConcreteUpdateForm({
    material,
    successHandler: () => {
      setIsVisible(false);
      form.reset();
      toast({
        title: t('messages.success.concreteOrder.update'),
      });
    },
  });

  const { handleSubmit, setValue } = form;

  useEffect(() => {
    if (!material) return;
    const { finalQuantity, options } = material;
    const finalSlump = getSlumpOption(options)?.finalQuantity;
    setValue('finalQuantity', finalQuantity || '');
    setValue('finalSlump', finalSlump || '');
    setValue('finalQuantityRepeat', finalQuantity || '');
    setValue('finalSlumpRepeat', finalSlump || '');
  }, [material, setValue]);

  return (
    <form
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onSubmit={handleSubmit(onSubmit)}
      onFocus={() => setIsVisible(true)}
      ref={formRef}
      className="grid grid-cols-[repeat(4,minmax(min-content,max-content))] items-center gap-x-4 gap-y-1 px-4 py-2.5">
      <JobSubTaskInput
        disabled
        value={material?.estimatedQuantity || '0'}
        label={t('pages.job.info.inputs.estimatedQuantity')}
        suffix={material?.unit || ''}
      />
      <FormField
        control={form.control}
        name="finalQuantity"
        render={({ field }) => (
          <JobSubTaskInput
            {...field}
            id={`final-quantity-${material?.id}`}
            disabled={status === 'completed' || data.completed || archived || isCadTechnician}
            onChange={(e) => {
              setValue('finalQuantityRepeat', e.currentTarget.value);
              field.onChange(e.currentTarget.value);
            }}
            placeholder={material?.estimatedQuantity || '0'}
            label={t('pages.job.info.inputs.finalQuantity')}
            suffix={material?.unit || ''}
          />
        )}
      />
      <JobSubTaskInput
        type="number"
        disabled
        value={getSlumpOption(material?.options)?.estimatedQuantity || '0'}
        label={t('pages.job.info.inputs.estimatedSlump')}
        suffix={getSlumpOption(material?.options)?.unit || ''}
      />
      <FormField
        control={form.control}
        name="finalSlump"
        render={({ field }) => (
          <JobSubTaskInput
            {...field}
            id={`final-slump-${material?.id}`}
            disabled={status === 'completed' || data.completed || archived || isCadTechnician}
            onChange={(e) => {
              setValue('finalSlumpRepeat', e.currentTarget.value);
              field.onChange(e.currentTarget.value);
            }}
            placeholder={getSlumpOption(material?.options)?.estimatedQuantity || '0'}
            label={t('pages.job.info.inputs.finalSlump')}
            suffix={getSlumpOption(material?.options)?.unit || ''}
          />
        )}
      />
      <JobSubTaskInput
        type="number"
        disabled
        value={material?.psi}
        label={t('pages.job.info.inputs.psi')}
      />
      {isVisible && (
        <Button
          disabled={status === 'completed' || data.completed || archived || isCadTechnician}
          type="submit"
          className="col-start-4">
          {t('actions.save')}
        </Button>
      )}
    </form>
  );
};
