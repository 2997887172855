import { Button } from '@components/ui/button';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const UpdateSW = () => {
  const { t } = useTranslation('app');
  const [hasUpdate, setHasUpdate] = useState(false);
  const reg = useRef<ServiceWorkerRegistration | null>(null);
  useEffect(() => {
    const checkReg = async () => {
      const registration = await navigator.serviceWorker.getRegistration();
      if (registration) {
        // eslint-disable-next-line immutable/no-mutation
        reg.current = registration;
        registration.addEventListener('updatefound', () => {
          setHasUpdate(true);
        });
      }
    };

    checkReg().catch((err) => {
      console.error(err);
    });
  }, []);

  const handleRegUpdate = () => {
    window.location.reload();
  };

  const havePermission = Boolean(
    'Notification' in window &&
      Notification.permission === 'granted' &&
      import.meta.env.VITE_PUSH_KEY,
  );

  return hasUpdate && havePermission ? (
    <div className="bg-warning ">
      <div className="container mx-auto flex items-center justify-between px-4 py-1 text-white">
        <span className="font-inter text-m font-medium">{t('navigation.push.update')}</span>
        <Button size="sm" variant="secondary" onClick={handleRegUpdate}>
          {t('navigation.push.reload')}
        </Button>
      </div>
    </div>
  ) : null;
};
