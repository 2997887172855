import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { InventoryRoutesRespSuccess } from '../types';
import { useQueryParams } from '@hooks/useQueryParams';
import { FilterValue } from '@services/users/filters/types';

export const useGetInventoryRoutes = () => {
  const {
    params: { routeId },
  } = useQueryParams<FilterValue>();

  const { data, error, isFetching } = useQuery<InventoryRoutesRespSuccess, ApiError>({
    queryKey: [...queryKeys.inventoryRoutes, routeId],
    queryFn: () =>
      queryFetch({
        url: `routes_planner/${routeId}`,
      }),
    placeholderData: (previousData) => previousData as InventoryRoutesRespSuccess,
    enabled: Boolean(routeId && uuidRegEx.test(routeId)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
