import { DefaultApiResp } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export const useRemoveInternalSchedule = ({
  id,
  jobId,
  callback,
}: {
  id: string;
  jobId: string;
  callback?: (data: DefaultApiResp) => void;
}) => {
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<DefaultApiResp> =>
      queryFetch({
        url: `jobs/${jobId}/internal_schedules/${id}`,
        opt: { method: 'DELETE' },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      await queryClient.refetchQueries({ queryKey: [...queryKeys.job, jobId] });
      await queryClient.refetchQueries({ queryKey: queryKeys.jobStages });
      await queryClient.refetchQueries({ queryKey: queryKeys.onDeck });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendar });
      await queryClient.refetchQueries({ queryKey: queryKeys.workloadCalendarSidebar });
      await queryClient.refetchQueries({ queryKey: queryKeys.notifications });
    },
    onSettled: (data) => {
      if (callback && data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
