import { useSchedulerContext } from '@contexts/scheduler';
import { getFirstHour, getLastHour } from '@contexts/scheduler/helpers';
import { Schedule } from '@services/jobs/schedules/teams';
import { addMinutes, differenceInMinutes, isAfter, isBefore, parseISO, subMinutes } from 'date-fns';
import { getSegmentFromPoint } from './dnd/helpers';
import { MouseEvent } from 'react';
import { DEFAULT_NEW_SCHEDULE_LENGTH } from '@contexts/scheduler/constants';
import { compareHours } from '@utils/dateHelpers';

export const useScheduler = () => {
  const {
    data,
    date,
    hours,
    modal,
    inventory,
    active: { schedule, newValues },
    setState,
  } = useSchedulerContext();
  const firstHour = getFirstHour(date);
  const lastHour = getLastHour(hours);

  const getSchedule = (id?: string, mutate: boolean = true) => {
    const scheduleId = id || schedule?.id;
    let selectedSchedule: Schedule | undefined;

    const foremen = data.raw.foremen.map((foreman) => ({
      ...foreman,
      schedules: foreman.schedules
        .map((el) => {
          if (el.id !== scheduleId) return el;
          selectedSchedule = el;
        })
        .filter(Boolean) as Schedule[],
    }));

    const withoutTeam = (data.raw.withoutTeam
      ?.map((el) => {
        if (el.id !== scheduleId) return el;
        selectedSchedule = el;
      })
      .filter(Boolean) || []) as Schedule[];

    if (mutate) {
      setState((prev) => ({
        ...prev,
        data: { ...prev.data, raw: { foremen, withoutTeam } },
      }));
    }

    return selectedSchedule;
  };

  const setSchedule = (newSchedule: Schedule, foremanId: string) => {
    if (!foremanId) {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          raw: {
            ...prev.data.raw,
            withoutTeam: [...(prev.data.raw.withoutTeam || []), newSchedule],
          },
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        data: {
          ...prev.data,
          raw: {
            ...prev.data.raw,
            foremen: prev.data.raw.foremen.map((el) => {
              if (el.id !== foremanId) return el;
              return {
                ...el,
                schedules: [...el.schedules, newSchedule],
              };
            }),
          },
        },
      }));
    }
  };

  const isSegmentDisabled = (value: Date) => {
    if (!inventory) return false;
    const [startsAt, endsAt] = [parseISO(schedule.startsAt), parseISO(schedule.endsAt)];
    return isBefore(value, startsAt) || isAfter(value, endsAt) || compareHours(value, endsAt);
  };

  const updateDates = () => {
    const [startsAt, endsAt] = [parseISO(schedule.startsAt), parseISO(schedule.endsAt)];
    const { from, to } = newValues;
    if (!from || !to || !startsAt || !endsAt) return {};
    const duration = differenceInMinutes(endsAt, startsAt, { roundingMethod: 'round' });
    const fromToStart = differenceInMinutes(from, startsAt, { roundingMethod: 'round' });
    const endToFrom = differenceInMinutes(endsAt, from, { roundingMethod: 'round' });
    const newStartsAt = subMinutes(to, fromToStart);
    const newEndsAt = addMinutes(to, endToFrom);

    if (isBefore(newStartsAt, firstHour)) {
      return { startsAt: firstHour, endsAt: addMinutes(firstHour, duration) };
    }

    if (isAfter(newEndsAt, lastHour)) {
      return { startsAt: subMinutes(lastHour, duration), endsAt: lastHour };
    }

    return { startsAt: newStartsAt, endsAt: newEndsAt };
  };

  const handleMouseDown = (e: MouseEvent<HTMLDivElement>, id: string) => {
    if (!modal || schedule.id) return;
    const startsAt = getSegmentFromPoint(e.clientX, e.clientY);
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        schedule: {
          ...prev.active.schedule,
          id: 'new',
          foreman: { id },
          startsAt: startsAt.toISOString(),
          endsAt: addMinutes(startsAt, DEFAULT_NEW_SCHEDULE_LENGTH).toISOString(),
        },
        newValues: {
          startsAt,
          endsAt: addMinutes(startsAt, DEFAULT_NEW_SCHEDULE_LENGTH),
        },
        fresh: false,
        mode: 'right',
        initialX: e.clientX,
        cursor: 'cursor-ew-resize',
      },
    }));
  };

  return { getSchedule, setSchedule, updateDates, handleMouseDown, isSegmentDisabled };
};
