import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DocumentResp } from './create';
import { queryFetch } from '@services/api';
import { DocumentsQueryType, getDocumentsQueries, getDocumentsUrl } from '.';

export const useUpdateDocument = ({
  callback,
  queryType,
}: {
  callback: (data: DocumentResp) => void;
  queryType: DocumentsQueryType;
}) => {
  const queryClient = useQueryClient();
  const { mutate, isPending } = useMutation({
    mutationFn: ({ id, body }: { id?: string; body: FormData }): Promise<DocumentResp> =>
      queryFetch({
        url: `${getDocumentsUrl(queryType)}/${id}`,
        formData: true,
        opt: { method: 'PUT', body },
      }),
    onError: (err) => {
      console.error(err);
    },
    onSuccess: async ({ success }) => {
      if (!success) return;
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      getDocumentsQueries(queryType).forEach((queryKey) =>
        queryClient.refetchQueries({ queryKey }),
      );
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
