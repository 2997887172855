import { appRoutes } from '@utils/consts';

export const signUpRoutes = [
  {
    path: appRoutes.passwordRecovery,
    lazy: async () => {
      const { PasswordRecovery } = await import('@pages/signIn/passwordRecovery');
      return { Component: () => <PasswordRecovery /> };
    },
  },
  {
    path: appRoutes.passwordRecoveryInfo,
    lazy: async () => {
      const { PasswordRecoveryInfo } = await import('@pages/signIn/passwordRecoveryInfo');
      return { Component: () => <PasswordRecoveryInfo /> };
    },
  },
  {
    path: appRoutes.passwordReset,
    lazy: async () => {
      const { PasswordReset } = await import('@pages/signIn/passwordReset');
      return { Component: () => <PasswordReset /> };
    },
  },
  {
    path: appRoutes.passwordResetConfirmation,
    lazy: async () => {
      const { PasswordResetConfirmation } = await import('@pages/signIn/passwordResetConfirmation');
      return { Component: () => <PasswordResetConfirmation /> };
    },
  },
];
