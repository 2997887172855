import { ProtectedRoute } from '@components/protectedRoute';
import { CompaniesProvider } from '@contexts/companies';
import { appRoutes } from '@utils/consts';

export const companiesRoutes = [
  {
    path: appRoutes.companies,
    lazy: async () => {
      const { CompaniesRoot } = await import('@components/companies');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={['administrator', 'project_coordinator']}>
            <CompaniesProvider>
              <CompaniesRoot />
            </CompaniesProvider>
          </ProtectedRoute>
        ),
      };
    },
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { Companies } = await import('@pages/companies');
          return {
            Component: () => <Companies />,
          };
        },
      },
      {
        path: appRoutes.id,
        lazy: async () => {
          const { Company } = await import('@pages/companies/:id');
          return {
            Component: () => <Company />,
          };
        },
      },
    ],
  },
];
