import { useQuery } from '@tanstack/react-query';
import { queryFetch } from '@services/api';
import { queryKeys } from '@utils/consts';
import { InventoryDropdownRespSuccess } from '../types';
import { ApiError } from '@root/globalTypes';

export const useInventoryRoutesDropdown = () => {
  const { data, error, isFetching } = useQuery<InventoryDropdownRespSuccess, ApiError>({
    queryKey: queryKeys.inventoryDropdown,
    queryFn: () => queryFetch({ url: 'dropdowns/routes' }),
    placeholderData: (previousData) => previousData as InventoryDropdownRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
