import { useQuery } from '@tanstack/react-query';
import { queryKeys, uuidRegEx } from '@utils/consts';
import { queryFetch } from '@services/api';
import { JobInventoryRespSuccess } from './types';
import { ApiError } from '@root/globalTypes';
import { useParams } from 'react-router-dom';

export const useGetJobInventory = () => {
  const { id } = useParams();

  const { data, error, isFetching } = useQuery<JobInventoryRespSuccess, ApiError>({
    queryKey: [...queryKeys.jobInventory, id],
    queryFn: () => queryFetch({ url: `jobs/${id}/inventories` }),
    placeholderData: (previousData) => previousData as JobInventoryRespSuccess,
    enabled: Boolean(id && uuidRegEx.test(id)),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
