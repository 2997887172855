import { appRoutes, foremanRoute } from '@utils/consts';
import { Outlet } from 'react-router-dom';

export const foremanRoutesRoutes = [
  {
    path: foremanRoute.route(foremanRoute.routeId),
    lazy: async () => {
      const { ForemanRouteProvider } = await import('@contexts/foreman/route');
      return {
        Component: () => (
          <ForemanRouteProvider>
            <Outlet />
          </ForemanRouteProvider>
        ),
      };
    },
    children: [
      {
        path: appRoutes.base,
        lazy: async () => {
          const { ForemanRoute } = await import('@pages/foreman/routes/:id');
          return {
            Component: () => <ForemanRoute />,
          };
        },
      },
      {
        path: appRoutes.form(foremanRoute.pointId),
        lazy: async () => {
          const { ForemanRouteForm } = await import('@pages/foreman/routes/:id/form');
          return {
            Component: () => <ForemanRouteForm />,
          };
        },
      },
    ],
  },
];
