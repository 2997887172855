const NAV_ICON_SIZE = 45;

export const HotSeat = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      d="M12.2 24.8c0 1.3.3 2.6.8 3.8a9.4 9.4 0 0 0 2.4 3.1V31c0-.8.2-1.5.5-2.3.3-.7.8-1.3 1.4-2l4.5-4.2 4.5 4.3c.6.6 1.1 1.2 1.4 2 .3.7.5 1.4.5 2.2v.7c1-.9 1.8-2 2.4-3.1.5-1.2.8-2.5.8-3.8a9 9 0 0 0-2.9-6.7 6.5 6.5 0 0 1-3.5 1 6.3 6.3 0 0 1-6.4-5.5 23 23 0 0 0-4.7 5.5 10.4 10.4 0 0 0-1.7 5.8Zm9.6 2L19.5 29a3 3 0 0 0-.7 1l-.2 1c0 .9.3 1.6 1 2.2.6.6 1.3.8 2.2.8.9 0 1.6-.2 2.3-.8a2.8 2.8 0 0 0 .7-3.3l-.7-1-2.3-2Zm0-19V13c0 .8.3 1.6 1 2.1.6.6 1.3 1 2.2 1a3.1 3.1 0 0 0 2.5-1.2l.7-.9a12.1 12.1 0 0 1 6.4 10.8c0 3.4-1.2 6.4-3.7 8.7a12.6 12.6 0 0 1-9.1 3.6c-3.6 0-6.6-1.2-9-3.6A11.7 11.7 0 0 1 9 24.8c0-3.3 1.2-6.4 3.5-9.4s5.4-5.5 9.3-7.5Z"
      fill="currentColor"
    />
  </svg>
);

export const Workload = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      d="M11.8 37.1c-.8 0-1.4-.2-2-.8-.5-.5-.8-1.2-.8-2V12.8c0-.8.3-1.4.8-2 .6-.5 1.2-.8 2-.8h2.1V7.9h2.6v2H28V8h2.6v2h2.1c.8 0 1.4.4 2 1 .5.5.8 1.1.8 2v21.4c0 .8-.3 1.5-.8 2-.6.6-1.2.8-2 .8H11.8Zm0-2.8h20.8v-15H11.8v15Zm0-17h20.8v-4.5H11.8v4.4Zm10.4 8.2c-.4 0-.8-.2-1-.5-.4-.3-.5-.6-.5-1 0-.5.1-.9.4-1.2.3-.3.7-.4 1.1-.4.4 0 .8.1 1 .4.4.3.5.7.5 1.1 0 .5-.1.8-.4 1.1-.3.3-.7.5-1 .5Zm-5.6 0c-.5 0-.8-.2-1.1-.5-.3-.3-.5-.6-.5-1 0-.5.2-.9.5-1.2.3-.3.6-.4 1-.4.5 0 .9.1 1.1.4.3.3.5.7.5 1.1 0 .5-.2.8-.5 1.1-.2.3-.6.5-1 .5Zm11.3 0c-.5 0-.8-.2-1.1-.5-.3-.3-.5-.6-.5-1 0-.5.2-.9.5-1.2.3-.3.6-.4 1-.4.5 0 .8.1 1.1.4.3.3.5.7.5 1.1 0 .5-.2.8-.5 1.1-.3.3-.6.5-1 .5ZM22.2 31c-.4 0-.8-.2-1-.5-.4-.3-.5-.6-.5-1 0-.5.1-.8.4-1.1.3-.3.7-.5 1.1-.5.4 0 .8.2 1 .5.4.3.5.6.5 1 0 .5-.1.8-.4 1.1-.3.3-.7.5-1 .5Zm-5.6 0c-.5 0-.8-.2-1.1-.5-.3-.3-.5-.6-.5-1 0-.5.2-.8.5-1.1.3-.3.6-.5 1-.5.5 0 .9.2 1.1.5.3.3.5.6.5 1 0 .5-.2.8-.5 1.1-.2.3-.6.5-1 .5Zm11.3 0c-.5 0-.8-.2-1.1-.5-.3-.3-.5-.6-.5-1 0-.5.2-.8.5-1.1.3-.3.6-.5 1-.5.5 0 .8.2 1.1.5.3.3.5.6.5 1 0 .5-.2.8-.5 1.1-.3.3-.6.5-1 .5Z"
      fill="currentColor"
    />
  </svg>
);

export const OnDeck = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 45 45"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}>
    <path
      d="M13.7 30.8H23v-3.1h-9.3v3Zm4.6-6.2h9.4v-3.2h-9.4v3.2Zm4.7-6.3h9.3v-3H23v3ZM12.1 37a3 3 0 0 1-2.2-1A3 3 0 0 1 9 34V12a3 3 0 0 1 1-2.2A3 3 0 0 1 12 9H34a3 3 0 0 1 2.2 1A3 3 0 0 1 37 12V34a3 3 0 0 1-1 2.2 3 3 0 0 1-2.1.9H12Zm0-3.1H34V12H12V34Z"
      fill="currentColor"
    />
  </svg>
);

export const Map = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      fill="currentColor"
      d="M22.8 23c.88 0 1.633-.313 2.26-.94.627-.627.94-1.38.94-2.26 0-.88-.313-1.633-.94-2.26a3.081 3.081 0 0 0-2.26-.94c-.88 0-1.633.313-2.26.94a3.081 3.081 0 0 0-.94 2.26c0 .88.313 1.633.94 2.26.627.627 1.38.94 2.26.94Zm0 11.76c3.253-2.987 5.667-5.7 7.24-8.14 1.573-2.44 2.36-4.607 2.36-6.5 0-2.907-.927-5.287-2.78-7.14-1.853-1.853-4.127-2.78-6.82-2.78-2.693 0-4.967.927-6.82 2.78-1.853 1.853-2.78 4.233-2.78 7.14 0 1.893.787 4.06 2.36 6.5 1.573 2.44 3.987 5.153 7.24 8.14Zm0 4.24c-4.293-3.653-7.5-7.047-9.62-10.18-2.12-3.133-3.18-6.033-3.18-8.7 0-4 1.287-7.187 3.86-9.56S19.413 7 22.8 7c3.387 0 6.367 1.187 8.94 3.56 2.573 2.373 3.86 5.56 3.86 9.56 0 2.667-1.06 5.567-3.18 8.7-2.12 3.133-5.327 6.527-9.62 10.18Z"
    />
  </svg>
);

export const Jobs = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      d="M11.7 35.2v-4.7H8.5v-3h3.2v-4.3h-5L22.6 9l15.8 14.2h-5v4.3h3.2v3h-3.2v4.7h-3v-4.7H24v4.7h-3v-4.7h-6.3v4.7h-3Zm3-7.7H21V14.4L14.7 20v7.5Zm9.3 0h6.3V20L24 14.4v13Z"
      fill="currentColor"
    />
  </svg>
);

export const Users = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      d="M6.8 35v-3.9c0-1 .2-1.8.6-2.5.5-.8 1.2-1.3 2-1.7 1.7-.8 3.2-1.3 4.6-1.7a17.6 17.6 0 0 1 8.6 0c1.4.3 3 .9 4.6 1.7.9.3 1.5 1 2 1.7s.7 1.6.7 2.5v4H6.8Zm25.4 0v-3.8c0-1.6-.4-2.9-1.1-3.9-.7-1-1.7-1.9-3-2.6a34 34 0 0 1 7.5 2 5 5 0 0 1 2.6 4.5V35h-6ZM18.3 22.5c-1.6 0-2.9-.5-3.9-1.6-1-1-1.5-2.4-1.5-4 0-1.7.5-3 1.5-4 1-1.1 2.3-1.7 4-1.7 1.5 0 2.8.6 3.8 1.6s1.6 2.4 1.6 4c0 1.7-.5 3-1.6 4.1-1 1-2.3 1.6-3.9 1.6Zm13.2-5.6c0 1.6-.5 3-1.5 4s-2.3 1.6-4 1.6a6 6 0 0 1-1.7-.3 6 6 0 0 0 1.3-2.3 9.3 9.3 0 0 0 0-6c-.3-.9-.7-1.7-1.3-2.4a7 7 0 0 1 1.8-.3c1.6 0 2.9.6 3.9 1.6s1.5 2.4 1.5 4Zm-22 15.3H27v-1a2 2 0 0 0-.3-1c-.2-.4-.5-.7-.8-.8-1.6-.8-3-1.2-4-1.5a16.3 16.3 0 0 0-7.3 0c-1.1.3-2.5.7-4.1 1.5-.3.1-.6.4-.8.7a2 2 0 0 0-.3 1v1Zm8.8-12.3c.9 0 1.6-.3 2.1-.9a3 3 0 0 0 .8-2.1 3 3 0 0 0-.8-2.2c-.5-.6-1.2-.8-2-.8-1 0-1.6.2-2.2.8a3 3 0 0 0-.8 2.2c0 .8.3 1.6.8 2.1.6.6 1.3.9 2.1.9Z"
      fill="currentColor"
    />
  </svg>
);

export const Builders = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}
    viewBox="0 0 45 45">
    <path
      d="M5.6 38.3v-21L19.4 6.7l10.4 8a7.4 7.4 0 0 0-4 1L19.3 11 8.9 19v16h7.8a4 4 0 0 0 0 .6v2.8h-11Zm13.5 0v-3.5a3.6 3.6 0 0 1 1.7-3.1 17.3 17.3 0 0 1 8.4-2.4 16 16 0 0 1 8.5 2.4c.5.3 1 .7 1.2 1.3.3.6.5 1.2.5 1.8v3.5H19Zm3.3-3h13.7v-1.2c-1.1-.6-2.2-1-3.4-1.3a12.3 12.3 0 0 0-7 0c-1 .3-2.2.8-3.3 1.4v1.1Zm6.9-7.4a5 5 0 0 1-3.7-1.6c-1-1-1.5-2.2-1.5-3.7s.5-2.7 1.5-3.8a5 5 0 0 1 3.6-1.5 5 5 0 0 1 3.7 1.5c1 1 1.5 2.3 1.5 3.8 0 1.4-.5 2.7-1.5 3.7a5 5 0 0 1-3.6 1.6Zm0-3c.6 0 1.2-.2 1.6-.6.5-.5.7-1 .7-1.7a2.3 2.3 0 0 0-2.4-2.4c-.6 0-1.2.2-1.6.7-.5.4-.7 1-.7 1.7a2.3 2.3 0 0 0 2.3 2.4Z"
      fill="currentColor"
    />
  </svg>
);

export const Companies = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}>
    <path
      fill="currentColor"
      d="M10 33V7h20v26h-8.571v-5.778H18.57V33H10Zm2.857-2.889h2.857v-5.778h8.572v5.778h2.857V9.89H12.857V30.11Zm2.857-8.667h2.857v-2.888h-2.857v2.888Zm0-5.777h2.857v-2.89h-2.857v2.89Zm5.715 5.777h2.857v-2.888h-2.857v2.888Zm0-5.777h2.857v-2.89h-2.857v2.89Z"
    />
  </svg>
);

export const Inventory = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    height={NAV_ICON_SIZE}
    width={NAV_ICON_SIZE}>
    <path
      fill="currentColor"
      d="M15.704 31.111c-1.413 0-2.622-.503-3.628-1.509-1.005-1.005-1.508-2.214-1.508-3.627V15.48a3.967 3.967 0 0 1-1.846-1.396A3.661 3.661 0 0 1 8 11.852c0-1.07.374-1.98 1.123-2.729C9.873 8.374 10.782 8 11.852 8s1.98.374 2.728 1.123c.75.75 1.124 1.659 1.124 2.729 0 .834-.241 1.578-.723 2.23a3.967 3.967 0 0 1-1.845 1.397v10.496c0 .706.251 1.311.754 1.814a2.473 2.473 0 0 0 1.814.754c.706 0 1.31-.251 1.813-.754a2.473 2.473 0 0 0 .755-1.814v-12.84c0-1.412.503-2.62 1.508-3.626C20.786 8.503 21.995 8 23.407 8c1.413 0 2.622.503 3.628 1.509 1.005 1.005 1.508 2.214 1.508 3.627v10.496a3.967 3.967 0 0 1 1.846 1.396c.481.653.722 1.397.722 2.231 0 1.07-.374 1.98-1.123 2.729-.75.749-1.659 1.123-2.729 1.123s-1.98-.374-2.728-1.123c-.75-.75-1.124-1.659-1.124-2.729 0-.834.241-1.583.723-2.247a3.736 3.736 0 0 1 1.845-1.38V13.136c0-.706-.251-1.31-.754-1.814a2.473 2.473 0 0 0-1.814-.754c-.706 0-1.31.251-1.813.754a2.473 2.473 0 0 0-.755 1.814v12.84c0 1.412-.502 2.62-1.508 3.627-1.006 1.005-2.215 1.508-3.627 1.508Zm-3.852-17.975c.364 0 .669-.123.915-.37.246-.245.369-.55.369-.914s-.123-.669-.37-.915a1.242 1.242 0 0 0-.914-.37c-.364 0-.669.124-.915.37s-.37.551-.37.915.124.669.37.915.551.369.915.369Zm15.407 15.407c.364 0 .669-.123.915-.369s.37-.551.37-.915-.124-.669-.37-.915a1.243 1.243 0 0 0-.915-.369c-.364 0-.669.123-.915.37-.246.245-.369.55-.369.914s.123.669.37.915c.245.246.55.37.914.37Z"
    />
  </svg>
);
