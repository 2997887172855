import { useTestPush } from '@services/users/registerPush';
import { Button } from '@components/ui/button';
import { Separator } from '@components/ui/separator';

export const PushTest = () => {
  const { test } = useTestPush();
  return (
    <>
      <Separator />
      {'Notification' in window &&
        Notification.permission === 'granted' &&
        import.meta.env.VITE_PUSH_TEST && <Button onClick={() => test()}>Test push</Button>}
    </>
  );
};
