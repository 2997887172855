import { SchedulerGridHeader } from './header';
import { SchedulerGridWithoutTeam } from './withoutTeam';
import { SchedulerGridForemen } from './foremen';
import { GRID_TEMPLATE_COLUMNS } from './constants';
import { useSchedulerResize } from '@hooks/scheduler/resize';
import { useSchedulerContext } from '@contexts/scheduler';
import { cn } from '@utils/cn';

export const SchedulerGrid = () => {
  const { handleMouseUp, handleMouseMove, handleMouseLeave } = useSchedulerResize();
  const {
    active: { cursor },
  } = useSchedulerContext();

  return (
    <div
      className={cn(['relative grid', cursor])}
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={GRID_TEMPLATE_COLUMNS}>
      <SchedulerGridHeader />
      <SchedulerGridWithoutTeam />
      <SchedulerGridForemen />
    </div>
  );
};
