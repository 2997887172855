import { useSchedulerContext } from '@contexts/scheduler';
import { useScheduler } from '@hooks/scheduler';
import { Schedule } from '@services/jobs/schedules/teams';
import { parseISO } from 'date-fns';
import { DragEvent } from 'react';
import { getSegmentFromPoint } from './helpers';

export const useSchedulerDragAndDrop = () => {
  const {
    inventory,
    active: { mode },
    setState,
  } = useSchedulerContext();
  const { updateDates } = useScheduler();

  const handleDragStart = (e: DragEvent<HTMLDivElement>, schedule: Schedule) => {
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        mode: 'move',
        newValues: {
          ...prev.active.newValues,
          startsAt: parseISO(schedule.startsAt),
          endsAt: parseISO(schedule.endsAt),
          from: getSegmentFromPoint(e.clientX, e.clientY),
        },
      },
    }));
  };

  const handleDrop = (id: string) => {
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        fresh: false,
        mode: 'move',
        schedule: {
          ...prev.active.schedule,
          foreman: { ...prev.active.schedule.foreman, id },
          startsAt: !inventory
            ? prev.active.newValues.startsAt?.toISOString() || prev.active.schedule.startsAt
            : prev.active.schedule.startsAt,
          endsAt: !inventory
            ? prev.active.newValues.endsAt?.toISOString() || prev.active.schedule.endsAt
            : prev.active.schedule.endsAt,
        },
      },
    }));
  };

  const handleDragOver = (e: DragEvent<HTMLDivElement>, to: Date, foremanId: string) => {
    e.preventDefault();
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        newValues: {
          ...prev.active.newValues,
          ...updateDates(),
          foremanId,
          to,
        },
      },
    }));
  };

  const handleMouse = (active: boolean, type: 'enter' | 'leave') => {
    if (!active || mode !== 'move') return;
    setState((prev) => ({
      ...prev,
      active: { ...prev.active, cursor: type === 'enter' ? 'cursor-move' : 'cursor-auto' },
    }));
  };

  return { handleDragStart, handleDrop, handleDragOver, handleMouse };
};
