import { SvgConsumer } from '@components/icons/svgConsumer';
import { Button } from '@components/ui/button';
import { cn } from '@utils/cn';
import {
  Dispatch,
  DragEvent,
  InputHTMLAttributes,
  RefObject,
  SetStateAction,
  SyntheticEvent,
  forwardRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';

interface FileInputProps extends InputHTMLAttributes<HTMLInputElement> {
  files: FileList | null;
  setFiles: Dispatch<SetStateAction<FileList | null>>;
  removeFile: (index: number) => void;
  dragAndDrop?: boolean;
}

export const FileInput = forwardRef<HTMLInputElement, FileInputProps>(
  ({ files, setFiles, removeFile, dragAndDrop, accept, ...rest }, ref) => {
    const { t } = useTranslation('app', { keyPrefix: 'fileUpload' });
    const inputRef = ref as RefObject<HTMLInputElement>;
    const [isDragging, setIsDragging] = useState<boolean>(false);

    const addValidFiles = (fileList: FileList) => {
      const dt = new DataTransfer();
      if (files) Array.from(files).forEach((file) => dt.items.add(file));
      Array.from(fileList).forEach((file) => {
        if (file.type || file.name.includes('.dwg')) dt.items.add(file);
      });
      setFiles(dt.files);
    };

    const handleDrop = (e: DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      setIsDragging(false);
      addValidFiles(e.dataTransfer.files);
    };

    if (!inputRef) return null;

    return (
      <div className="flex flex-col gap-2">
        <div
          onDrop={handleDrop}
          onDragOver={(e) => e.preventDefault()}
          onDragLeave={() => setIsDragging(false)}
          onDragEnter={() => setIsDragging(true)}
          className={cn([
            'relative flex w-full flex-col items-center gap-3 border border-dashed border-neutral-800 py-5 font-semibold text-neutral-500 after:absolute after:left-0 after:top-0 after:h-full after:w-full',
            isDragging ? 'bg-neutral-100 after:block' : 'bg-neutral-50 after:hidden',
          ])}>
          <span className="text-sm">{t(dragAndDrop ? 'dragAndDrop' : 'chooseFiles')}</span>
          {dragAndDrop && <span className="text-xs">{t('or')}</span>}
          <Button variant="secondary" onClick={() => inputRef?.current?.click()}>
            <SvgConsumer id="uploadDocument" />
            {t('browseFiles')}
          </Button>
          <input
            ref={ref}
            className="hidden"
            onChange={(e: SyntheticEvent<HTMLInputElement>) => setFiles(e.currentTarget.files)}
            type="file"
            accept={accept?.includes('image/vnd.dwg') ? '.dwg' : accept}
            capture={accept?.includes('image') ? 'environment' : undefined}
            {...rest}
          />
        </div>
        {files && Boolean(Array.from(files).length) && (
          <>
            <h3 className="text-m font-bold text-neutral-500">{t('selectedFiles')}</h3>
            <div className="flex flex-col gap-2">
              {Array.from(files).map((file, index) => (
                <div
                  className="flex items-center justify-between gap-2 text-sm"
                  key={[file.name, index].join('-')}>
                  <span className="w-[200px] overflow-hidden text-ellipsis font-bold text-primary-500">
                    {file.name}
                  </span>
                  <div className="flex items-center gap-2">
                    <span className="whitespace-nowrap text-neutral-400">
                      {file.size
                        ? Math.round(((file.size + Number.EPSILON) / 1024 / 1024) * 100) / 100
                        : 0}{' '}
                      MB
                    </span>
                    <Button
                      className="-mr-2 mb-[1px] h-fit p-2"
                      variant="ghost"
                      onClick={() => removeFile(index)}>
                      <SvgConsumer className="text-neutral-300" id="trash" />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  },
);
