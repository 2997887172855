import { useTranslation } from 'react-i18next';
import { JobTaskFormCountProps } from './types';

export const JobTaskFormCount = ({ count, total }: JobTaskFormCountProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.questionary' });

  if (!count || !total) return;

  return (
    <p className="text-xs text-neutral-800">
      <span className="font-bold">
        {count} / {total}
      </span>
      &nbsp;
      {t('answered')}
    </p>
  );
};
