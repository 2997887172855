import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError, InventoryLocation } from '@root/globalTypes';
import { useQueryParams } from '@hooks/useQueryParams';
import { FilterValue } from '@services/users/filters/types';
import { InventoryLocationsRespSuccess } from '../types';

export const useGetInventoryAvailable = (location: InventoryLocation) => {
  const {
    params: { routeId, available: search },
    parseParams,
  } = useQueryParams<FilterValue>();

  const { locationId, locationType } = location;

  const queryParams = parseParams({ routeId, search, locationId, locationType });

  const { data, error, isFetching } = useQuery<InventoryLocationsRespSuccess, ApiError>({
    queryKey: [...queryKeys.inventoryAvailable, queryParams],
    queryFn: () =>
      queryFetch({
        url: `routes/available${queryParams}`,
      }),
    placeholderData: (previousData) => previousData as InventoryLocationsRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
