import { ProtectedRoute } from '@components/protectedRoute';
import { appRoutes } from '@utils/consts';

export const buildersRoutes = [
  {
    path: appRoutes.builders,
    lazy: async () => {
      const { Builders } = await import('@pages/builders');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={['administrator', 'project_coordinator']}>
            <Builders />
          </ProtectedRoute>
        ),
      };
    },
  },
  {
    path: appRoutes.builder(':id'),
    lazy: async () => {
      const { Builder } = await import('@pages/builders/:id');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={['administrator', 'project_coordinator']}>
            <Builder />
          </ProtectedRoute>
        ),
      };
    },
  },
];
