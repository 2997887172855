import { useAppContext } from '@contexts/app';
import { SignIn } from './signIn';
import { Navigate, useNavigate } from 'react-router-dom';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { useEffect } from 'react';

export const DefaultView = () => {
  const data = useAppContext();
  const navigate = useNavigate();

  const { isLogged, isForeman, isManager, isCadTechnician } = data;

  useEffect(() => {
    if (!isLogged) return;
    if (isForeman) navigate(parseRoute(`/${appRoutes.foremanSchedules}`));
    if (isManager) navigate(parseRoute(`/${appRoutes.hotSeat}`));
    if (isCadTechnician) navigate(parseRoute(`/${appRoutes.jobs}`));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, navigate]);

  if (!isLogged) return <SignIn />;
  if (isForeman) return <Navigate to={parseRoute(`/${appRoutes.foremanSchedules}`)} replace />;
  if (isManager) return <Navigate to={parseRoute(`/${appRoutes.hotSeat}`)} replace />;
  if (isCadTechnician) return <Navigate to={parseRoute(`/${appRoutes.jobs}`)} replace />;
};
