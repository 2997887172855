import { typography } from '@config/typography';
import { type ClassValue, clsx } from 'clsx';
import { extendTailwindMerge } from 'tailwind-merge';

export const twMerge = extendTailwindMerge({
  classGroups: {
    'font-size': Object.keys(typography as Record<string, string[]>).map((key) => `text-${key}`),
  },
});

export const cn = (...inputs: ClassValue[]) => {
  return twMerge(clsx(inputs));
};
