import { foremanRoute } from '@utils/consts';

export const foremanOthersRoutes = [
  {
    path: foremanRoute.others,
    lazy: async () => {
      const { Others } = await import('@pages/foreman/others');
      return {
        Component: () => <Others />,
      };
    },
  },
];
