import { WorkloadWeekProps } from './types';
import { getPrefix } from './helpers';
import { shortDate } from '@utils/i18n';
import { getFirstDayOfBusinessWeek, getLastDayOfBusinessWeek } from '../../helpers';
import { useTranslation } from 'react-i18next';

export const WorkloadWeek = ({ date }: WorkloadWeekProps) => {
  const { t } = useTranslation('app');
  const prefix = getPrefix(date);

  return (
    <div className="flex h-9 select-none flex-col justify-end font-bold">
      <span className="text-xs text-neutral-300">{t(prefix)}</span>
      <span className="text-sm text-neutral-500">
        {[
          shortDate(getFirstDayOfBusinessWeek(date)),
          shortDate(getLastDayOfBusinessWeek(date)),
        ].join(' - ')}
      </span>
    </div>
  );
};
