import { useQuery } from '@tanstack/react-query';
import { ApiError, QueryParams } from '@root/globalTypes';
import { UsersRespSuccess, fetchUser } from './fetchUser';
import { queryKeys } from '@utils/consts';
import { parseSpecialtiesAttributes } from '@utils/textHelpers';

export const useGetUser = ({ id }: QueryParams) => {
  const { data, error, isFetching } = useQuery<UsersRespSuccess, ApiError>({
    queryKey: [...queryKeys.user, id],
    queryFn: () => fetchUser({ id }),
    select: (d) => {
      return {
        ...d,
        data: {
          ...d.data,
          specialties: parseSpecialtiesAttributes(d.data.specialties || [], 'camel'),
        },
      };
    },
    placeholderData: (previousData) => previousData as UsersRespSuccess,
    enabled: Boolean(id),
  });

  return {
    userData: data,
    error,
    isLoading: isFetching,
  };
};
