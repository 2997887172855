import { SchedulerGridScheduleProps } from './types';
import { ReactNode, useState } from 'react';
import { SchedulerGridScheduleElement } from './element';
import { AppTooltip } from '@components/ui/tooltip/tooltip';

export const SchedulerGridSchedule = ({ data }: SchedulerGridScheduleProps) => {
  const [isTruncated, setIsTruncated] = useState(false);

  const withTooltip = (component: ReactNode) =>
    isTruncated ? (
      <AppTooltip
        className="w-fit max-w-fit p-0.5"
        variant="dark"
        side="top"
        context={<SchedulerGridScheduleElement data={data} tooltip />}>
        {component}
      </AppTooltip>
    ) : (
      component
    );

  return withTooltip(
    <div>
      <SchedulerGridScheduleElement data={data} setIsTruncated={setIsTruncated} />
    </div>,
  );
};
