import { MapRecord } from '@root/globalTypes';

export const hasCoords = (data: MapRecord) => {
  const { lat, lon } = data;
  if (lat && lon) return true;
  return false;
};

export const focusOnElement = (id: string) => {
  setTimeout(() => {
    const mapListWrapper = document.querySelector('.map-list') as HTMLDivElement;
    const mapListItem = document.querySelector(`#map-list-item-${id}`) as HTMLLIElement;
    if (!mapListWrapper || !mapListItem) return;
    // eslint-disable-next-line immutable/no-mutation
    mapListWrapper.scrollTop = mapListItem.offsetTop;
  }, 10);
};
