import { appRoutes, supportedLanguages } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { ReactNode } from 'react';
import { Navigate, useParams } from 'react-router-dom';

export const LangValidation = ({ children }: { children: ReactNode }) => {
  const { lang } = useParams();

  if (lang && !supportedLanguages.includes(lang))
    return <Navigate to={`/${parseRoute(appRoutes.notFound)}`} />;

  return children;
};
