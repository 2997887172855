import { AppTooltip } from '@components/ui/tooltip/tooltip';
import { JobStatus } from '../helpers';
import { Alert } from '@root/globalTypes';
import { icons } from '../stage/icons';
import { JobAlertTooltip } from './tooltip';

interface JobAlertProps {
  status: JobStatus;
  alerts: Alert[];
}

export const JobAlert = ({ status, alerts }: JobAlertProps) => {
  const renderAlertIcon = () => {
    if (['error', 'warning'].includes(status)) {
      return (
        <AppTooltip variant="dark" context={<JobAlertTooltip data={alerts} />}>
          <span>{icons[status]}</span>
        </AppTooltip>
      );
    }
    return <span>{icons[status]}</span>;
  };

  return renderAlertIcon();
};
