import { QueryParams, User } from '@root/globalTypes';
import { queryFetch } from '@services/api';

export interface UsersRespSuccess {
  success: true;
  data: User;
  hotSeatAlerts: boolean;
  onDeckAlerts: boolean;
}

export const fetchUser = async (p: QueryParams): Promise<UsersRespSuccess> => {
  return queryFetch({ url: `users/${p.id}` });
};
