/* eslint-disable immutable/no-mutation */

import { useSchedulerContext } from '@contexts/scheduler';
import { MouseEvent } from 'react';
import { getSegmentFromPoint } from '../dnd/helpers';
import { SchedulerGridScheduleResizeType } from '@components/scheduler/grid/schedule/resize/types';
import { addMinutes, differenceInMinutes, isBefore, isEqual, isValid, parseISO } from 'date-fns';
import { SEGMENT_LENGTH } from '@contexts/scheduler/constants';
import { compareDate, compareHours } from '@utils/dateHelpers';
import { getElementStyle } from '@hooks/scheduler/elementStyle/helpers';
import { getLastHour } from '@contexts/scheduler/helpers';

export const useSchedulerResize = () => {
  const {
    hours,
    inventory,
    active: { schedule, mode, measures, initialX, newValues },
    setState,
  } = useSchedulerContext();
  const isLeft = mode === 'left';
  const isRight = mode === 'right';
  const isResizing = isLeft || isRight;
  const lastHour = getLastHour(hours);
  const [initialStartsAt, initialEndsAt] = [parseISO(schedule.startsAt), parseISO(schedule.endsAt)];

  const isSafe = (startsAt?: Date, endsAt?: Date) => {
    if (!startsAt || !endsAt) return false;
    if (differenceInMinutes(endsAt, startsAt) < SEGMENT_LENGTH) return false;
    return compareDate(endsAt, startsAt);
  };

  const resetStyles = () => {
    const element = document.querySelector('.scheduler-active-schedule') as HTMLDivElement;
    if (!measures || !element) return;
    const { width } = measures;
    element.style.width = `${width}px`;
    element.style.left = '2px';
  };

  const handleResize = (e: MouseEvent<HTMLDivElement>) => {
    const element = document.querySelector('.scheduler-active-schedule') as HTMLDivElement;
    if (!measures && element) {
      const newWidth = e.clientX - initialX;
      element.style.width = `${newWidth}px`;
      return;
    }
    if (!measures || !element) return;
    const { x, width } = measures;
    const left = x - e.clientX;
    const newWidth = isLeft ? left + width + 2 : e.clientX - x;
    if (!isSafe(newValues.startsAt, newValues.endsAt)) {
      resetStyles();
      return;
    }
    element.style.width = `${newWidth}px`;
    if (isLeft) element.style.left = `${-left}px`;
  };

  const handleMouseDown = (direction: SchedulerGridScheduleResizeType) => {
    const element = document.querySelector('.scheduler-active-schedule') as HTMLDivElement;
    if (inventory) return;
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        measures: element ? element.getBoundingClientRect() : prev.active.measures,
        mode: direction,
        cursor: 'cursor-ew-resize',
      },
    }));
  };

  const handleMouseUp = () => {
    const element = document.querySelector('.scheduler-active-schedule') as HTMLDivElement;
    if (!isResizing) return;
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        fresh: false,
        schedule: {
          ...prev.active.schedule,
          startsAt: prev.active.newValues.startsAt?.toISOString() || '',
          endsAt: prev.active.newValues.endsAt?.toISOString() || '',
        },
        measures: element ? element.getBoundingClientRect() : prev.active.measures,
        mode: 'move',
        cursor: 'cursor-auto',
      },
    }));

    setTimeout(() => {
      const styles = getElementStyle(schedule.id, true);
      if (!element || !styles) return;
      element.style.width = `${styles.width}px`;
      element.style.left = `${styles.left}px`;
    }, 250);
  };

  const handleMouseLeave = () => {
    if (!isResizing) return;
    resetStyles();
    setState((prev) => ({
      ...prev,
      active: {
        ...prev.active,
        newValues: {
          startsAt: parseISO(prev.active.schedule.startsAt),
          endsAt: parseISO(prev.active.schedule.endsAt),
        },
        cursor: 'cursor-auto',
        mode: 'move',
      },
    }));
  };

  const handleMouseMove = (e: MouseEvent<HTMLDivElement>) => {
    if (!isResizing) return;
    const date = getSegmentFromPoint(e.clientX, e.clientY);
    const startsAt = isLeft ? date : initialStartsAt;
    const endsAt = isRight
      ? addMinutes(
          date,
          isBefore(date, initialEndsAt) ||
            compareHours(addMinutes(date, SEGMENT_LENGTH), lastHour) ||
            !differenceInMinutes(date, initialStartsAt)
            ? SEGMENT_LENGTH
            : 0,
        )
      : initialEndsAt;

    const isSafeOver = () => {
      if (!isValid(date)) return false;
      if (isLeft && !isSafe(startsAt, initialEndsAt)) return false;
      if (isRight && !isSafe(initialStartsAt, endsAt)) return false;
      return true;
    };

    if (isSafeOver()) {
      handleResize(e);
      setState((prev) => ({
        ...prev,
        active: {
          ...prev.active,
          newValues: {
            ...prev.active.newValues,
            startsAt:
              isLeft && isSafeOver() && !isEqual(date, newValues.startsAt || 0)
                ? startsAt
                : prev.active.newValues.startsAt,
            endsAt:
              isRight && isSafeOver() && !isEqual(date, newValues.endsAt || 0)
                ? endsAt
                : prev.active.newValues.endsAt,
          },
        },
      }));
    } else {
      resetStyles();
      setState((prev) => ({
        ...prev,
        active: {
          ...prev.active,
          newValues: {
            startsAt: parseISO(prev.active.schedule.startsAt),
            endsAt: parseISO(prev.active.schedule.endsAt),
          },
          cursor: 'cursor-auto',
          mode: 'move',
        },
      }));
    }
  };

  return { handleMouseDown, handleMouseUp, handleMouseMove, handleMouseLeave };
};
