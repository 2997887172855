import { transformCamelCaseToSnakeCase } from './textHelpers';
import { ConstructionType, UserRole } from '@root/globalTypes';
import { WorkloadView } from '@components/workload/filters/types';

export enum ESupportedLangs {
  en = 'en',
  es = 'es',
}

export enum Priorities {
  low = 'low',
  medium = 'medium',
  high = 'high',
}

export type JobStageStatus =
  | 'toSchedule'
  | 'scheduleRequestSent'
  | 'scheduled'
  | 'inProgress'
  | 'completed'
  | 'warning'
  | 'error';

export const priorities = Object.values(Priorities);

export const supportedLanguages: string[] = Object.values(ESupportedLangs);

export const getLangFromPath = () => {
  const p = window.location.pathname;
  const isSupportedLang = supportedLanguages.find((lang) => p.startsWith(`/${lang}`));
  if (isSupportedLang) return isSupportedLang;
  return 'en';
};

export const jobIdRoute = {
  index: '',
  materials: 'materials',
  inventory: 'inventory',
  documents: 'documents',
  workProgress: 'work-progress',
  questionary: 'questionary',
  workProgressStage: ':stageId',
};

export const foremanRoute = {
  index: 'foreman',
  archive: 'archive',
  requests: 'requests',
  route: (id: string) => `routes/${id}`,
  schedules: 'schedules',
  task: (id: string) => `tasks/${id}`,
  questionary: 'questionary',
  form: 'form',
  others: 'others',
  taskId: ':taskId',
  routeId: ':routeId',
  pointId: ':pointId',
  questionId: ':questionId',
};

export const appRoutes = {
  index: '/',
  base: '',
  back: '..',
  id: ':id',
  notFound: 'not-found',
  form: (id: string) => `form/${id}`,
  foremanArchive: `${foremanRoute.index}/${foremanRoute.archive}`,
  foremanRequests: `${foremanRoute.index}/${foremanRoute.requests}`,
  foremanRoute: (id: string) => `${foremanRoute.index}/${foremanRoute.route(id)}`,
  foremanRouteForm: (id: string) =>
    `${foremanRoute.index}/${foremanRoute.route(id)}/${foremanRoute.form}`,
  foremanSchedules: `${foremanRoute.index}/${foremanRoute.schedules}`,
  foremanTask: (id: string) => `${foremanRoute.index}/${foremanRoute.task(id)}`,
  foremanTaskQuestionary: (id: string) =>
    `${foremanRoute.index}/${foremanRoute.task(id)}/${foremanRoute.questionary}`,
  foremanTaskQuestionaryDetails: (taskId: string, questionId: string) =>
    `${foremanRoute.index}/${foremanRoute.task(taskId)}/${foremanRoute.questionary}/${questionId}`,
  foremanOthers: `${foremanRoute.index}/${foremanRoute.others}`,
  passwordRecovery: 'password-recovery',
  passwordRecoveryInfo: 'password-recovery/info',
  passwordReset: 'password-reset',
  passwordResetConfirmation: 'password-reset/confirmed',
  users: 'users',
  builder: (id: string) => `builders/${id}`,
  builders: 'builders',
  acceptInvitation: 'users/accept-invitation',
  jobs: 'jobs',
  jobPreview: (id: string) => `preview/${id}`,
  job: (id: string) => `jobs/${id}`,
  jobMaterials: (id: string) => `jobs/${id}/${jobIdRoute.materials}`,
  jobInventory: (id: string) => `jobs/${id}/${jobIdRoute.inventory}`,
  jobDocuments: (id: string) => `jobs/${id}/${jobIdRoute.documents}`,
  jobQuestionary: (id: string) => `jobs/${id}/${jobIdRoute.questionary}`,
  jobWorkProgress: (id: string) => `jobs/${id}/${jobIdRoute.workProgress}`,
  hotSeat: 'hot-seat',
  onDeck: 'on-deck',
  workload: 'workload',
  companies: 'companies',
  company: (id: string) => `companies/${id}`,
  inventory: 'inventory',
  map: 'map',
};

const basicAccess = [
  appRoutes.hotSeat,
  appRoutes.workload,
  appRoutes.onDeck,
  appRoutes.map,
  appRoutes.jobs,
];

const extendedAccess = [...basicAccess, appRoutes.inventory];

export const userAccessMap: Record<string, string[]> = {
  administrator: [
    ...basicAccess,
    appRoutes.users,
    appRoutes.builders,
    appRoutes.companies,
    appRoutes.inventory,
  ],
  projectCoordinator: [
    ...basicAccess,
    appRoutes.builders,
    appRoutes.companies,
    appRoutes.inventory,
  ],
  cadTechnician: [appRoutes.map, appRoutes.jobs, appRoutes.inventory],
  superintendent: extendedAccess,
  foreman: [],
  layoutManager: extendedAccess,
  customerServiceManager: extendedAccess,
  generalSuperintendent: extendedAccess,
  readOnly: extendedAccess,
};

export const usersAccessList = (path: string) => {
  const allowedUsers: UserRole[] = [];
  Object.entries(userAccessMap).forEach(([user, values]) => {
    if (values.includes(path)) allowedUsers.push(transformCamelCaseToSnakeCase(user) as UserRole);
  });
  return allowedUsers;
};

export const queryKeys = {
  builders: ['builders'],
  builder: ['builder'],
  buildersDropdown: ['builders-dropdown'],
  buildersSuperintendentsDropdown: ['builders-superintendents-dropdown'],
  buildersList: ['builders', 'builders-list'],
  dropdowns: ['dropdowns'],
  foremanSchedules: ['foreman-schedules'],
  foremanRoute: ['foreman-route'],
  foremanRequests: ['foreman-requests'],
  foremanArchive: ['foreman-archive'],
  foremanTask: ['foreman-task'],
  foremanStages: ['foreman-stages'],
  foremanOthers: ['foreman-others'],
  plans: ['plans'],
  job: ['job'],
  jobs: ['jobs'],
  jobsList: ['jobs', 'jobs-list'],
  jobStages: ['job', 'stages'],
  jobInventory: ['job-inventory'],
  jobInventoryDropdown: ['job-inventory-dropdown'],
  jobInventoryDialog: ['job-inventory-dialog'],
  stagesDropdown: ['stages-dropdown'],
  jobPreview: ['job-preview'],
  onDeck: ['on-deck'],
  projectCoordinator: ['project_coordinator'],
  schedules: ['schedules'],
  superintendent: ['superintendent'],
  tasksDropdown: ['tasks-dropdown'],
  user: ['user'],
  users: ['users'],
  usersDropdown: ['users-dropdown'],
  usersList: ['users', 'users-list'],
  notifications: ['notifications'],
  subtasksDropdown: ['subtasks-dropdown'],
  documents: ['documents'],
  foremanDocuments: ['foreman-documents'],
  materials: ['materials'],
  foremanMaterials: ['foreman-materials'],
  workloadCalendar: ['workload-calendar'],
  workloadCalendarSidebar: ['workload-calendar-sidebar'],
  workload: ['workload'],
  filters: ['filters'],
  companies: ['companies'],
  companiesDropdown: ['companies-dropdown'],
  company: ['company'],
  contacts: ['contacts'],
  files: ['files'],
  inventoryRoutes: ['inventory-routes'],
  inventoryRequested: ['inventory-requested'],
  inventoryRequestedLocation: ['inventory-requested-location'],
  inventoryAvailable: ['inventory-available'],
  inventoryAvailableLocation: ['inventory-available-location'],
  inventoryCustomAdd: ['inventory-custom-add'],
  inventoryMissingLoad: ['inventory-missing-load'],
  inventoryRemainingLoad: ['inventory-remaining-load'],
  inventoryDropdown: ['inventory-dropdown'],
  locationsDropdown: ['locations-dropdown'],
  locationItems: ['location-items'],
  map: ['map'],
  mapMenu: ['map-menu'],
  questionary: ['questionary'],
  questionaryAttachment: ['questionary-attachment'],
};

export const speciality = {
  layout: 'Layout',
  footing: 'Footing',
  walls: 'Walls',
  pump: 'Pump',
  utilityDriver: 'Utility Driver',
  stripout: 'Stripout',
  crane: 'Crane',
  customerService: 'Customer Service',
};

export const multitaskRoles = ['crane', 'pump'];

export const uuidRegEx =
  /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

export const geolocalizationRegEx =
  /^[-]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/;

export const errorKeyRegEx = /^\w+$/;

export const isSecureConnection = window.location.protocol.includes('https');

export const foremanWidth = 160;

export const jobStates = ['construction', 'pre_construction', 'follow_up'];

export const jobConstructionTypes: ConstructionType[] = ['residential', 'commercial'];

export const jobStatuses = ['pending', 'approved', 'rejected'];

export const workloadViews: WorkloadView[] = ['inventory'];

export const use24h = import.meta.env.VITE_TIME_FORMAT === '24h';
