import { cn } from '@utils/cn';
import { EdgeProps } from './types';
import { EdgeElement } from './element';

export const Edge = ({ className, count, ...rest }: EdgeProps) => (
  <div className={cn(['absolute flex flex-col', className])}>
    {new Array(count).fill(null).map((_, index) => (
      <EdgeElement key={`edge-${rest.position}-${index}`} {...rest} />
    ))}
  </div>
);
