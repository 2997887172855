import { useAppContext } from '@contexts/app';
import { UserRole } from '@root/globalTypes';
import { appRoutes } from '@utils/consts';
import { parseRoute } from '@utils/i18n';
import { loginRedirect } from '@utils/textHelpers';
import { Navigate, useLocation } from 'react-router-dom';

export const ProtectedRoute = ({
  children,
  allowedRoles = [],
}: {
  children: React.ReactNode;
  allowedRoles?: UserRole[];
}) => {
  const { isLogged, role } = useAppContext();
  const { pathname } = useLocation();
  if (role && allowedRoles.length && !allowedRoles.includes(role as UserRole)) {
    return <Navigate to={parseRoute(`${appRoutes.index}`)} replace />;
  }
  if (!isLogged) return <Navigate to={loginRedirect(parseRoute(pathname || ''))} replace />;
  return <>{children}</>;
};
