import { cn } from '@utils/cn';
import { EdgeElementProps } from './types';

export const EdgeElement = ({
  size,
  position,
  elementClassName: className,
  error,
  warning,
}: EdgeElementProps) => {
  const isLeft = position === 'left';
  const hasAlert = error || warning;

  const setBorders = () => {
    if (!isLeft) return 'border-r border-t';
    if (hasAlert) return 'border-b-4 border-l-4';
    return 'border-b border-l';
  };

  const background = className?.split(' ')?.find((el) => el.includes('bg'));

  return (
    <div style={{ height: (size - 1) * Math.SQRT2 }}>
      <div
        style={{ height: size, width: size }}
        className={cn([
          'relative rotate-45',
          className,
          isLeft ? 'shadow-task' : 'shadow-task-mirror',
          isLeft && error && 'border-danger-500',
          isLeft && warning && 'border-warning-500',
          setBorders(),
        ])}>
        <div
          style={{ height: size, width: size }}
          className={cn([
            'absolute',
            background,
            isLeft ? 'bottom-[0px] left-[0px]' : 'right-[0px] top-[0px]',
          ])}
        />
      </div>
    </div>
  );
};
