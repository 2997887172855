export const getElementStyle = (id?: string, active?: boolean) => {
  if (!id) return;
  const startSegment = document.querySelector(`[data-start='${id}']`)?.getBoundingClientRect();
  const endSegment = document.querySelector(`[data-end='${id}']`)?.getBoundingClientRect();
  if (!startSegment || !endSegment) return;
  return {
    width: endSegment?.x + endSegment?.width - startSegment?.x - 4,
    height: active ? 'calc(50%-2px)' : startSegment?.height - 5,
    left: startSegment?.x - startSegment?.left + 2,
    transitionDuration: '50ms',
  };
};
