import { Checkbox } from '@components/ui/checkbox';
import { cn } from '@utils/cn';
import { IssueProps } from './types';
import { variants } from './constants';
import { NoteBox } from '../job/workProgress/noteBox';
import { useJobContext } from '@contexts/job';
import { IssueList } from './list';
import { IssueAuthor } from './author';
import { IssueHeading } from './heading';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useResolveIssue } from '@services/issues/resolve';
import { getVariant } from './helpers';

export const Issue = ({ data, disabled }: IssueProps) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();
  const { job, allowed, archived } = useJobContext();
  const jobId = job?.data?.data?.id;
  const { id, description, createdAt, note, images, creator } = data;
  const variant = getVariant(data);

  const { mutate, isLoading } = useResolveIssue({
    callback: () => {
      toast({
        title: t('messages.success.issue.resolve'),
      });
    },
  });

  return (
    <>
      <div
        className={cn([
          'col-start-1 col-end-3 flex items-center gap-6 border-t px-6 py-3',
          variants[variant].background,
          variants[variant].border,
          variants[variant].text,
        ])}>
        <div className="flex flex-col text-xs">
          <IssueHeading>{description}</IssueHeading>
          <div className="ml-[30px] flex flex-col gap-3">
            <IssueAuthor date={createdAt} name={creator.fullName} />
            <IssueList issueId={id} data={images} />
          </div>
        </div>
      </div>

      <div
        className={cn([
          'col-start-3 col-end-4 border-t',
          variants[variant].background,
          variants[variant].border,
        ])}>
        <div className="h-full w-fit border-x px-4.5 py-4">
          <Checkbox
            checked={variant === 'resolved'}
            disabled={isLoading || !allowed || archived || variant === 'resolved'}
            onCheckedChange={() => mutate(id)}
          />
        </div>
      </div>

      <div
        className={cn([
          'col-start-4 col-end-5 border-t',
          variants[variant].background,
          variants[variant].border,
        ])}>
        <NoteBox referableType="Issue" data={note} jobId={jobId} typeId={id} disabled={disabled} />
      </div>
    </>
  );
};
