import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SetError, handleOnSettled, useHandleFormErrors, validateFloat } from '@utils/formHelpers';
import { Material } from '@root/globalTypes';
import { useForemanUpdateMaterial } from '@services/materials/foreman/update';
import { getSlumpOption } from '@pages/jobs/:id/materials/helpers';
import { useTranslation } from 'react-i18next';
import { useUpdateMaterial } from '@services/materials/update';
import { MaterialResp } from '@services/materials/types';

interface UseConcreteUpdateFormProps {
  successHandler: () => void;
  material?: Material;
  foreman?: boolean;
}

export const useConcreteUpdateForm = ({
  successHandler,
  material,
  foreman,
}: UseConcreteUpdateFormProps) => {
  const handleFormErrors = useHandleFormErrors();
  const { t } = useTranslation('app');

  const validateNumber = (name: string) =>
    z.custom<string>((value) => validateFloat(value), t(`forms.${name}.required`));

  const formSchema = z.object({
    finalQuantity: validateNumber('final'),
    finalQuantityRepeat: z.custom<string>((val) => {
      const { value } = document.querySelector(
        `#final-quantity-${material?.id}`,
      ) as HTMLInputElement;
      return val === value;
    }, t('forms.repeatFinal.required')),
    finalSlump: validateNumber('final'),
    finalSlumpRepeat: z.custom<string>((val) => {
      const { value } = document.querySelector(`#final-slump-${material?.id}`) as HTMLInputElement;
      return val === value;
    }, t('forms.repeatFinal.required')),
  });

  const defaultValues = {
    finalQuantityRepeat: '',
    finalSlumpRepeat: '',
  };

  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: defaultValues,
  });

  const onSettled = (resp: MaterialResp) => {
    const handleError: SetError = (name, { type, message }) => {
      form.setError(name as keyof z.infer<typeof formSchema>, {
        type,
        message,
      });
    };
    let error: object = {};
    if (resp?.errors) {
      error = { root: resp.errors };
      if (Object.keys(resp.errors).length) {
        error = { ...resp.errors } as object;
      }
    }

    handleOnSettled<MaterialResp>(resp, successHandler, () =>
      handleFormErrors(handleError, Object.keys(defaultValues), error),
    );
  };

  const foremanUpdate = useForemanUpdateMaterial({ callback: onSettled });
  const materialUpdate = useUpdateMaterial({ callback: onSettled });

  const onSubmit = async (values: z.infer<typeof formSchema>) => {
    if (!material) return;
    const { finalQuantity, finalSlump } = values;
    const { id, jobId, product, unit, options } = material;

    const data = {
      id,
      jobId,
      product: product || '',
      unit: unit || '',
      finalQuantity: parseFloat(finalQuantity),
      optionsAttributes: [
        {
          id: getSlumpOption(options)?.id,
          finalQuantity: parseFloat(finalSlump),
        },
      ],
    };

    if (foreman) foremanUpdate.mutate(data);
    else materialUpdate.mutate(data);
  };

  return {
    form,
    onSubmit,
  };
};
