import { ApiError, User } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation } from '@tanstack/react-query';

export type LogInUserReq = {
  email: string;
  password: string;
};
export interface LogInUserRespSuccess {
  [key: string]: unknown;
  readonly status?: boolean;
  readonly data?: User;
}

export type LogInUserResp = LogInUserRespSuccess | ApiError;

export const useLogIn = ({ callback }: { callback: (data: LogInUserResp) => void }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: (userData: LogInUserReq): Promise<LogInUserResp> =>
      queryFetch({ url: 'users/sign_in', data: userData, opt: { method: 'POST' }, useAuth: false }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};
