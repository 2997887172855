import { Button } from '@components/ui/button';
import { Form, FormField, FormMessage } from '@components/ui/form';
import { FormItemWrapper } from '@components/ui/form/itemWrapper';
import { useTranslation } from 'react-i18next';
import { useToast } from '@hooks/useToast';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '@components/ui/dialog';
import { useEffect } from 'react';
import { Note } from '@root/globalTypes';
import { useNoteForm } from '@hooks/forms/useNoteForm';
import { Textarea } from '@components/ui/form/textarea';

interface NoteDialogProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  initialValues?: Note;
  usage?: string;
}

export const NoteDialog = ({ open, setOpen, initialValues, usage }: NoteDialogProps) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();
  const edit = Boolean(initialValues?.id);

  const { form, onSubmit, locked } = useNoteForm({
    initialValues,
    successHandler: (action) => {
      setOpen(false);
      form.reset();
      toast({
        title: t(`messages.success.note.${action}`),
      });
    },
    usage,
  });

  const { setValue, watch, reset } = form;
  const formContent = watch('content');

  useEffect(() => {
    if (!open) {
      reset();
      return;
    }
    if (!initialValues) return;
    const { content, referableType } = initialValues;
    if (content) setValue('content', content);
    setValue('important', referableType === 'Job');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, setValue, reset]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(`pages.job.info.note.${edit ? 'update' : 'create'}`)}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-4">
            <FormField
              control={form.control}
              name="content"
              render={({ field }) => (
                <FormItemWrapper label={t('forms.note.label')}>
                  <Textarea
                    {...field}
                    className="max-h-[480px]"
                    placeholder={t('forms.note.placeholder')}
                  />
                </FormItemWrapper>
              )}
            />
            {/* <FormField
              control={form.control}
              name="important"
              render={({ field: { value, onChange, ...rest } }) => (
                <FormItemWrapper>
                  <Checkbox
                    disabled={Boolean(edit || initialValues?.referableType === 'Job')}
                    {...rest}
                    checked={value}
                    onCheckedChange={onChange}>
                    <div className="flex items-center gap-2">{t('forms.important.label')}</div>
                  </Checkbox>
                </FormItemWrapper>
              )}
            /> */}
            <FormField name="root" render={() => <FormMessage />} />
            <div className="flex items-center justify-end gap-2">
              <Button
                variant="ghost"
                disabled={!formContent.length}
                onClick={() => setValue('content', '')}>
                {t('actions.clear')}
              </Button>
              <Button variant="secondary" onClick={() => setOpen(false)}>
                {t('actions.cancel')}
              </Button>
              <Button type="submit" disabled={locked}>
                {t('actions.save')}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
