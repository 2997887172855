import { createContext, useContext, useEffect, useState } from 'react';
import {
  JobInventoryContextData,
  JobInventoryData,
  JobInventoryProviderProps,
  JobInventoryToggle,
} from './types';
import { initialState } from './constants';
import { useJobInventoryForm } from '@hooks/forms/useJobInventoryForm';
import { useToast } from '@hooks/useToast';
import { useTranslation } from 'react-i18next';
import { useGetJobInventory } from '@services/jobs/inventory';
import { useJobInventoryDropdown } from '@services/jobs/inventory/dropdown';

const JobInventoryContext = createContext<JobInventoryContextData | Record<string, never>>({});

export const JobInventoryProvider = ({ children }: JobInventoryProviderProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'messages.success.jobInventory' });
  const { toast } = useToast();
  const [state, setState] = useState<JobInventoryData>(initialState);

  const successHandler = () => {
    toast({
      title: t('update'),
    });
  };

  const toggle = (prop: JobInventoryToggle, value: boolean) =>
    setState((prev) => ({ ...prev, [prop]: value }));

  const { data } = useGetJobInventory();
  const records = data?.records;
  const summary = data?.summary;

  const dropdown = useJobInventoryDropdown();
  const dropdowns = dropdown.data?.records;

  const dialog = useJobInventoryDropdown(true);
  const dialogItems = dialog.data?.records;

  const tableForm = useJobInventoryForm({
    successHandler: () => {
      successHandler();
      toggle('actions', false);
    },
  });
  const {
    form: { watch },
    resetFields: resetTableFields,
  } = tableForm;
  const items = watch('items');

  const dialogForm = useJobInventoryForm({
    successHandler: () => {
      successHandler();
      toggle('dialog', false);
    },
    dialog: true,
  });
  const { resetFields: resetDialogFields } = dialogForm;

  const cancel = () => {
    resetTableFields(records);
    toggle('actions', false);
  };

  const setActions = () => {
    if (!records) return;
    const initial = records.map((el) => el.needed.toString());
    const current = items.map((el) => el.needed);
    toggle(
      'actions',
      initial.some((el, i) => el !== current[i]),
    );
  };

  useEffect(() => {
    if (!records) return;
    resetTableFields(records);
  }, [records, resetTableFields]);

  useEffect(() => {
    if (!dialogItems) return;
    resetDialogFields(dialogItems);
  }, [dialogItems, resetDialogFields]);

  useEffect(() => {
    if (!dropdowns) return;
    setState((prev) => ({ ...prev, dropdowns }));
  }, [dropdowns]);

  useEffect(() => {
    if (!summary) return;
    setState((prev) => ({ ...prev, summary }));
  }, [summary]);

  return (
    <JobInventoryContext.Provider
      value={{
        ...state,
        tableForm,
        dialogForm,
        toggle,
        cancel,
        setActions,
      }}>
      {children}
    </JobInventoryContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useJobInventoryContext = () => useContext(JobInventoryContext);
