import { SvgConsumer } from '@components/icons/svgConsumer';
import { FormControl, FormDescription, FormItem, FormLabel, FormMessage } from '.';
import { AppTooltip } from '../tooltip/tooltip';
import { HTMLAttributes, ReactNode } from 'react';

export const FormItemWrapper = ({
  label = '',
  tooltip = '',
  msg = '',
  children,
  select = false,
  description = false,
  className = '',
  state,
  props,
}: {
  label?: ReactNode;
  tooltip?: ReactNode;
  msg?: string;
  children: React.ReactNode;
  select?: boolean;
  description?: boolean;
  className?: string;
  state?: 'read-only' | 'disabled';
  props?: HTMLAttributes<HTMLDivElement>;
}) => (
  <FormItem
    {...props}
    className={`w-full ${className} ${
      state ? (state === 'disabled' ? 'filed-disabled' : 'field-read-only') : ''
    }`}>
    {description && label && <FormDescription>{label}</FormDescription>}
    {!description && label && (
      <div className="flex items-center gap-1.5">
        <FormLabel>{label}</FormLabel>
        {tooltip && (
          <AppTooltip side="top" context={tooltip}>
            <span>
              <SvgConsumer className="shrink-0 text-m" id="tooltip" />
            </span>
          </AppTooltip>
        )}
      </div>
    )}
    {select ? <>{children}</> : <FormControl>{children}</FormControl>}
    <FormMessage>{msg}</FormMessage>
  </FormItem>
);
