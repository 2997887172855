import { errorKeyRegEx, geolocalizationRegEx } from './consts';
import { toast } from '@hooks/useToast';
import { SplittedGeolocalization } from '@services/jobs/createJob';
import { KeyboardEvent } from 'react';
import { useTranslation } from 'react-i18next';

export type SetError = (name: string, { type, message }: { type: string; message: string }) => void;
type HandleFormErrors = (
  setError: SetError,
  defaultValues: string[],
  errors?: object | undefined,
  showToast?: boolean,
) => void;
export const useHandleFormErrors = () => {
  const { t } = useTranslation('app');
  const handleFormErrors: HandleFormErrors = (
    setError,
    defaultValues,
    errors,
    showToast = false,
  ) => {
    if (!errors) return;
    const globalTypes = ['root', 'base'];
    if (!showToast) {
      Object.entries(errors).forEach(([type, value]: [string, string[]]) => {
        if (defaultValues.includes(type) || globalTypes.includes(type)) {
          setError(type, {
            type: 'manual',
            message: `${value.map((err) => t(`errors.${err}`)).join(', ')}`,
          });
        }
      });
    }
    if (showToast) {
      const list = new Set(Object.values(errors).flat());
      toast({
        title: `${Array.from(list)
          .map((err) => t(`errors.${err}`))
          .join('\n')}`,
        variant: 'destructive',
      });
    }
  };

  return handleFormErrors;
};

export const useShowErrorToast = () => {
  const { t } = useTranslation('app');

  const showErrorToast = (e: string | string[]) => {
    const errors = typeof e === 'string' ? e.replace(' ', '%').split('%') : e;
    toast({
      title: `${errors
        .map((err) => (errorKeyRegEx.test(err) ? t(`errors.${err}`) : err))
        .join('\n')}`,
      variant: 'destructive',
    });
  };

  return {
    showErrorToast,
  };
};

type BaseResp = {
  status?: unknown;
  success?: boolean;
  errors?: unknown;
  message?: string;
  data?: unknown;
};

export const handleOnSettled = <T extends BaseResp>(
  resp: T,
  successHandler: (data?: T) => void,
  setFormErrors: () => void,
) => {
  if (resp?.success && typeof successHandler === 'function') {
    successHandler(resp?.data as T);
    return;
  }
  if (resp?.errors) {
    setFormErrors();
    return;
  }
  if (resp?.message) {
    if (typeof successHandler === 'function') successHandler();
    return;
  }
};

export const validateGeolocalization = (value?: unknown) => {
  if (!value || typeof value !== 'string') return false;
  return geolocalizationRegEx.test(value);
};

export const splitGeolocalization = (value: string): SplittedGeolocalization => {
  const [lat, lon] = value.split(',').map((el) => el.trim());
  return { lat: lat || '', lon: lon || '' };
};

export const joinGeolocalization = (value: SplittedGeolocalization) => {
  const { lat, lon } = value;
  if (!lat || !lon) return '';
  return [lat, lon].join(', ');
};

export const validateFloat = (value: unknown) => {
  if (value && parseFloat(value as string) >= 0) return true;
  return false;
};

export const parseDecimalValue = (value: string) =>
  new Intl.NumberFormat('en-US', { useGrouping: false }).format(Number(value));

export const validateKeyDown = (e: KeyboardEvent<HTMLInputElement>, pattern: RegExp) => {
  const match = e.key.match(pattern);

  return (
    e.metaKey ||
    e.key.includes('Arrow') ||
    e.key === 'Tab' ||
    e.key === 'Backspace' ||
    e.key === 'Enter' ||
    (match && match['0'] === match.input)
  );
};
