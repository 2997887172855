import { useQuery } from '@tanstack/react-query';
import { ApiError, Document, DocumentSubtask } from '@root/globalTypes';
import { queryFetch } from '@services/api';
import { queryKeys, uuidRegEx } from '@utils/consts';

export type DocumentsQueryType =
  | 'documents'
  | 'subtask'
  | 'foreman'
  | 'foremanTask'
  | 'questionary';

export interface DocumentsRespSuccess {
  success: boolean;
  mainRecords: Document[];
  subtasksRecords: DocumentSubtask[];
}

export const getDocumentsUrl = (queryType?: DocumentsQueryType) => {
  return `${queryType?.includes('foreman') ? 'foreman/' : ''}documents`;
};

export const getDocumentsQueries = (queryType: DocumentsQueryType) => {
  const queries: Record<DocumentsQueryType, string[][]> = {
    documents: [queryKeys.documents, queryKeys.jobStages],
    foreman: [queryKeys.foremanDocuments, queryKeys.foremanTask],
    foremanTask: [queryKeys.foremanDocuments, queryKeys.foremanTask],
    subtask: [queryKeys.jobStages],
    questionary: [queryKeys.job],
  };

  return [...queries[queryType], queryKeys.notifications];
};

export const fetchDocuments = async (
  jobId?: string,
  queryType?: DocumentsQueryType,
): Promise<DocumentsRespSuccess> => {
  return queryFetch({
    url: `${getDocumentsUrl(queryType)}${jobId ? `?job_id=${jobId}` : ''}`,
  });
};

export const useGetDocuments = ({ jobId, foreman }: { jobId?: string; foreman?: boolean }) => {
  const { data, error, isFetching } = useQuery<DocumentsRespSuccess, ApiError>({
    queryKey: foreman ? queryKeys.foremanDocuments : queryKeys.documents,
    queryFn: () => fetchDocuments(jobId, foreman ? 'foreman' : 'documents'),
    enabled: Boolean(jobId && uuidRegEx.test(jobId)),
    placeholderData: (previousData) => previousData as DocumentsRespSuccess,
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
