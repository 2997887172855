import { useTranslation } from 'react-i18next';
import { parseRoute } from '@utils/i18n';
import { appRoutes } from '@utils/consts';
import { Card, CardContent, CardHeader } from '@components/ui/card';
import { Button } from '@components/ui/button';

type FallbackProps = {
  error: Error;
  resetErrorBoundary: () => void;
};

export const ErrorFallback = ({ error, resetErrorBoundary }: FallbackProps) => {
  const { t } = useTranslation('app', { keyPrefix: 'pages.error' });

  return (
    <Card className="absolute left-1/2 top-1/2 w-full max-w-[800px] -translate-x-1/2 -translate-y-1/2 rounded-lg border-0 font-inter shadow-card">
      <CardHeader className="flex-row">
        <h1 className="text-heading-xs font-bold">{t('title')}</h1>
      </CardHeader>
      <CardContent className="flex flex-col gap-6">
        <div className="flex flex-col gap-6">
          <div className="leading-6">
            <p>{t('first')}</p>
            <p>{t('second')}</p>
          </div>
          <code className="scrollbar flex max-h-[400px] flex-col gap-2 overflow-hidden overflow-y-auto rounded bg-neutral-500 px-3 py-2 text-neutral-50">
            <strong>{error.stack?.split('(http')[0]}</strong>
            <span>
              {t('path')} {window.location.pathname}
            </span>
            <span>{error.stack}</span>
          </code>
        </div>
        <div className="flex gap-2">
          <Button disabled={false} onClick={() => window.location.reload()} className="w-fit">
            {t('refresh')}
          </Button>
          <Button
            disabled={false}
            onClick={() => {
              resetErrorBoundary();
              // eslint-disable-next-line immutable/no-mutation
              window.location.href = parseRoute(appRoutes.index);
            }}
            variant="secondary"
            className="w-fit">
            {t('back')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
