import { Material, MaterialCreator, MaterialOption } from '@root/globalTypes';
import { compareAsc } from 'date-fns';

export const getSlumpOption = (options?: MaterialOption[]) =>
  options?.find((el) => el.name === 'Slump');

export const getLastCreator = (firstCreator?: MaterialCreator, secondCreator?: MaterialCreator) => {
  const firstCreatedAt = firstCreator?.createdAt ? new Date(firstCreator.createdAt) : undefined;
  const secondCreatedAt = secondCreator?.createdAt ? new Date(secondCreator.createdAt) : undefined;

  if (firstCreatedAt && secondCreatedAt) {
    if (compareAsc(firstCreatedAt, secondCreatedAt)) return firstCreator;
    return secondCreator;
  }

  if (!secondCreatedAt && firstCreatedAt) return firstCreator;
  if (!firstCreatedAt && secondCreatedAt) return secondCreator;
  return undefined;
};

export const isCreatorExist = (item: Material) => {
  const { estimatedQuantityCreator, finalQuantityCreator, options } = item;
  const finalSlumpCreator = getSlumpOption(options)?.finalQuantityCreator;
  const estimatedSlumpCreator = getSlumpOption(options)?.estimatedQuantityCreator;

  return Boolean(
    estimatedQuantityCreator?.createdAt ||
      finalQuantityCreator?.createdAt ||
      finalSlumpCreator?.createdAt ||
      estimatedSlumpCreator?.createdAt,
  );
};

export const sortMaterials = (data?: Material[]) => {
  if (!data) return [];
  return data.sort((a, b) => {
    if (!a.productFullName || !b.productFullName) return 0;
    return a.productFullName?.localeCompare(b.productFullName || '', undefined, {
      numeric: true,
      sensitivity: 'base',
    });
  });
};
