import { foremanRoute } from '@utils/consts';
import { Outlet } from 'react-router-dom';
import { foremanArchiveRoutes } from './archive/routes';
import { foremanRequestsRoutes } from './requests/routes';
import { foremanRoutesRoutes } from './routes/routes';
import { foremanSchedulesRoutes } from './schedules/routes';
import { foremanTasksRoutes } from './tasks/routes';
import { ProtectedRoute } from '@components/protectedRoute';
import { foremanOthersRoutes } from './others/routes';

export const foremanRoutes = [
  {
    path: foremanRoute.index,
    lazy: async () => ({
      Component: () => (
        <ProtectedRoute allowedRoles={['foreman']}>
          <Outlet />
        </ProtectedRoute>
      ),
    }),
    children: [
      ...foremanArchiveRoutes,
      ...foremanRequestsRoutes,
      ...foremanRoutesRoutes,
      ...foremanSchedulesRoutes,
      ...foremanTasksRoutes,
      ...foremanOthersRoutes,
    ],
  },
];
