import { foremanRoute } from '@utils/consts';

export const foremanRequestsRoutes = [
  {
    path: foremanRoute.requests,
    lazy: async () => {
      const { Requests } = await import('@pages/foreman/requests');
      return {
        Component: () => <Requests />,
      };
    },
  },
];
