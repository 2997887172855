import { CompaniesData } from './types';

export const initialState: CompaniesData = {
  company: {
    isOpen: false,
    isRemove: false,
  },
  contact: {
    isOpen: false,
    isRemove: false,
  },
};
