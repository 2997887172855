import { sortByProp } from '@components/job/workProgress/helpers';
import { JobStage } from '@components/job/workProgress/stage';
import { useJobContext } from '@contexts/job';
import { JobStage as JobStageType } from '@root/globalTypes';

export const WorkProgress = () => {
  const { stages } = useJobContext();

  return (
    <section className="grid w-full grid-cols-1">
      {sortByProp<JobStageType>(stages.data?.records || [], 'position', 'asc').map((stage) => (
        <JobStage key={stage.id} data={stage} />
      ))}
    </section>
  );
};
