import { Menu } from '@components/ui/menu';
import { useToast } from '@hooks/useToast';
import { useCopyTask } from '@services/jobs/tasks/copy';
import { JobStageMenuProps } from './types';
import { cn } from '@utils/cn';
import { SvgConsumer } from '@components/icons/svgConsumer';
import { useTranslation } from 'react-i18next';
import { useCreateTask } from '@services/jobs/tasks/create';
import { uuidRegEx } from '@utils/consts';
import { useState } from 'react';

export const JobStageMenu = ({ jobId, multi }: JobStageMenuProps) => {
  const { toast } = useToast();
  const { t } = useTranslation('app');
  const [open, setOpen] = useState<boolean>(false);
  const isMulti = Boolean(multi?.length);

  const createMulti = useCopyTask({
    callback: () => {
      toast({
        title: t('messages.success.task.create'),
      });
    },
  });

  const createTask = useCreateTask({
    callback: () => {
      toast({
        title: t('messages.success.task.create'),
      });
    },
  });

  const handleItemClick = (callback: () => void) => {
    setOpen(false);
    callback();
  };

  const handleAddNew = (id: string, stageName: string) => {
    handleItemClick(() => {
      if (!uuidRegEx.test(id)) createTask.mutate({ jobId, taskName: id, stageName });
      else createMulti.mutate({ jobId, originalTaskId: id });
    });
  };

  return (
    <Menu
      open={open}
      setOpen={setOpen}
      options={[
        {
          group: {
            id: 'job-stage-menu-add-new',
            name: t('pages.job.addNew'),
          },
          items: multi.map(({ id, name, stageName }) => ({
            id,
            name,
            callback: () => handleAddNew(id, stageName),
          })),
        },
      ]}>
      <button
        className={cn([
          'flex h-6 w-6 items-center justify-center text-lg text-neutral-400 hover:text-primary-500 disabled:text-neutral-400 disabled:opacity-30',
          open && 'text-primary-500',
        ])}
        disabled={!isMulti}
        type="button"
        onClick={(e) => {
          e.stopPropagation();
          setOpen(!open);
        }}>
        <SvgConsumer id="jobStageMenu" />
      </button>
    </Menu>
  );
};
