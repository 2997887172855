import { WorkloadJobStatus } from '@services/workload/types';
import { WorkloadJobStyle, WorkloadJobStyles } from './types';
import { isBeforeToday } from '../../helpers';
import { parseISO } from 'date-fns';
import { WorkloadBasicSpeciality } from '@services/workload/calendar/types';

export const activeStyles: WorkloadJobStyle = {
  regular: 'bg-white text-neutral-700 border-neutral-700',
  menu: 'text-neutral-700 border-neutral-700',
};

export const styles: Record<WorkloadBasicSpeciality, WorkloadJobStyles> = {
  walls: {
    past: {
      regular: 'bg-walls-200 text-walls-700 border-walls-200',
      menu: 'text-walls-700 border-walls-700',
    },
    accepted: {
      regular: 'bg-walls-500 text-white border-walls-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-walls-600 border-walls-400',
      menu: 'text-walls-600 border-walls-600',
    },
    withoutTeam: {
      regular: 'bg-white text-walls-600 border-walls-400 border-dashed',
      menu: 'text-walls-600 border-walls-600',
    },
  },
  footing: {
    past: {
      regular: 'bg-footing-200 text-footing-700 border-footing-200',
      menu: 'text-footing-700 border-footing-700',
    },
    accepted: {
      regular: 'bg-footing-500 text-white border-footing-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-footing-600 border-footing-400',
      menu: 'text-footing-600 border-footing-600',
    },
    withoutTeam: {
      regular: 'bg-white text-footing-600 border-footing-400 border-dashed',
      menu: 'text-footing-600 border-footing-600',
    },
  },
  layout: {
    past: {
      regular: 'bg-layout-200 text-layout-700 border-layout-200',
      menu: 'text-layout-700 border-layout-700',
    },
    accepted: {
      regular: 'bg-layout-500 text-white border-layout-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-layout-600 border-layout-400',
      menu: 'text-layout-600 border-layout-600',
    },
    withoutTeam: {
      regular: 'bg-white text-layout-600 border-layout-400 border-dashed',
      menu: 'text-layout-600 border-layout-600',
    },
  },
  stripout: {
    past: {
      regular: 'bg-stripout-200 text-stripout-700 border-stripout-200',
      menu: 'text-stripout-700 border-stripout-700',
    },
    accepted: {
      regular: 'bg-stripout-500 text-white border-stripout-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-stripout-600 border-stripout-400',
      menu: 'text-stripout-600 border-stripout-600',
    },
    withoutTeam: {
      regular: 'bg-white text-stripout-600 border-stripout-400 border-dashed',
      menu: 'text-stripout-600 border-stripout-600',
    },
  },
  crane: {
    past: {
      regular: 'bg-crane-200 text-crane-700 border-crane-200',
      menu: 'text-crane-700 border-crane-700',
    },
    accepted: {
      regular: 'bg-crane-500 text-white border-crane-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-crane-600 border-crane-400',
      menu: 'text-crane-600 border-crane-600',
    },
    withoutTeam: {
      regular: 'bg-white text-crane-600 border-crane-400 border-dashed',
      menu: 'text-crane-600 border-crane-600',
    },
  },
  pump: {
    past: {
      regular: 'bg-pump-200 text-pump-700 border-pump-200',
      menu: 'text-pump-700 border-pump-700',
    },
    accepted: {
      regular: 'bg-pump-500 text-white border-pump-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-pump-600 border-pump-400',
      menu: 'text-pump-600 border-pump-600',
    },
    withoutTeam: {
      regular: 'bg-white text-pump-600 border-pump-400 border-dashed',
      menu: 'text-pump-600 border-pump-600',
    },
  },
  utilityDriver: {
    past: {
      regular: 'bg-utility-driver-200 text-utility-driver-700 border-utility-driver-200',
      menu: 'text-utility-driver-700 border-utility-driver-700',
    },
    accepted: {
      regular: 'bg-utility-driver-500 text-white border-utility-driver-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-utility-driver-600 border-utility-driver-400',
      menu: 'text-utility-driver-600 border-utility-driver-600',
    },
    withoutTeam: {
      regular: 'bg-white text-utility-driver-600 border-utility-driver-400 border-dashed',
      menu: 'text-utility-driver-600 border-utility-driver-600',
    },
  },
  customerService: {
    past: {
      regular: 'bg-customer-service-200 text-customer-service-700 border-customer-service-200',
      menu: 'text-customer-service-700 border-customer-service-700',
    },
    accepted: {
      regular: 'bg-customer-service-500 text-white border-customer-service-500',
      menu: 'text-white border-white',
    },
    notAccepted: {
      regular: 'bg-white text-customer-service-600 border border-customer-service-400',
      menu: 'text-customer-service-600 border-customer-service-600',
    },
    withoutTeam: {
      regular:
        'bg-white text-customer-service-600 border border-customer-service-400 border-dashed',
      menu: 'text-customer-service-600 border-customer-service-600',
    },
  },
};

export const getPillStatus = (startDate: string, status?: string): WorkloadJobStatus => {
  if (!status || status === 'rejected') return 'withoutTeam';
  if (isBeforeToday(parseISO(startDate))) return 'past';
  if (status === 'approved') return 'accepted';
  return 'notAccepted';
};
