import { useAppContext } from '@contexts/app';
import { useQueryClient } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';

export const useRevalidateQuery = () => {
  const queryClient = useQueryClient();
  const { isForeman } = useAppContext();

  const revalidateQuery = async (qKeys: string[]) => {
    await queryClient.refetchQueries({ queryKey: qKeys });
  };

  const mapQueryType = (type: string) => {
    switch (type) {
      case 'schedule':
        return isForeman
          ? [queryKeys.foremanRequests, queryKeys.foremanSchedules, queryKeys.foremanTask]
          : [queryKeys.job, queryKeys.jobStages, queryKeys.onDeck];
      case 'task':
        return isForeman
          ? [queryKeys.foremanTask, queryKeys.foremanStages]
          : [queryKeys.jobStages, queryKeys.onDeck];
      case 'subtask':
        return isForeman
          ? [queryKeys.foremanTask, queryKeys.foremanStages]
          : [queryKeys.jobStages, queryKeys.onDeck];
      case 'job':
        return [queryKeys.job, queryKeys.jobStages, queryKeys.jobs];
      case 'notification':
        return [queryKeys.notifications];
      case 'on_deck':
        return [queryKeys.onDeck];
      default:
        return [];
    }
  };

  const revalidate = async (types: string[]) => {
    if (!types.length) return;
    const queries: string[][] = [];
    types.forEach((type) => {
      const tmp = mapQueryType(type);
      tmp.forEach((t) => queries.push(t));
    });

    const promises = queries.map((q) => {
      return revalidateQuery(q);
    });

    Promise.allSettled(promises)
      .finally(() => {
        // eslint-disable-next-line no-console
        console.info('queries updated');
      })
      .catch((err) => {
        console.error(err);
      });
  };

  type WindowPlus = Window & {
    revalidateQueries: (types: string[]) => Promise<void>;
  };
  // eslint-disable-next-line immutable/no-mutation
  (window as unknown as WindowPlus).revalidateQueries = revalidate;

  return {
    revalidate,
  };
};
