import { SvgConsumer } from '@components/icons/svgConsumer';
import { SchedulerGridScheduleMenuProps } from './types';
import { cn } from '@utils/cn';
import { Menu } from '@components/ui/menu';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { parseRoute } from '@utils/i18n';
import { appRoutes } from '@utils/consts';
import { useTranslation } from 'react-i18next';
import { RemoveConfirmationAlert } from '@components/dialogs/removeConfirmationAlert';
import { useToast } from '@hooks/useToast';
import { useRemoveInternalSchedule } from '@services/schedules/internal/remove';
import { MenuItem } from '@components/ui/menu/types';

export const SchedulerGridScheduleMenu = ({ data, className }: SchedulerGridScheduleMenuProps) => {
  const { t } = useTranslation('app');
  const { toast } = useToast();
  const [open, setOpen] = useState(false);
  const [isRemove, setIsRemove] = useState(false);
  const navigate = useNavigate();

  const { id, task } = data;
  const jobId = task?.job?.id || '';
  const stageId = task?.stage?.id || '';

  const remove = useRemoveInternalSchedule({
    id,
    jobId,
    callback: () => {
      toast({ title: t('messages.success.internalSchedule.remove') });
    },
  });

  return (
    <>
      <Menu
        variant="small"
        open={open}
        setOpen={setOpen}
        options={[
          {
            group: {
              id: 'scheduler-schedule-menu',
            },
            items: [
              jobId &&
                stageId && {
                  id: 'viewDetails',
                  name: t('actions.viewDetails'),
                  callback: () =>
                    navigate(
                      `/${parseRoute(
                        appRoutes.jobWorkProgress(jobId),
                      )}?open=${stageId}&focus=${stageId}`,
                    ),
                },
              {
                id: 'removeSchedule',
                name: t('actions.remove'),
                callback: () => setIsRemove(!isRemove),
              },
            ].filter(Boolean) as MenuItem[],
          },
        ]}>
        <button
          type="button"
          onClick={() => setOpen(!open)}
          className={cn([
            'absolute right-0 z-10 flex h-4 w-4 items-center justify-center rounded hover:border',
            open && 'border',
            className,
          ])}>
          <SvgConsumer id="menu" />
        </button>
      </Menu>
      <RemoveConfirmationAlert
        open={isRemove}
        setOpen={setIsRemove}
        title={t('schedules.removeTitle')}
        description={t('schedules.removeInfo')}
        onConfirm={remove.mutate}
        locked={remove.isLoading}
      />
    </>
  );
};
