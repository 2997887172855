import { foremanRoute } from '@utils/consts';

export const foremanSchedulesRoutes = [
  {
    path: foremanRoute.schedules,
    lazy: async () => {
      const { Schedules } = await import('@pages/foreman/schedules');
      return {
        Component: () => <Schedules />,
      };
    },
  },
];
