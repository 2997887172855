import { ApiError } from '@root/globalTypes';
import { queryFetch } from '../api';
import { useMutation } from '@tanstack/react-query';

export type WebPushReq = {
  webpushNotifications: {
    endpoint: string;
    auth: string;
    p256dh: string;
  };
};
export interface WebPushRespSuccess {
  [key: string]: unknown;
  readonly success?: boolean;
}

export type WebPushResp = WebPushRespSuccess | ApiError;

export const useWebPush = ({ callback }: { callback: (data: WebPushResp) => void }) => {
  const { mutate, isPending } = useMutation({
    mutationFn: (userData: WebPushReq): Promise<WebPushResp> =>
      queryFetch({ url: 'webpush_notifications', data: userData, opt: { method: 'POST' } }),
    onError: (err) => {
      console.error(err);
    },
    onSettled: (data) => {
      if (data) callback(data);
    },
  });

  return {
    mutate,
    isLoading: isPending,
  };
};

export const useTestPush = () => {
  const { mutate, isPending } = useMutation({
    mutationFn: (): Promise<WebPushResp> =>
      queryFetch({
        url: 'webpush_notifications/push',
        data: { message: 'Test push message body' },
        opt: { method: 'POST' },
      }),
    onError: (err) => {
      console.error(err);
    },
  });

  return {
    test: mutate,
    isLoading: isPending,
  };
};
