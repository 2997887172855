import { createContext, useContext, useState } from 'react';
import { CompaniesContextData, CompaniesData, CompaniesProviderProps } from './types';
import { initialState } from './constants';

const CompaniesContext = createContext<CompaniesContextData | Record<string, never>>({});

export const CompaniesProvider = ({ children }: CompaniesProviderProps) => {
  const [state, setState] = useState<CompaniesData>(initialState);

  return (
    <CompaniesContext.Provider value={{ ...state, setState }}>{children}</CompaniesContext.Provider>
  );
};

// eslint-disable-next-line react-refresh/only-export-components
export const useCompaniesContext = () => useContext(CompaniesContext);
