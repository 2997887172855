import React, { Children } from 'react';
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
} from '.';

export const NavMenu = ({
  children,
  trigger,
  triggerClass = '',
  hideChevron = false,
}: {
  children: React.ReactNode;
  trigger: React.ReactNode;
  triggerClass?: string;
  hideChevron?: boolean;
}) => (
  <NavigationMenu>
    <NavigationMenuList>
      <NavigationMenuItem>
        <NavigationMenuTrigger hideChevron={hideChevron} className={triggerClass}>
          {trigger}
        </NavigationMenuTrigger>
        <NavigationMenuContent>
          {Children.map(children, (child) => (
            <NavigationMenuLink className="w-full justify-start" asChild>
              {child}
            </NavigationMenuLink>
          ))}
        </NavigationMenuContent>
      </NavigationMenuItem>
    </NavigationMenuList>
  </NavigationMenu>
);
