import { useQuery } from '@tanstack/react-query';
import { queryKeys } from '@utils/consts';
import { queryFetch } from '@services/api';
import { ApiError } from '@root/globalTypes';
import { MapRespSuccess } from './types';
import { useQueryParams } from '@hooks/useQueryParams';
import { FilterValue } from '@services/users/filters/types';

export const useGetMap = () => {
  const {
    params: { search, states, stages, constructionTypes },
    parseParams,
  } = useQueryParams<FilterValue>();

  const queryParams = parseParams({ search, states, stages, constructionTypes });

  const { data, error, isFetching } = useQuery<MapRespSuccess, ApiError>({
    queryKey: [...queryKeys.map, queryParams],
    queryFn: () =>
      queryFetch({
        url: `maps/jobs${queryParams}`,
      }),
  });

  return {
    data,
    error,
    isLoading: isFetching,
  };
};
