import { ProtectedRoute } from '@components/protectedRoute';
import { MapProvider } from '@contexts/map';
import { API_KEY } from '@contexts/map/constants';
import { appRoutes, usersAccessList } from '@utils/consts';
import { APIProvider } from '@vis.gl/react-google-maps';

export const mapRoutes = [
  {
    path: appRoutes.map,
    lazy: async () => {
      const { Map } = await import('@pages/map');
      return {
        Component: () => (
          <ProtectedRoute allowedRoles={usersAccessList(appRoutes.map)}>
            <APIProvider apiKey={API_KEY}>
              <MapProvider>
                <Map />
              </MapProvider>
            </APIProvider>
          </ProtectedRoute>
        ),
      };
    },
  },
];
